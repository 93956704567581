const styles = (theme: any): Record<string, any> => {
	return {targetBox: {
		backgroundColor: theme.colors.system20,
		height: "auto",
		padding: "1rem",
		textAlign: "center",
		margin: "10px",
	},
	browse: {
		color: theme.colors.blue,
		cursor: "pointer",
		"&:hover": {
			color: theme.colors.blueHover
		}
	}}
};


export default styles;
