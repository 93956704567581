import React, { useState, useCallback } from "react";
import PropTypes, { InferProps } from "prop-types";
import TopCard from "./Card";
import FormFrame from "../../Form/FormFrame";
import { GLOBAL_TABBED_DRAWER, CONTACT, MYPROFILE, USER, EDIT, CLONE, REVIEW, ACCOUNT } from "../../../common/consts";
import { getDisplayName } from "../../../common/moduleData";
import openCommForm from "../../../common/comm";
import useTransientElements from "../../../hooks/useTransientElements";
import { useHistory } from "react-router-dom";
import useGraphServer from "../../../hooks/useGraphServer";
import GridModal from "../../Modal";
import { GridModalState } from "../../Modal/typeDefs";
import AnonymizeReport from "../../Modal/AnonymizeReport";


const TopCardWrapperProps = {
	data: PropTypes.shape({
		recId: PropTypes.number,
		IsActive: PropTypes.bool,
		Pending: PropTypes.bool,
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		AssetPath: PropTypes.string,
		RecInfo: PropTypes.arrayOf(PropTypes.string),
		PrimaryEmailAddress: PropTypes.string,
		IsApproved: PropTypes.bool || PropTypes.number,
		PendingID: PropTypes.number,
		LastModifiedDt: PropTypes.string
	}).isRequired,
	moduleName: PropTypes.string.isRequired,
	deleteRecord: PropTypes.func.isRequired
};

type Props = InferProps<typeof TopCardWrapperProps>;

const TopCardWrapper: React.FC<Props> = ({
	deleteRecord,
	moduleName,
	data
}) => {
	const [modalState, setModalState] = useState<GridModalState>({
		title: "",
		isOpen: false
	});

	const closeModal = useCallback(() => setModalState({
		...modalState,
		isOpen: false
	}), [modalState]);

	const { recId, IsActive, Pending, IsApproved, PendingID, LastModifiedDt, status, ...rest } = data;
	const { openConfirmDialog, setDrawerOpen, showSnackbarMessage } = useTransientElements();
	const history = useHistory();
	const crm = useGraphServer();
	if (!recId) {
		return null;
	}

	type OpenFormFrame = (action: string, viewOnly?: boolean) => void
	const openFormFrame: OpenFormFrame = (action, viewOnly = false) => {
		const displayComponent: React.ReactNode = (
			<FormFrame
				type={moduleName}
				action={action}
				recId={recId}
				viewOnly={viewOnly}
			/>
		);

		//TODO This needs to not be here. We shouldn't have logic based on module types in the UI. Move server-side
		const tab = {
			name: `${action} ${moduleName === "eventCalendar" ? "Event Calendar" : moduleName === "myProfile" ? "my profile" : moduleName}`
		};
		setDrawerOpen(GLOBAL_TABBED_DRAWER, {
			type: moduleName,
			isOpen: true,
			id: recId,
			action,
			displayComponent,
			tabs: [tab],
			activeTab: tab.name
		});
	};

	type CardData = {
		fullName?: string;
		initial?: string;
		image?: string;
		status?: string|null;
		IsApproved?: boolean | number | null;
		PendingID?: number | null;
		LastModifiedDt?: string | null;
	}
	let cardData: CardData = {};
	cardData.fullName = getDisplayName(moduleName, data);
	cardData.IsApproved = IsApproved;
	cardData.PendingID = PendingID;
	cardData.LastModifiedDt = LastModifiedDt;
	// construct data based on module name
	if ([CONTACT, MYPROFILE, USER].includes(moduleName!)) {
		const { FirstName = "", LastName = "" } = data;
		cardData = {
			...cardData,
			initial: FirstName!.charAt(0) + LastName!.charAt(0),
			image: rest.AssetPath || ""
		};
	}

	const handlers = {
		handleEdit: (): void => openFormFrame(EDIT),
		handleView: (): void => openFormFrame(EDIT),
		handleClone: (): void => openFormFrame(CLONE),
		handleReview: (): void => openFormFrame(REVIEW),
		handleRead: (): void => openFormFrame(EDIT, true),
		handleDelete: (): void => {
			openConfirmDialog({
				title: "Are you sure you want to send this record to the Recycle Bin?",
				content: "Click OK to confirm. Click Cancel to go back.",
				handleConfirm: (): void => {
					deleteRecord(moduleName, Number(recId));
					history.push(`/grid/${moduleName}`);
				}
			});
		},
		handleSendEmail: (): void => {
			//TODO More module type logic. Needs to be moved server-side.
			if (moduleName === CONTACT || moduleName === ACCOUNT) {
				if (!data.PrimaryEmailAddress) {
					showSnackbarMessage("Cannot send communication to this contact, missing primary email address.", "error");
					return;
				}
			}
			if (!openCommForm(moduleName, recId, setDrawerOpen, data.PrimaryEmailAddress)) {
				console.warn(`send email is not supported from ${moduleName} summary`);
			}
		},
		handleAnonymize: (): void => {
			if (moduleName === CONTACT) {
				openConfirmDialog({
					content: "This action will permanently remove field data on the record(s) you are anonymizing. It cannot be undone. Do you want to continue?",
					handleConfirm: () => {
						crm.general.set_old({
							queryString: `
								anonymize_contact(ContactID: ${recId})
							`
						}).then((res: any) => {
							if (res.anonymize_contact) {
								history.push(`/grid/${moduleName}`);
							}
						});
					}
				});
			}
		},
		handleAnonymizeReport: async (): Promise<void> => {
			if (moduleName === CONTACT) {
				const res = await crm.general.get_old({
					type: "contacts",
					queryString: `
					contacts(filter: { recId: ${recId} }) { 
						recs {
							FirstName
							LastName
							FullName
							Email {
								recs {
									EmailAddress
								}
							}
							Phone {
								recs {
									PhoneNumber
								}
							}
							Address {
								recs {
									AddressLine1
									AddressLine2
									AddressLine3
									City
									StateCode
									PostalCode
									CountryCode
									AddressType
								}
							}
							Tag {
								recs {
									Title
								}
							}
							Connection {
								DisplayNm
								ConnectionValue          
							}
							CustomField {
								UDFieldValue
							}
						}
						message
						success
					}`
				});
				setModalState({
					children: <AnonymizeReport data={(res && res.success) ? res.recs[0] : {}} />,
					title: "Data Privacy Report",
					isOpen: true,
					useHandleBackdrop: false
				});
			}
		}

	};

	// fill in the common field
	if (status) {
		cardData.status = status;
	} else if (typeof Pending === "undefined" && typeof IsActive === "undefined") {
		cardData.status = null;
	}else {
		cardData.status = Pending ? "Pending" : IsActive ? "Active" : "Inactive";
	}
	return <>
		<TopCard {...handlers} {...cardData} moduleName = {moduleName} rowActions = {rest.RecInfo} />
		<GridModal handleClose={closeModal} isOpen={modalState.isOpen} useHandleBackdrop={modalState.useHandleBackdrop} title={modalState.title} maxWidth={"sm"}>
			{ modalState.children}
		</GridModal>
	</>;
};

TopCardWrapper.propTypes = TopCardWrapperProps;
export default TopCardWrapper;
