const styles = theme => ({
	expansionPanelSummary: {
		borderTopWidth: 1,
		borderTopStyle: "solid",
		borderTopColor: theme.palette.primary.light,
		borderBottomWidth: 1,
		borderBottomStyle: "solid",
		borderBottomColor: theme.palette.primary.light,
		flexDirection: "row-reverse",
		backgroundColor: theme.colors.primaryBlue
	},
	expansionPanelSummaryContent: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		"& > :last-child": {
			paddingRight: 0,
			paddingLeft: theme.spacing(3)
		}
	},
	expansionPanelSummaryExpanded: {
		margin: "0 !important",
		minHeight: "unset !important",
		height: 48,
		display: "flex",
		alignItems: "center"
	},
	expansionPanelDetails: {
		display: "block",
		"& .detail-summary-fieldset": {
			marginTop: theme.spacing(1.5),
			marginLeft: theme.spacing(1)
		}
	},
	expansionPanel: {
		marginBottom: theme.spacing(2),
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		},
		borderRight: 0
	},
	expansionPanelExpanded: {
		marginBottom: 0,
		margin: 0
	},
	expansionPanelIcon: {
		left: theme.spacing(1),
		right: "unset",
		"& svg": {
			color: theme.palette.common.white
		}
	},
	subtitle: {
		color: theme.palette.common.white
	},
	appBar: {
		boxShadow: "none",
		backgroundColor: theme.colors.gandolf,
		marginBottom: theme.spacing(2),
		color: theme.palette.common.white,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center"
	},
	appBarTitle: {
		marginLeft: theme.spacing(2),
		color: theme.palette.common.white,
		textTransform: "uppercase"
	},
	dialogContent: {
		minHeight: "50px",
		marginBottom: "16px"
	},
	label: {
		color: theme.colors.darkGray,
		marginBottom: 1
	},
	text: {
		display: "block",
		whiteSpace: "pre-line",
		color: theme.colors.black,
		"& .chip": {
			margin: "1px 2px",
			height: theme.spacing(3)
		},
		"& a": {
			textDecoration: "none"
		}
	},
	section: {
		marginBottom: "16px !important"
	},
	sectionHeader: {
		backgroundColor: theme.colors.secondaryGray
	},
	gridContainer: {
		display: "block",
		"& pre.diffFieldPre": {
			display: "inherit",
			fontFamily: "inherit"
		}
	},
	detailActionButton: {
		marginBottom: 10,
		marginRight: 40,
		backgroundColor: theme.colors.blue,
		color: "#FFF"
	}
});

export default styles;
