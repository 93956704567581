import { withStyles } from "@material-ui/core/styles";

/*
* this is used to overwrite css from 3rd party components
* like Google's address autocompolete container
*/

export default withStyles(theme => ({
	"@global": {
		".pac-container": {
			marginTop: "5px",
		}
	}
}))(() => null);
