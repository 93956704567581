import React, { useMemo } from "react";
import { BulkActionData } from "./typeDefs";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { CSVLink } from "react-csv";
import { getModuleName } from "../../common/moduleData";
import moment from "moment";
import { formatPhone, formatTime, formatDatetime } from "@react-ui/common/format";

interface Props {
	inputData: BulkActionData;
	headers: string[];
	labels: BulkActionData;
	moduleName: string;
}

const useStyles = makeStyles(styles);

const BulkExportForm: React.FC<Props> = ({ inputData, headers, labels, moduleName }) => {
	const classes = useStyles();

	const dt = moment().format("YYYYMMDD");
	const plural = useMemo(() => inputData?.length > 1, [inputData]);
	const csvheaders = useMemo(() => headers.map(h => {
		const labelIndex = labels.findIndex(l => l.name === h);
		return {
			label: labelIndex === -1 ? "" : labels[labelIndex].label,
			key: h
		};
	}), [headers]);

	const data = useMemo(() => {
		// deal with time format
		// deal with date format
		// deal with datetime format
		// deal with timeutc format
		let timeFormatColumns: string[] = [], dateFormatColumns: string[] = [], datetimeFormatColumns: string[] = [];
		let timeutcFormatColumns: string[] = [], dateutcFormatColumns: string[] = [];
		let booleanFormatColumns: string[] = [];

		labels.forEach((column: any) => {
			switch (column.format) {
				case "time":
					timeFormatColumns.push(column.name);
					break;
				case "datetime":
					datetimeFormatColumns.push(column.name);
					break;
				case "timeutc":
					timeutcFormatColumns.push(column.name);
					break;
				case "date":
					dateFormatColumns.push(column.name);
					break;
				case "clientdate":
					dateFormatColumns.push(column.name);
					break;
				case "dateutc":
					dateutcFormatColumns.push(column.name);
					break;
				case "boolean_icon":
					booleanFormatColumns.push(column.name);
					break;
			}
		});

		let formattedData = inputData;
		formattedData.forEach((record: any) => {
			Object.keys(record).forEach(k => {
				if (timeFormatColumns.includes(k) && record[k] && formattedData.length > 50) {
					record[k] = formatTime(record[k]);
				} else if (dateFormatColumns.includes(k) && record[k]) {
					record[k] = moment(record[k]).format("MMM D, YYYY");
				} else if (datetimeFormatColumns.includes(k) && record[k]) {
					record[k] = formatDatetime(record[k]);
				} else if (timeutcFormatColumns.includes(k) && record[k] && record[k].startsWith("1970")) {
					record[k] = moment(record[k].replace("Z", "")).format("LT");
				} else if (dateutcFormatColumns.includes(k) && record[k]) {
					record[k] = moment(record[k].replace("Z", "")).format("MMM D, YYYY");
				} else if (booleanFormatColumns.includes(k) && record[k]) {
					if (record[k]?.props?.displayvalue) {
						record[k] = record[k].props.displayvalue;
					} else {
						const isTrue = (record[k] === "true" || record[k] === true || record[k] === "1") ? true : false;
						record[k] = isTrue ? "Yes" : "No";
					}
				}
			});
		});
		return formattedData;
	}, [inputData]);

	const csvdata = useMemo(() => data.map((d: any) => {
		const rtn: Record<string, any> = {};
		for (const key in d) {
			if (typeof d[key] === "object") {
				if (d[key]?.props?.children?.length) {
					if (Array.isArray(d[key].props.children)) {
						rtn[key] = d[key].props.children.map((chip: any) => chip?.props?.label?.replaceAll("\"", "\'")).join(",");
					} else {
						rtn[key] = d[key].props.children?.replaceAll("\"", "\'");
					}
				} else if (d[key]?.props?.displayvalue?.length) {
					rtn[key] = d[key].props.displayvalue?.replaceAll("\"", "\'");
					rtn[key] = rtn[key] === "Yes" ? "true" : "false";
				} else if (Array.isArray(d[key])) {
					rtn[key] = d[key].map((chip: any) => chip?.Title?.replaceAll("\"", "\'")).join(",");
				} else if (d[key]?.props?.dangerouslySetInnerHTML) {
					rtn[key] = d[key].props.dangerouslySetInnerHTML?.__html.replaceAll("\"", "\'") || d["CustomFields"]?.filter((field: any) => "CustomField_" + field.UDFID === key)[0]?.value.replaceAll("\"", "\'");
				}
			} else if (typeof d[key] === "boolean") {
				rtn[key] = d[key] ? "Yes" : "No";
			} else {
				rtn[key] = d[key];
				if (key.search("PhoneNumber") !== -1) {
					rtn[key] = formatPhone(rtn[key].toString());
				}
			}
		}
		return rtn;
	}), [data]);

	return <div>
		<div style={{ margin: 14 }} className={classes.bulkTagBanner}>
			{`A file has been created containing ${inputData.length} entr${plural ? "ies" : "y"}`}
		</div>
		<div style={{ float: "right" }}>
			<CSVLink className={classes.link} data={csvdata} headers={csvheaders} target="_blank" filename={`${getModuleName(moduleName, true, inputData.length)}-${dt}.csv`}>Click here to download the file.</CSVLink>
		</div>
	</div>;
};

export default BulkExportForm;
