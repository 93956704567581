import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";


import { RootState } from "../../reducers";
import { wrapValue } from "../../common/format";
import styles from "./styles";

export const ONE_RECORD_FOUND = "1 record found";
export const NO_RECORDS_FOUND = "No results found";
export const TOP_FOUND = "Top 100 found";

export const SearchResult: React.FC<any> = ({ classes }) => {
	const searchResults = useSelector((state: RootState) => {
		return state.records.searchResults;
	}) || [];

	const formatCount: () => string = () => {
		if(searchResults.length >= 100) {
			return TOP_FOUND;
		} else if(searchResults.length > 1) {
			return `${searchResults.length} records found`;
		} else if(searchResults.length === 1) {
			return ONE_RECORD_FOUND;
		} else {
			return NO_RECORDS_FOUND;
		}
	};

	return (
		<Paper classes={{ root: classes.searchPaper }}>
			<Typography variant={"h2"}>SEARCH RESULTS <span className={classes.recordsFoundText}>- {formatCount()}</span></Typography>
			<Table classes={{ root: classes.searchTable }}>
				<TableHead classes={{ root: classes.searchHeader }}>
					<TableRow>
						<TableCell classes={{ root: classes.cell }}>Record ID</TableCell>
						<TableCell classes={{ root: classes.cell }}>Record Type</TableCell>
						<TableCell classes={{ root: classes.cell }}>Record</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{searchResults.slice(0, 100).map(sr => (
						<TableRow className={"search-result-table-row"} key={`sr-table-row-${sr.RecordID}`}>
							<TableCell classes={{ root: classes.cell }}>
								{
									wrapValue(sr.RecordID, "moduleLink", { module: sr.type, data: sr.RecordID })
								}
							</TableCell>
							<TableCell classes={{ root: classes.cell }}>{sr.type}</TableCell>
							<TableCell classes={{ root: classes.cell }}>
								{
									wrapValue(sr.title, "moduleLink", { module: sr.type, data: sr.RecordID })
								}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(SearchResult);
