import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, Grid, Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import upperFirst from "lodash/upperFirst";
import styles from "./styles";
import dashlets from "./dashlets";
import { Creators } from "../../actions/dashboardActions";

class Dashboard extends Component {
	componentDidMount() {
		this.props.fetchDashboards();
	}
	render = () => {
		const { classes, dashboards, data = {} } = this.props;
		const { dashboardId } = data;
		const dashboard = dashboards.filter(d => d.dashboardId === dashboardId)[0] || {};
		const dashletSize = {
			carousel: 8,
			bulletin: 4,
			accounts: 6,
			tasks: 4,
			calendar: 12,
			pendingActivity: 6,
			myActionItems: 6,
			"accounts&pendingActivity,calendar": 8
		}
		
		return (
			<div className={classes.container}>
				{dashboard.title && (
					<Typography variant="h1" className={classes.title}>
						{dashboard.title}
					</Typography>
				)}
				{dashboard.dashlets && (
					<Grid container spacing={4}>
						{dashboard.dashlets.map(dashlet => {
							const dashletNames = dashlet.split(",");
							
							if (dashletNames[0]) {
								return (
									dashlet === "carousel" ?
									<Grid key={`${dashlet}_dashlet`} item xs={12} sm={12} md={dashletSize[dashlet]} lg={dashletSize[dashlet]}>
										<div key={`${dashlet}_dashletDiv`}>
										{dashletNames.map(function(dashletName){
											const Dashlet = dashlets[upperFirst(dashletName)];
											return (
													<Dashlet boardClasses={classes} />
												);
										})}
										</div>
										</Grid>
										:	<Grid key={`${dashlet}_dashlet`} item xs={12} sm={12} md={dashletSize[dashlet] || 6 } lg={dashletSize[dashlet] || 6 }>
										
												{dashletNames.map(function(dashletName){
													let Dashlet = dashlets[upperFirst(dashletName)];
													
													if (dashletName.indexOf("&") > -1) {
														const subDashletNames = dashletName.split("&");
														return (
															<Grid key={`${dashletName}_dashlet`} className={classes.dashboardSubGrid} container>
																{subDashletNames.map(function(subDashletName, i){
																	Dashlet = dashlets[upperFirst(subDashletName)];

																	return (
																		<Grid key={`${subDashletName}_dashlet`} style={ i % 2 ? { paddingLeft: 15} : { paddingRight: 15}} item xs={12} sm={12} md={subDashletNames.length === 1 ? 12 : dashletSize[subDashletName] || 6 } lg={subDashletNames.length === 1 ? 12 : dashletSize[subDashletName] || 6 }>
																			<Card className={classes.dashboardCard}>
																				<CardContent>
																					<Dashlet boardClasses={classes} key={`dashlet_${dashlet}`} />
																				</CardContent>
																			</Card>
																		</Grid>
																	);
																})}
															</Grid>
														);
													} else {
														return (
															<Grid key={`${dashletName}_dashlet`} item xs={12} sm={12} md={dashletNames.length === 1 ? 12 : dashletSize[dashletName] || 6 } lg={dashletNames.length === 1 ? 12 : dashletSize[dashletName] || 6 }>
																<Card className={classes.dashboardCard}>
																	<CardContent>
																		<Dashlet boardClasses={classes} key={`dashlet_${dashlet}`} />
																	</CardContent>
																</Card>
															</Grid>
														);
													}
												})}	
										
										</Grid>
								);
							}
						})}
					</Grid>
				)}
			</div>
		);
	};
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	dashboards: state.dashboard.dashboards,
	data: state.dashboard.data
});

const mapDispatchToProps = {
	fetchDashboards: Creators.fetchDashboards
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Dashboard));
