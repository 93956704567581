import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "../IconButton";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import { Creators } from "../../../actions/transientElementActions";
import { Creators as CalendarCreators } from "../../../actions/mycalendarActions";
import FormFrame from "../../Form/FormFrame";
import { GLOBAL_TABBED_DRAWER } from "../../../common/consts";
import styles from "./styles";

const ANCHOR = "addNewAnchorElement";

class AddNew extends Component {
	componentDidMount() {
		this.props.setAnchorElement(ANCHOR, null);
	}

	handleMenu = event => {
		const { setAnchorElement } = this.props;
		setAnchorElement(ANCHOR, event.currentTarget);
	};

	openDrawer = type => () => {
		const { setDrawerOpen, setAnchorElement, fetchCalendar } = this.props;
		const displayComponent = (
			<FormFrame
				type={type}
				action={"add"}
				recId={0}
				afterSubmit={() => {
					setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
					if (type.indexOf("task") >= 0) {
						fetchCalendar();
					}
				}}
			/>
		);
		setDrawerOpen(GLOBAL_TABBED_DRAWER, {
			isOpen: true,
			type,
			id: 0,
			action: "add",
			tabs: [{ name: "" }],
			activeTab: "",
			displayComponent
		});
		setAnchorElement(ANCHOR, null);
	};

	closeMenu = () => {
		const { setAnchorElement } = this.props;
		setAnchorElement(ANCHOR, null);
	};

	render() {
		const {
			handleMenu,
			openDrawer,
			closeMenu,
			props: { addNewMenuItems, anchorElement }
		} = this;
		const open = !!anchorElement;

		if (addNewMenuItems.length) {
			return (
				<React.Fragment>
					<Tooltip title={"Add New Record"}>
						<IconButton onClick={handleMenu} color="inherit" aria-label="Open Add New Menu" className={"CyHeaderAddNewButton"}>
							<Add />
						</IconButton>
					</Tooltip>
					<Menu
						id="menu-appbar"
						getContentAnchorEl={null}
						anchorEl={anchorElement}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center"
						}}
						open={open}
						onClose={closeMenu}
					>
						<MenuItem disabled selected className="title">
							Create a New
						</MenuItem>
						{addNewMenuItems.map(item => {
							return (
								<MenuItem key={`${item.type}AddNewMenuItem`} onClick={openDrawer(item.type)} data-cy={item.type}>
									{item.name}
								</MenuItem>
							);
						})}
					</Menu>
				</React.Fragment>
			);
		}

		return "";
	}
}

const mapStateToProps = state => ({
	anchorElement: state.transientElements.anchorElement[ANCHOR],
	addNewMenuItems: state.header.addNewOptions || [],
	currentGrid: state.grid.gridType
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	setAnchorElement: Creators.setAnchorElement,
	fetchCalendar: CalendarCreators.eventsFetch
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AddNew)
);
