import React from "react";
import { 
	Table,
	TableRow,
	TableCell,
	TableBody
} from "@material-ui/core";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { AnonymizeReportProps } from "./typeDefs";
import { startCase } from "lodash";
import { formatPhone, formatAddress } from "@react-ui/common/format";

const useStyles = makeStyles(styles);

export const prepareDataForDisplay = (input: any): any => {
	if (input === null || input === undefined) {
		return "";
	} else if (typeof input === "boolean") {
		return input.toString();
	} else if (Array.isArray(input)) {
		return input.map(elem => prepareDataForDisplay(elem)).join(",\n");
	} else if (input instanceof Object) {
		let toReturn = "";
		Object.keys(input).forEach(val => {
			if (!(val in input) || input[val] === null) {
				toReturn += "";
			}
			else if (val !== "recs" && val !== "DisplayNm") {
				if (["EmailAddress", "Title", "ConnectionValue", "UDFieldValue"].includes(val)) {
					toReturn += `${input[val]}`;
				} else if (["PhoneNumber"].includes(val)) {
					toReturn += `${formatPhone(input[val])}`;
				} else {
					toReturn += `${prepareDataForDisplay(input[val])}\n`;
				}
			} else if (val === "recs" && Array.isArray(input.recs) && input.recs.length && input.recs[0].CountryCode) {
				const address = input.recs[0];
				toReturn += `${formatAddress(address.AddressLine1, address.AddressLine2, address.AddressLine3, address.City, address.StateCode, address.PostalCode)}`;
			} else {
				toReturn += `${prepareDataForDisplay(input[val])}\n`;
			}
		});
		return toReturn;
	}
	return input;
};


const AnonymizeReport: React.FC<AnonymizeReportProps> = ({ data }) => {
	const classes = useStyles();
	if (!data) {
		return null;
	}

	return <>
		<Table>
			<TableBody>
				{ Object.keys(data).map(key => <TableRow key={key}>
					<TableCell> {startCase(key)} </TableCell>
					<TableCell className={classes.dataDisplay}>
						{prepareDataForDisplay(data[key])}
					</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	</>;
};

export default AnonymizeReport;
