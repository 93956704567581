const styles = theme => ({
	icon: {
		display: "block",
		fontSize: 92,
		color: "#994444"
	},
	text: {
		fontSize: 16
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "50%",
		justifyContent: "center",
		alignItems: "center"
	}
});

export default styles;
