const styles = theme => ({
	appBar: {
		...theme.mixins.toolbar,
		backgroundColor: "#000",
		boxShadow: "none",
		zIndex: 99,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShifted: {
		marginLeft: theme.constants.leftNav.width,
		width: `calc(100% - ${theme.constants.leftNav.width}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	productSwitcher: {
		color: "#fff",
		margin: "0 10px",
		padding: "16px",
		"&:hover": {
			color: "#fff"
		}
	},
	productSwitcherMenuItem: {
		backgroundColor: "white",
		"&:hover": {
			backgroundColor: theme.palette.primary.main
		}
	},
	appBarTitle: {
		display: "flex",
		flex: 1,
		marginLeft: "10%",
		color: "#fff"
	},
	appBarProductSwitcherLabel: {
		color: "#fff",
		fontSize: "16px"
	},
	appBarProductSwitcherLabelNoProducts: {
		margin: "0 10px",
		padding: "16px",
		color: "#fff",
		fontSize: "16px"
	},
	root: {
		width: "100%"
	},
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: "0.5em",
		marginRight: "0.5em"
	},
	iconButton: {

	},
	menuButtonIconClosed: {
		transition: theme.transitions.create(["transform"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		transform: "rotate(0deg)"
	},
	menuButtonIconOpen: {
		transition: theme.transitions.create(["transform"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		transform: "rotate(180deg)"
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block"
		}
	},
	appBarLeftNavToggler: {
		width: "60px",
		paddingLeft: "0px",
		paddingRight: "0px"
	},
	appLogo: {
		paddingLeft: "12px"
	},
	menuIcon: {
		marginRight: theme.spacing(1)
	}
});

export default styles;
