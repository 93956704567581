import React, { useEffect } from "react";
import { connect } from "react-redux";
import IconButton from "../IconButton";
import { withStyles } from "@material-ui/core/styles";
import { History } from "@material-ui/icons";
import { Creators } from "../../../actions/transientElementActions";
import { Creators as HeaderCreators } from "../../../actions/headerActions";
import RecentlyViewedTab from "./RecentlyViewedTab";
import styles from "./styles";
import { GLOBAL_TABBED_DRAWER, RECENTLY_VIEWED_DRAWER_NAME } from "../../../common/consts";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const RecentlyViewed = ({ bookmarksFetch, recentlyViewedFetch, bookmarks, setDrawerOpen }) => {

	useEffect(() => {
		bookmarksFetch();
	}, []);

	const tabs = [
		{ name: "Recently Viewed", component: <RecentlyViewedTab type="recentlyViewed" /> }
	];

	return (
		<div>
			<Tooltip title={"Recently Viewed"}>
				<IconButton
					onClick={() =>
						setDrawerOpen(GLOBAL_TABBED_DRAWER, {
							isOpen: true,
							type: RECENTLY_VIEWED_DRAWER_NAME,
							tabs,
							activeTab: tabs[0].name,
							displayComponent: tabs[0].component,
							displaySize: "sm"
						})
					}
					color="inherit"
					aria-label="Open Recently Viewed Drawer"
				>
					<History style={{ width: 31, height: 28 }} />
				</IconButton>
			</Tooltip>
		</div>
	);
};

const mapStateToProps = state => ({
	bookmarks: state.header.bookmarks
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	bookmarksFetch: HeaderCreators.bookmarksFetch,
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(RecentlyViewed)
);
