import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, CardActions, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Creators } from "../../../actions/dashboardActions";
import CircularProgressCentered from "../../CircularProgressCentered";
import { getModulePath } from "../../../common/moduleData";
import styles from "./styles";

const Accounts = ({ fetchAccounts, fetchStatuses, setDashboardData, classes, data = {} }) => {
	useEffect(() => {
		fetchAccounts();
		fetchStatuses();
		return () => {
			setDashboardData({ accounts: undefined });
			setDashboardData({ statuses: undefined });
		};
	}, []);

	const { accounts, statuses = [] } = data;

	if (!accounts) {
		return <CircularProgressCentered size={75} color="primary" />;
	}

	const status = statuses.reduce((map, status) => {
		map[status.StatusID] = status.Title;
		return map;
	}, {});

	return (
		<React.Fragment>
			<span className={classes.accountTitleSpan}>
				<Typography variant="h2" className={classes.dashletTitle}>
					My Accounts
				</Typography>
			</span>
			<span className={classNames(classes.accountTitleSpan, classes.accountTitleSpanRight)}>
				<Link className={classes.seeAllLink} to={"/myProfile/account"}>See All</Link>
			</span>
			{!accounts.length ? 
					<Typography variant="body1" className={classes.accountNoAccounts}>
						You currently have no assigned accounts.
					</Typography>
				 : ""}
			<List>
				{accounts.map((account, i) => {
					return (
						<React.Fragment key={`account_${i}`}>
							<ListItem>
								<ListItemText
									primaryTypographyProps={{ variant: "h6" }}
									secondaryTypographyProps={{ className: "body3" }}
									primary={account.CompanyName}
									secondary={`Status: ${status[account.StatusID] || account.IsActive ? "Active" : "Inactive"}`}
								/>
								<CardActions>
									<Button disableRipple color="secondary" size="small">
										<Link className="routerLink" to={getModulePath("account", account.RecordID)}>
											View Account
										</Link>
									</Button>
								</CardActions>
							</ListItem>
						</React.Fragment>
					);
				})}
			</List>
		</React.Fragment>
	);
};

const mapStateToProps = state => ({
	data: state.dashboard.data
});

const mapDispatchToProps = {
	fetchAccounts: Creators.fetchAccounts,
	setDashboardData: Creators.setDashboardData,
	fetchStatuses: Creators.fetchStatuses
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Accounts));
