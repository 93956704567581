import { wrapFormatValue, FORMAT } from "./format";

export const FIELD_TYPES = {
	// added values should be lowercase to match line 12 logic
	SELECT: "select",
	CHIP: "chip",
	DYNAMIC: "dynamic",
	COMPOSITE: "composite",
	TEXT: "text",
	CHIPLINK: "chiplink",
};

export const getFieldValue = (field, data) => {
	// @returns a value or array of values
	const { SELECT, CHIP, DYNAMIC, COMPOSITE, TEXT, CHIPLINK } = FIELD_TYPES;
	const type = field.type ? field.type.toLowerCase() : TEXT;
	const functionsByType = {
		[SELECT]: getSelectValue,
		[CHIP]: getChipValue,
		[CHIPLINK]: getChipLinkValue,
		[COMPOSITE]: getCompositeValue,
		[DYNAMIC]: getDynamicValue,
		[TEXT]: getTextValue
	};
	if (functionsByType[type]) {
		return functionsByType[type](field, data);
	}
	return getOther(field, data);
};

const getTextValue = (field, data) => data[field.name];

const getSelectValue = (field, data) => {
	return data[field.name].label;
};

const getChipValue = (field, data) => {
	if (data[field.name] === null || data[field.name] === undefined) {
		return data[field.name];
	}

	if (data[field.name].recs !== undefined && data[field.name].recs !== null) {
		return data[field.name].recs.map(value => value[field.map.label]);
	} else if (data[field.name].recs === null) {
		return data[field.name].recs;
	}

	return data[field.name].map(opt => opt.label);
};

const getChipLinkValue = (field, data) => {
	if (data[field.name] && Array.isArray(data[field.name].recs)) {
		return data[field.name].recs.map(value => ({ label: value[field.map.label], link: `${field.link}${value[field.map.value]}` }));
	} else if (Array.isArray(data[field.name])) {
		return data[field.name].map(value => ({ label: value.label, link: `${field.link}${value.value}` }));
	}
};

const getCompositeValue = (field, data) => {
	const rows = field.compositeField && field.compositeField.rows.map((row, i) => {
		let value = "";
		row.columns.forEach(column => {
			column.fields.forEach(fld => {
				value += data[fld.name] ? applyFieldAffixes(fld, data[fld.name]) : "";
			});
		});
		return value;
	});
	return rows && rows.filter(row => !!row).join("\n");
};

const getDynamicValue = (field, data) => {
	const valueArray = field.dynamicString.fields.map(fieldName => {
		if (fieldName === "BirthMonth") {
			return wrapFormatValue(data[fieldName], FORMAT.MONTH);
		} else {
			return data[fieldName];
		}
	});
	let value = "";
	valueArray.forEach(val => {
		if (val && value.length && val.toString().length) {
			value += field.dynamicString.delimiter || " ";
		}
		value += val || "";
	});

	return value;
};

const getOther = (field, data) => {
	return applyFieldAffixes(field, data[field.name] || "");
};

const applyFieldAffixes = (field, value) => {
	return `${field.valuePrefix || ""}${value}${field.valueSuffix || ""}`;
};
