import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";

import { GLOBAL_DIALOG } from "../../common/consts";
import { Creators } from "../../actions/transientElementActions";
import styles from "./styles";
import { connect } from "react-redux";
/**
 * Alert Dialog (not to confuse with alert on the top right)
 *
 * According to https://material-ui.com/demos/dialogs/ :
 *
 * Alerts are urgent interruptions, requiring acknowledgement,
 * that inform the user about a situation.
 *
 * Most alerts don't need titles.
 * They summarize a decision in a sentence or two by either:
 * 	- Asking a question (e.g. "Delete this conversation?")
 * 	- Making a statement related to the action buttons
 *
 * Use title bar alerts only for high-risk situations,
 * such as the potential loss of connectivity.
 * Users should be able to understand the choices based on
 * the title and button text alone.
 *
 * If a title is required:
 * 	- Use a clear question or statement with an explanation
 * 		in the content area, such as "Erase USB storage?".
		- Avoid apologies, ambiguity, or questions,
			such as “Warning!” or “Are you sure?”
 */
const AlertDialog = ({ classes, isOpen, title, content = "", actions = [], setDrawerOpen, clearElement, handleExit }) => {
	const handleClose = () => setDrawerOpen(GLOBAL_DIALOG, { isOpen: false });
	if (actions.length === 0) {
		actions.push({});
	}
	const actionButtons = actions.map((action, idx) => {
		const { handler, color, label } = action;
		return (
			<Button key={`alert-actions-${idx}`} color={color} className={"CyDialogActionButtons"} onClick={handler || handleClose}>
				{label || "Dismiss"}
			</Button>
		);
	});

	return (
		<Dialog open={isOpen} onClose={handleClose} onExited={() => {
			handleExit && handleExit();
			clearElement(GLOBAL_DIALOG);
		}} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
			<DialogContent>
				<DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: content }} />
			</DialogContent>
			<DialogActions>{actionButtons}</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = ({ transientElements: { drawerOpen } }) => ({
	...drawerOpen[GLOBAL_DIALOG]
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	clearElement: Creators.clearElement
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AlertDialog)
);
