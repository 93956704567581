import React from "react";
import { connect, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch, useParams } from "react-router-dom";
import CircularProgressCentered from "../CircularProgressCentered";
import { Creators } from "../../actions/summaryActions";
import SummaryMiniPanel from "../SummaryMiniPanel";
import SummaryTabs from "../SummaryTabs";
import styles from "./styles";
import NotFound from "../NotFound";
import Amenity from "./Display/Amenity";
import Attribute from "./Display/Attribute";
import BenefitSummary from "./Display/BenefitSummary";
import MeetingFacility from "./Display/MeetingFacility";
import {
	SUMMARY,
	SUMMARY_PARAM,
	AMENITY,
	BENEFITSUMMARY,
	MEETINGFACILITY,
	MYPROFILE,
	ATTRIBUTE
} from "../../common/consts";
import MosaicGrid from "../Grid";
import AlternateSummary from "./AlternateSummary";

const SubGrid = () => {
	const { recId, module, tab } = useParams();

	const numRecId = useSelector(state => state.summary.data.recId);

	const displays = {
		[MEETINGFACILITY]: <MeetingFacility key={`${MEETINGFACILITY}`} strId={recId}/>,
		[AMENITY]: <Amenity key={`${AMENITY}`} recId={numRecId || recId} module={module} />,
		[BENEFITSUMMARY]: <BenefitSummary key={`${BENEFITSUMMARY}`} recId={numRecId || recId}/>,
		[ATTRIBUTE]: <Attribute key={`${ATTRIBUTE}`} recId={numRecId || recId} module={module} />,
	};

	if ([MEETINGFACILITY, AMENITY, BENEFITSUMMARY, ATTRIBUTE].includes(tab)) {
		return displays[tab];
	} else {
		return <MosaicGrid moduleName={tab} parentModule={module} parentId={recId} key={`grid-${module}-${tab}-${recId}`} />;
	}
};

const MyProfileGrid = () => {
	const { tab } = useParams();
	return <MosaicGrid moduleName={tab} parentModule={MYPROFILE} parentId={0} key={`grid-${MYPROFILE}-${tab}-${0}`} />;
};

const Summary = ({ classes, tabsFetching, shouldRedirect, summaryErr, adminModule, summaryTabSettings }) => {
	let { module, recId } = useParams();
	module = adminModule || module;

	shouldRedirect = !!(shouldRedirect || (module !== MYPROFILE && !module) || (module !== MYPROFILE && !recId));

	const notFoundProps = { clearError: true };
	if (summaryErr) {
		notFoundProps.message = summaryErr;
	}

	const devolopmentEnv = false; //["dms-development", "dms-dev"].includes(process.env.PRODUCT);

	if (devolopmentEnv) {
		return <AlternateSummary />
	}

	return shouldRedirect || !summaryTabSettings[module] ?

		<NotFound {...notFoundProps} /> :

		<React.Fragment>
			<Grid
				container
				wrap="nowrap"
				justify="flex-start"
				spacing={0}
				className={classes.summaryPageWrapper}
			>
				<Grid item className={classes.miniPanelWrapper}>
					<SummaryMiniPanel moduleName={module} key={`${module}-summaryminipanel`}/>
				</Grid>
				<Grid item className={classes.summaryContentWrapper}>
					<Grid container alignItems="stretch" direction="column" spacing={0}>
						<Grid item>
							{tabsFetching ? (
								<CircularProgressCentered size={50} color="primary" />
							) : (
								<SummaryTabs moduleName={module} />
							)}
						</Grid>
						<Grid item>
							<Switch>
								<Route path={`/${SUMMARY}/:module/${SUMMARY_PARAM}/:tab`}>
									<SubGrid/>
								</Route>
								<Route path={`/${MYPROFILE}/:tab`}>
									<MyProfileGrid/>
								</Route>
							</Switch>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>;
};


const mapStateToProps = (state) => {
	return {
		tabsFetching: state.summary.tabsFetching,
		shouldRedirect: state.summary.shouldRedirect,
		summaryErr: state.summary.summaryErr,
		summaryTabSettings: state.summary.tabs
	};
};

const mapDispatchToProps = {
	summaryReset: Creators.summaryReset
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( withStyles(styles)(Summary) );
