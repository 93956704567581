import React from "react";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	root: {
		padding: theme.spacing(0.5),
		width: 32 + theme.spacing(1),
		height: 32 + theme.spacing(1),
		marginRight: theme.spacing(1),
		"&:hover": {
			backgroundColor: theme.colors.gray700
		}
	}
});

const IconWrapper = React.forwardRef((props, ref) => <IconButton className={props.classes.iconButton} ref={ref} {...props} />);

export default withStyles(styles)(IconWrapper);
