const styles = theme => ({
	container: {
		marginBottom: theme.spacing(2),
		width: "100%",
		flexGrow: 1
	},
	showMoreTab: {
		flexGrow: 0,
		paddingTop: 0,
		"& svg": {
			position: "absolute",
			right: "43px",
			height: "15px",
			fontSize: "0.9rem",
			paddingTop: "5px",
			"@media screen and (max-width: 1100px)": {
				right: "5px"
			},
			"@media screen and (max-width: 960px)": {
				right: "-2px"
			}
		},
		"@media screen and (max-width: 1100px)": {
			minWidth: 200
		}
	},
	uitabs: {
		flexGrow: 1,
		overflowX: "hidden"
	},
	verticaluitabs: {
		flexGrow: 1,
		overflowX: "hidden",
		"& span": {
			textAlign: "left"
		}
	},
	reorderMainDrawer: {
		margin: "20px 15px",
		"& button": {
			backgroundColor: "#79a935",
			margin: "10px",
			padding: "8px 20px",
			"& span": {
				color: "#fff"
			}
		},
		"& button:hover": {
			backgroundColor: "#79a935"
		}
	},
	tabDrawer: {
		"& header": {
			height: "inherit",
			backgroundColor: "#0088c6"
		},
		"& > div": {
			marginTop: `${theme.constants.header.height}px`,
			height: `calc(100% - ${theme.constants.header.height}px)`,
			minWidth: "250px"
		}
	},
	rightToolbar: {
		marginLeft: "auto",
		marginRight: "-12px"
	},
	reorderTabs: {
		marginLeft: "5px"
	},
	listItem: {
		border: `solid 2px ${theme.palette.primary.light}`,
		borderRadius: "3px",
		padding: "5px 5px 0",
		margin: "6px 0",
		cursor: "move",
		"& svg, & p": {
			color: "#8b8b8b"
		},
		"& p": {
			display: "inline-block",
			position: "relative",
			top: "-7px"
		}
	},
	resetButton: {
		backgroundColor: "#fff !important",
		border: "solid 1px #79a935",
		"& span": {
			color: "#79a935 !important"
		}
	},
	link: {
		textDecoration: "none",
		capitalize: true
	},
	badge: {
		"& > i": {
			display: "none"
		}
	},
	badgeContent: {
		right: -15
	},
	badgeContentSingleDigit: {
		right: -12
	},
	moduleTab: {
		opacity: 1
		
	}
});

export default styles;
