import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import classNames from "classnames";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import styles from "../styles";
import CollapsibleSectionCellDisplay from "./CollapsibleSectionCellDisplay";
import MosaicGrid from "../../Grid";
import useGraphServer from "../../../hooks/useGraphServer";

const MEETINGFACILITY_TYPE = "account_meetingFacility";

const MeetingFacility = ({ classes, strId }) => {
	let data = [];
	const noDataMessage = "No meeting facilities were found for this record";
	const tabs = [];

	const [isLoading, setLoading] = useState(false);
	const [meetingFacilities, setMeetingFacilities] = useState([]);
	const [formDef, setFormDef] = useState([]);

	const crm = useGraphServer();
	const recId = useSelector(state => state.summary.data.recId);

	const fetchData = id => () => {
		setLoading(true);
		crm.general.get({
			type: "account_meetingFacility",
			filter: {
				recId: Number(id)
			},
			fields: `AccountID TenantID NumberOfRooms TotalSize LargestRoom CeilingHeight TheaterCapacity 
			BanquetCapacity ClassroomCapacity ReceptionCapacity SleepingRooms Suites Villas ExhibitSpace 
			NumberOfExhibits NumberOfBooths MeetingFacilityDesc SpaceNotes FloorplanPath CreatedDt CreatedBy 
			LastModifiedDt LastModifiedBy `,
			formDef: `
				fields {
					Name
					Label
				}
				actions {
				  	Name
				  	Label
				}`
		}).then(res => {
			setMeetingFacilities(res.recs);
			setFormDef(res.formDef);
		}).finally(() => setLoading(false));
	};

	useEffect(() => {
		const pat = /[a-z]+/i;
		if(!pat.test(recId)) {
			fetchData(recId)();
		}
	}, [recId]);

	//Conform the results to the standard data set used by the shared ExpansionPanels.
	if (meetingFacilities && meetingFacilities.length) {
		const meetingFacility = meetingFacilities[0];

		const cellDataArray = ["NumberOfRooms", "TotalSize", "LargestRoom", "CeilingHeight", "TheaterCapacity", "BanquetCapacity",
			"ClassroomCapacity", "ReceptionCapacity", "SleepingRooms", "Suites", "Villas", "NumberOfBooths", "ExhibitSpace", 
			"NumberOfExhibits", "MeetingFacilityDesc", "SpaceNotes", "FloorplanPath"];

		data = [{
			RecordID: meetingFacility.AccountID,
			Title: "Meeting Facilities",
			CellData: cellDataArray.map((key, index) => {
				const toPushBase = {
					RecordID: index,
					Title: formDef?.fields?.filter(val => val.Name === key)?.[0]?.Label,
				};
				if (meetingFacility[key] === null) {
					return {
						...toPushBase,
						Value: ""
					};
				} else if (key === "FloorplanPath" && meetingFacility[key]) {
					return {
						...toPushBase,
						Value: <Link href={meetingFacility[key]} target="_blank">View File</Link>
					};
				} else {
					return {
						...toPushBase,
						Value: typeof meetingFacility[key] === "boolean" ? (meetingFacility[key] === true ? "Yes" : "No") : meetingFacility[key]
					};
				}
			})
		}];
	}

	if (formDef?.actions?.filter(action => action.Name === "submit").length) {
		tabs.push({ name: "Edit Meeting Facilities" });
	}

	return (
		<React.Fragment>
			<CollapsibleSectionCellDisplay
				recId={recId}
				type={MEETINGFACILITY_TYPE}
				fetchData={fetchData(recId)}
				isLoading={isLoading}
				tabs={tabs}
				data={data}
				classes={classes}
				noDataMessage={noDataMessage}
				sortCellData={false} />
			<ExpansionPanel defaultExpanded className={classes.section} key={"sectionalDisplay-section2"}>
				<ExpansionPanelSummary className={classNames(classes.sectionHeader, "CyFormSectionHeader")} expandIcon={<ExpandMoreIcon />}>
					Additional Rooms
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classNames(classes.roomGridDetail)}>
					<MosaicGrid fullWidth moduleName={"meetingFacilityRoom"} parentModule={"account"} parentId={strId} key={"meetingRoomMosaicGrid"}/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</React.Fragment>
	);
};

export default withStyles(styles)(MeetingFacility);
