import React, { useCallback, useMemo } from "react";
import { BulkActionData, GridModalState } from "./typeDefs";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import useGraphServer from "../../hooks/useGraphServer";
import { Button, TableRow, TableCell, Table, TableHead, TableBody } from "@material-ui/core";
interface Props {
	data: BulkActionData;
	moduleName: string;
	setModalState: (state: GridModalState) => void;
	refetchGrid: any;
}

const useStyles = makeStyles(styles);

const FillRequest: React.FC<Props> = ({ data, moduleName, setModalState, refetchGrid }: Props) => {
	const classes = useStyles();
	const crmGraphServer = useGraphServer();
	const breakDownByBrochureCode = useMemo(() => {
		const toReturn: any = {};
		data.forEach(val => {
			if (val.BrochureCode) {
				if (toReturn[val.BrochureCode]) {
					toReturn[val.BrochureCode]++;
				} else {
					toReturn[val.BrochureCode] = 1;
				}
			} else {
				if (toReturn[""]) {
					toReturn[""]++;
				} else {
					toReturn[""] = 1;
				}
			}
		});
		return toReturn;
	}, [data]);

	const strip = useCallback(id => {
		if (!id || typeof id === "number") {
			return id;
		}

		let rtnId = id;
		const recordIdMatch = id.match(/\d+/g);
		if (recordIdMatch !== null) {
			rtnId = recordIdMatch[0];
		}

		return Number(rtnId);
	}, []);

	const handleFillRequest = useCallback(async () => {
		const ids = data.map(value => `{ BrochureID: ${value.recId}, InquiryID: ${strip(value.RecordID?.props?.children)} }`);
		const res = await crmGraphServer.general.set_old({
			type: "batch_fill_request",
			queryString: `batch_fill_request (
				ids: [${ids.join(", ")}]
			) {
				success
				message
				snackbarMessage
				snackbarDisplayType
			}`
		});
		if (res.batch_fill_request.success) {
			setModalState({
				isOpen: false
			});
			refetchGrid(moduleName);
		}
	}, [data]);

	return <div>
		<div className={classes.bulkTagBanner}>
			{ `${data.length} brochure${data.length > 1 ? "s" : ""} will be filled` }
		</div>
		<div className={classes.bulkFormWrapper}>
			{
				breakDownByBrochureCode && <Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Brochure Code</TableCell>
							<TableCell align="right"># of brochures to be filled</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ Object.keys(breakDownByBrochureCode).map(key => {
							return <TableRow key={key}>
								<TableCell component="th" scope="row">
									{key}
								</TableCell>
								<TableCell align="right">{breakDownByBrochureCode[key]}</TableCell>
							</TableRow>;
						})}
					</TableBody>
				</Table>
			}
		</div>
		<div style={{ float: "right" }}>
			<Button onClick={(): void => setModalState({ isOpen: false })} variant="contained" color="secondary" style={{ marginRight: "0.5rem" }}>
				Cancel
			</Button>
			{
				<Button onClick={handleFillRequest} variant="contained" color="primary">
					Fill
				</Button>
			}
		</div>
	</div>;
};

export default FillRequest;
