import React, { Component } from "react";
import { connect } from "react-redux";
import { DropTarget as dropTarget } from "react-dnd";
import ReorderableNavTabsItem from "./ReorderableNavTabsItem";

class ReorderableNavTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reorderedTabs: []
		};
	}

	componentDidMount() {
		const { reorderedTabs } = this.props;
		this.setState({ reorderedTabs });
	}

	moveTab = (id, hoverIndex) => {
		const { index } = this.findTab(id);
		const reorderedTabs = this.reorderTabs(index, hoverIndex);
		if (hoverIndex || hoverIndex === 0) {
			this.setState({ reorderedTabs });
		}
		this.props.moveTab(reorderedTabs);
	};

	findTab = label => {
		const { reorderedTabs } = this.state;
		const { moduleName } = this.props;
		let index;
		reorderedTabs[moduleName].filter((t, i) => {
			if (t.label.replace(" ", "") === label) {
				index = i;
			}
		});

		return {
			index
		};
	};

	reorderTabs = (startIndex, endIndex) => {
		const { reorderedTabs } = this.state;
		const { moduleName } = this.props;

		if (endIndex || endIndex === 0) {
			let result = Array.from(reorderedTabs[moduleName]);
			const [removed] = result.splice(startIndex, 1);
			if (endIndex !== 0) {
				result.splice(endIndex, 0, removed);
			} else {
				result = [removed, ...result];
			}

			result.map((tab, index) => {
				tab.sortOrder = index + 1;
				return tab;
			});

			return { ...reorderedTabs, [moduleName]: result };
		}

		return reorderedTabs;
	};

	render() {
		const { connectDropTarget, moduleName } = this.props;
		const { reorderedTabs } = this.state;

		return connectDropTarget(
			<div>
				{reorderedTabs[moduleName] && reorderedTabs[moduleName].sort((a, b) => b.sortOrder - a.sortOrder > 0 ? -1 : 1).map(tab => (
					<ReorderableNavTabsItem key={`${tab.label}ReorderTab`} id={tab.label.replace(" ", "")} text={tab.label} moveTab={this.moveTab} findTab={this.findTab} />
				))}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	reorderedTabs: state.summary.tabs
});

const Types = {
	ITEM: "reorderedTabs"
};

export default connect(mapStateToProps)(
	dropTarget(Types.ITEM, {}, connect => ({
		connectDropTarget: connect.dropTarget()
	}))(ReorderableNavTabs)
);
