import React from "react";
import Message from "../Message";
import TabbedDrawer from "../TabbedDrawer";
import AlertDialog from "../Dialog";
import FullScreenSpinner from "./Spinner";


const Global = () => {
	return (
		<React.Fragment>
			<Message/>
			<TabbedDrawer />
			<AlertDialog />
			<FullScreenSpinner />
		</React.Fragment>
	);
};

export default Global;
