import toLower from "lodash/toLower";
/**
 * Compare two events
 * 
 * first order by priority and then by title.
 * @param {*} a 
 * @param {*} b 
 */
export const compareEvent = (a, b) => {
	let diff = 0;
	if (!diff && Number.isInteger(a.priorityOrder) && Number.isInteger(b.priorityOrder)) {
		diff = a.priorityOrder - b.priorityOrder;
	}
	if (!diff) {
		diff = toLower(a.title) > toLower(b.title) ? 1 : -1;
	}
	return diff;
};
