import React, { useCallback } from "react";
import FileInput from "@react-ui/components/FileInput";
import { FormHelperText, InputLabel } from "@material-ui/core";
import FlexContainer from "@react-ui/components/FlexContainer";
import FilePreview from "./FilePreview";


interface Props {
	value?: File[];
	onChange: (files: File[]) => void;
	limit?: number;
	errorMsg?: string;
	label: string;
}

const FileField: React.FC<Props> = ({
	value = [],
	onChange,
	limit = 0,
	errorMsg = "",
	label = ""
}) => {

	const handleRemoveFile = useCallback((i: number) => (): void => {
		value.splice(i, 1);
		onChange(value);
	}, [value]);
	
	const handleAddFile = useCallback((files) => {
		onChange([...value, ...files]);
	}, [value]);

	return <div>
		<InputLabel>{label}</InputLabel>
		<FileInput singular={limit === 1} onFileAdded={handleAddFile} />
		<FormHelperText error={!!(errorMsg?.length)}>{errorMsg}</FormHelperText>
		<FlexContainer wrap={true}>
			{value.map((file, i) => {
				return <FilePreview file={file} key={file.name} handleRemoveFile={handleRemoveFile(i)} />;
			})}
		</FlexContainer>
	</div>;
};

export default FileField;
