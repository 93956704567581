import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ImageCarouselItem from "./Item";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import {
	ImageCarouselState,
	Props,
	IndicatorProps
} from "./typeDefs";

const useStyles = makeStyles(styles);
let globalTimer: any = null;

const ImageCarousel: React.FC<Props> = (props) => {
	const { items } = props;
	const indicatorLength = items ? items.length : 0;

	const [state, setState] = useState<ImageCarouselState>({
		active: 0,
		direction: "right"
	});

	const prev = (): void => {
		const nextActive = state.active - 1 < 0 ? items.length - 1 : state.active - 1;

		setState({
			active: nextActive,
			direction: "left"
		});
	};

	const next = (): void => {
		const nextActive = state.active + 1 > items.length - 1 ? 0 : state.active + 1;

		setState({
			active: nextActive,
			direction: "right"
		});
	};

	const indicator = (index: number): void => {
		setState({
			active: index,
			direction: "up"
		});
	};

	const slide = (): void => {
		const slide = items ? items[state.active].SlideInterval * 1000 : 4000;
		if (globalTimer) {
			clearInterval(globalTimer);
		}
		globalTimer = setInterval(() => next(), slide);
	};

	useEffect(() => {
		slide();
	}, [state.active]);

	const classes = useStyles();
	const buttonCssClassValue = `${classes.button} ${classes.buttonVisible} ${classes.fullHeightHoverButton}`;
	const buttonWrapperCssClassValue = `${classes.buttonWrapper} ${classes.fullHeightHoverWrapper}`;

	return (
		<React.Fragment>
			<div className={classes.root}>
				{
					items.map((child: Record<string, any>, index: number) => {
						return (
							<ImageCarouselItem
								key={`carousel-item${index}`}
								active={index === state.active}
								data={child}
								direction={state.direction}
							/>
						);
					})
				}

				{ indicatorLength > 1 ? 
					<React.Fragment>
						<div className={`${buttonWrapperCssClassValue} ${classes.next}`}>
							<IconButton className={`${buttonCssClassValue} ${classes.next}`} onClick={next} aria-label="Next">
								<NavigateNextIcon />
							</IconButton>
						</div>

						<div className={`${buttonWrapperCssClassValue} ${classes.prev}`}>
							<IconButton className={`${buttonCssClassValue} ${classes.prev}`} onClick={prev} aria-label="Previous">
								<NavigateBeforeIcon />
							</IconButton>
						</div>
					</React.Fragment>
					: null 
				}
			</div>
			<div>
				{ indicatorLength > 1 ? 
					<Indicators
						classes={classes}
						length={indicatorLength}
						active={state.active}
						press={indicator}
					/> 
					: null 
				}
			</div>
		</React.Fragment>
	);
};

const Indicators: React.FC<IndicatorProps> = (props) => {
	const classes = props.classes;

	const indicators = [];
	for (let i = 0; i < props.length; i++) {
		const item = <FiberManualRecordIcon
			key={i}
			className={`${classes.indicator} ${i === props.active ? classes.active : ""}`}
			onClick={(): void => props.press(i)}
		/>;

		indicators.push(item);
	}

	return (
		<div className={classes.indicators}>
			{indicators}
		</div>
	);
};

export default withStyles(styles)(ImageCarousel);
