import React, { useMemo, useCallback } from "react";
import useTransientElements from "../../hooks/useTransientElements";
import useGraphServer from "../../hooks/useGraphServer";
import upperFirst from "lodash/upperFirst";
import { FormActions } from "../../common/consts";
import { GridModalState } from "../Modal/typeDefs";
import { useDispatch, useSelector } from "react-redux";
import { Creators as FormCreators } from "../../actions/recordActions";
import Diff from "../Modal/Diff";
import BulkTagForm from "../Modal/BulkTagForm";
import BulkCommForm from "../Modal/BulkCommForm";
import BulkExportForm from "../Modal/BulkExportForm";
import FillRequest from "../Modal/FillRequest";
import ImageCarouselModal from "../Modal/ImageCarouselModal";
import BulletinModal from "../Modal/BulletinModal";
import { setRecentlyViewed } from "../../common/recentlyViewed";
import cloneDeep from "lodash/cloneDeep";
import store from "../../store";
import FormFrame from "../Form/FormFrame";

interface BulkActionArgs {
	data: Record<string, any>[];
}

interface ActionArgs {
	data: Record<string, any>;
}

type CallbackFunction = (data?: ActionArgs | BulkActionArgs) => void | Promise<void>

interface Input {
	moduleName: string;
	parentModule?: string;
	parentId?: number | string;
	setModalState: (state: GridModalState) => void;
}

const recIdMap: any = {
	offer: "SpecialOfferID",
	eventCalendar: "EventCalendarID",
	contact: "ContactID",
	relatedAccount: "AccountID",
	inquiry: "InquiryID",
	brochure: "BrochureID",
	account: "AccountID",
	listing: "ListingID"
};

const refIdMap: any = {
	relatedAccount: "ParentAccountID"
};

const useCallbackMap = ({ moduleName, parentModule, parentId, setModalState }: Input): Record<string, CallbackFunction> => {
	const refId = parentId;
	const primaryKeyMap = recIdMap[moduleName];
	const secondaryKeyMap = refIdMap[moduleName];
	const { openForm, openDialog, closeDialog, setDrawerOpen } = useTransientElements();
	const crmGraphServer = useGraphServer();
	const dispatch = useDispatch();
	const logDelete = useCallback((m) => dispatch(FormCreators.logUpdate(m)), []);
	const hasSummary: string[] = useSelector((state: any) => state.summary.hasSummary);
	const callbackMap = useMemo((): Record<string, CallbackFunction> => {
		const result: Record<string, CallbackFunction> = {
			create: (): void => {
				openForm({ moduleName, parentModule, refId });
			},
			handleEdit: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.EDIT,
					moduleName,
					parentModule,
					recId: data[primaryKeyMap] || data.recId || data.RecordID,
					refId
				});
				if (hasSummary.includes(moduleName)) {
					setRecentlyViewed({ crmGraphServer, type: moduleName, record: data });
				}				
			},
			handleDelete: async ({ data }: ActionArgs) => {
				const testResults = await crmGraphServer.general.get_old({
					type: "grid_delete_check",
					queryString: `grid_delete_check(
						recordId: "${data[primaryKeyMap] || data.recId}",
						moduleName: "${moduleName}"${parentModule ? `,
						parent: "${parentModule}"` : ""}
					) { 
					canDelete dialogMessage }`
				});

				const actions = [
					{
						label: "Cancel",
						handler: closeDialog
					}
				];

				if(testResults.canDelete) {
					const refId = data[secondaryKeyMap] || data.refId;
					const refIdString = refId ? `refId: "${refId}",` : "";

					actions.push({
						label: "OK",
						handler: async () => {
							await crmGraphServer.general.set_old({
								queryString: `grid_delete(
										id: "${data[primaryKeyMap] || data.recId}",
										${refIdString}
										moduleName: "${moduleName}"${parentModule ? `,
										parent: "${parentModule}"` : ""}
									) {
									success
									message
									snackbarMessage
									snackbarLinkType
									snackbarDisplayType
								}`,
							});
							closeDialog();
							logDelete(moduleName);
						}
					});
				}

				openDialog({
					dialogMessage: testResults.dialogMessage,
					dialogSubText: testResults.canDelete ? "Click OK to confirm, or Cancel to abort." : "Press Cancel to close.",
					actions
				});
			},
			handleClone: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.CLONE,
					moduleName,
					parentModule,
					recId: data[primaryKeyMap] ||data.recId || data.RecordID,
					refId
				});
			},
			handleReview: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.REVIEW,
					moduleName,
					parentModule,
					PendingID: data.PendingID,
					refId
				});				
			},
			handleRestore: ({ data }: ActionArgs) => {
				openDialog({
					dialogMessage: `You're about to restore this ${moduleName}.`,
					dialogSubText: "Click OK to confirm. Click Cancel to go back.",
					actions: [
						{
							label: "Cancel"
						},
						{
							label: "OK",
							color: "primary",
							handler: async (): Promise<void> => {
								await crmGraphServer.general.restore({
									moduleName,
									recId: data[primaryKeyMap] || data.recId
								});
								closeDialog();
								logDelete(moduleName);
							}
						}
					]
				});
			},
			handleAudit: async ({ data }: ActionArgs) => {
				const refKey = `${upperFirst(parentModule)}ID`;
				let labels = {};
				let modalData = { newValue: {}, oldValue: {} };
				if (data[primaryKeyMap] || data.recId) {
					const res = await crmGraphServer.general.get_old({
						type: "fullHistory",
						queryString: `
						fullHistory(filter: { 
								recId: ${data[refKey]}
								historyId: ${data[primaryKeyMap] || data.recId}
								type: "${parentModule}"
							}) { 
							recs {
								newValue
								oldValue
								labels
							}
							message
							success
						}`
					});
					if (res.success) {
						labels = JSON.parse(res.recs[0].labels);
						delete res.recs[0].labels;
						modalData = res.recs[0];
					}
				}
				setModalState({
					children: ( <Diff data={modalData} labels={labels} />),
					title: "Fields Modified",
					isOpen: true,
					maxWidth: "lg",
					useHandleBackdrop: false
				});
			},
			handleBulkTag: ({ data }: BulkActionArgs) => {
				setModalState({
					children: <BulkTagForm data={data} moduleName={moduleName} setModalState={setModalState} />,
					title: "Add Tag(s)",
					isOpen: true
				});
			},
			handleBulkTagAll: async function () {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const that: any = this;
				const bulkTagColumns = ["RecordID", "IsPending", "PendingID", "Title"];
				that.columns = that.columns.filter((column: any) => bulkTagColumns.includes(column.name));

				const res = await crmGraphServer.get_grid.fetchGridData(
					null,
					moduleName,
					{ type: parentModule, ID: refId },
					{ 
						filters: that.filter.map((filt: any) => ({
							columnName: filt.name,
							value: cloneDeep(filt.value),
							jsonify: filt.operation !== "contains"
						})),
						columns: that.columns.map((col: any) => ({
							columnName: col.name,
							type: col.format	
						}))
					},
					{
						skip: true
					}
				);

				setModalState({
					children: <BulkTagForm data={res.data} moduleName={moduleName} setModalState={setModalState} asLink={true} />,
					title: "Add Tag(s)",
					isOpen: true
				});
			},
			handleBulkComm: async ({ data }: BulkActionArgs) => {
				const res = await crmGraphServer.general.get({
					type: "myProfile",
					filter: {
						UserID: store.getState().login.userId
					},
					fields: "UserSignature"
				});

				const recIds = data.map(contact => contact.ContactID);
				const contactsRes = await crmGraphServer.general.get({
					type: "contact",
					fields: `
					ContactID
					FullName
					SendEmail
					`,
					filter: {
						recId: recIds
					}
				});

				if (contactsRes.success) {
					const contactsWithNoSendEmail = contactsRes.recs.filter((contact: any) => !contact.SendEmail)
						.map((contact: any) => contact.ContactID);
					
					data = data.filter((contact: any) => !contactsWithNoSendEmail.includes(contact.ContactID));
					
					if (contactsWithNoSendEmail.length === 0) {
						let formChange;
						if (res.success && res.count) {
							const heading = "<p></p><p></p><p>--</p>";
							formChange = res.recs[0].UserSignature ? heading.concat(res.recs[0].UserSignature) : res.recs[0].UserSignature;
						}
		
						setModalState({
							children: <BulkCommForm data={data} moduleName={moduleName} setModalState={setModalState} userSignature={formChange} />,
							title: "Send Communication(s)",
							isOpen: true
						});
					} else if (recIds.length === contactsWithNoSendEmail.length) {
						const dialogMessage = `All of your selected Contacts have opted out of communications.`;
						const actions = [
							{
								label: "OK",
								handler: closeDialog
							}
						];

						openDialog({
							dialogMessage,
							dialogSubText: "Click OK to return to the list.",
							actions
						});
					} else if (recIds.length != contactsWithNoSendEmail.length) {
						const dialogMessage = `${contactsWithNoSendEmail.length} of your selected Contacts have opted out of communications.`;
						const actions = [
							{
								label: "Cancel",
								handler: closeDialog
							},
							{
								label: "OK",
								handler: async (): Promise<void> => {
									let formChange;
									if (res.success && res.count) {
										const heading = "<p></p><p></p><p>--</p>";
										formChange = res.recs[0].UserSignature ? heading.concat(res.recs[0].UserSignature) : res.recs[0].UserSignature;
									}
					
									setModalState({
										children: <BulkCommForm data={data} moduleName={moduleName} setModalState={setModalState} userSignature={formChange} />,
										title: "Send Communication(s)",
										isOpen: true
									});
									closeDialog();
								}
							}
						];

						openDialog({
							dialogMessage,
							dialogSubText: "Click OK to proceed or Cancel to return to the list.",
							actions
						});
					}
				}
			},
			handleBrochureFill: async function ({ data }: BulkActionArgs) {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const that: any = this;
				const isFilled = that.filter.filter((f: any) => f.name === "IsFilled" && f.value).length > 0;

				if (isFilled) {
					const dialogMessage = data.length === 1 ? "This brochure has already been filled." : "These brochures have already been filled.";
					const actions = [
						{
							label: "OK",
							handler: closeDialog
						}
					];

					openDialog({
						dialogMessage,
						dialogSubText: "Click OK to return to the list.",
						actions
					});
				} else {
					setModalState({
						children: <FillRequest data={data} moduleName={moduleName} setModalState={setModalState} refetchGrid={logDelete} />,
						title: "Fill Request(s)",
						isOpen: true
					});
				}
			},
			handleExport: async function ({ data }: BulkActionArgs) {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const that: any = this;

				let columns = that.columns.filter((column: any) => that.activeColumns.includes(column.name));
				const shortdescColumns = columns.filter((column: any) => ["shortdesc", "shorthtmleditor"].includes(column.format)).map((c: any) => c.name);
				
				columns = columns.map((col: any) => ({
					columnName: col.name,
					type: shortdescColumns.includes(col.format) ? "shortdesc" : col.format
				}));

				//#region Get the full description if it is being requested
				if (shortdescColumns.length) {
					const shortdescColumnsRes = await crmGraphServer.get_grid.fetchGridData(
						null,
						moduleName,
						{ type: parentModule, ID: refId },
						{ 
							filters: that.filter.map((filt: any) => ({
								columnName: filt.name,
								value: cloneDeep(filt.value),
								jsonify: filt.operation !== "contains"
							})),
							columns
						},
						{
							skip: true
						}
					);

					if (shortdescColumnsRes.data && shortdescColumnsRes.data.length)
					{
						data.forEach(record => {
							Object.keys(record).forEach(key => {
								if (shortdescColumns.includes(key)) {
									record[key] = shortdescColumnsRes.data.filter((d: any) => 
										d.RecordID == record.RecordID || d.RecordID == record.RecordID?.props?.children
									)[0][key];
								} 
							})
						})
					}
				}
				//#endregion

				//#region Handle image/file attributes
				if (data.length) {
					data.map(row => {
						if (row.Attributes) {
							row.Attributes.map((attribute: any) => {
								
								try {
									const file = JSON.parse(attribute.value);
									if (file.url) {
										row[`Attribute_${attribute.AttributeID}`] = file.url;
									}
								} catch {
									//do nothing
								}
							})
						}

						if (row.CustomFields) {
							row.CustomFields.map((customField: any) => {
								
								try {
									const file = JSON.parse(customField.value);
									if (file[0].AssetPath) {
										row[`CustomField_${customField.UDFID}`] = file[0].AssetPath;
									}
								} catch {
									//do nothing
								}
							})
						}
					})
				}
				//#endregion

				setModalState({
					children: <BulkExportForm inputData={data} headers={that.activeColumns} labels={that.columns} moduleName={moduleName}/>,
					title: "Export",
					isOpen: true,
					useHandleBackdrop: false
				});
			},
			handleExportAll: async function () {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const that: any = this;
				const shortdescColumns = that.columns.filter((column: any) => ["shortdesc", "shorthtmleditor"].includes(column.format)).map((c: any) => c.name);
				
				const maxExportLength = 5000;

				if (that.count > maxExportLength) {
					setModalState({
						children: ( <div dangerouslySetInnerHTML={{ __html: `Record export is more than ${maxExportLength} records.
							<br />Please modify your search and try exporting again.` }} />),
						title: "Export Failed",
						isOpen: true,
						maxWidth: "sm"
					});

					return;
				}

				let columns = that.columns.filter((column: any) => that.activeColumns.includes(column.name));
				columns = columns.map((col: any) => ({
					columnName: col.name,
					type: shortdescColumns.includes(col.format) ? "shortdesc" : col.format
				}));

				const oldGridCalls = ["fillRequest"];
				let res = {
					data: []
				};

				if (oldGridCalls.includes(moduleName)) {
					res = await crmGraphServer.general.fetchGrid({
						moduleName,
						parent: {
							moduleName: undefined,
							ID: undefined
						},
						pagingOptions: {
							page: 1,
							limit: 5000,
							orderBy: [
								{field: "InquiryID", order: "DESC"}
							]
						},
						form: undefined,
						registry: undefined,
						isInitCall: true,
						view: {
							limit: 5000
						}
					});
				}
				else {
					res = await crmGraphServer.get_grid.fetchGridData(
						null,
						moduleName,
						{ type: parentModule, ID: refId },
						{ 
							filters: that.filter.map((filt: any) => ({
								columnName: filt.name,
								value: cloneDeep(filt.value),
								jsonify: filt.operation !== "contains"
							})),
							columns
						},
						{
							skip: true
						}
					);
				}

				// deal with cutom fields
				const customFields = that.columns.filter((col: any) => col.name.startsWith("CustomField_"));

				res.data.map((record: any) => {
					customFields.forEach((f: any) => {
						const recordRes = record.CustomFields?.filter((c: any) => "CustomField_" + c.UDFID === f.name)[0];
						record[f.name] = recordRes?.value || recordRes?.chips;

						if (["boolean_icon"].includes(f.format)) {
							record[f.name] = record[f.name] || false;
						}
					})

					//#region Handle image/file attributes
					if (record.Attributes) {
						record.Attributes.map((attribute: any) => {
							try {
								const file = JSON.parse(attribute.value);
								if (file.url) {
									record[`Attribute_${attribute.AttributeID}`] = file.url;
								}
							} catch {
								//do nothing
							}
						})
					}

					if (record.CustomFields) {
						record.CustomFields.map((customField: any) => {
							try {
								const file = JSON.parse(customField.value);
								if (file[0].AssetPath) {
									record[`CustomField_${customField.UDFID}`] = file[0].AssetPath;
								}
							} catch {
								//do nothing
							}
						})
					}
					//#endregion

					delete record.CustomFields;
				})

				setModalState({
					children: <BulkExportForm inputData={res.data} headers={that.activeColumns} labels={that.columns} moduleName={moduleName}/>,
					title: "Export",
					isOpen: true,
					useHandleBackdrop: false
				});
			},
			handlePreview: async ({ data }: ActionArgs) => {
				const refKey = `${upperFirst(moduleName)}ID`;
				const res = await crmGraphServer.general.setFormData({
					queryString: `
						query($acct_id: String!, $recId: Int!) {
							crm(acct_id: $acct_id) {
								preview_${moduleName}(recId: $recId ){
									Title
									TemplateBody
								}
							}
						}
					`,
					variables: { recId: data[refKey] }
				});
				const { TemplateBody, Title } = res[`preview_${moduleName}`];

				setModalState({
					children: ( <div dangerouslySetInnerHTML={{ __html: TemplateBody }} />),
					title: Title,
					isOpen: true,
					maxWidth: "md"
				});
			},
			handleJSONEdit: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.EDIT,
					moduleName: `${moduleName}JSON`,
					parentModule,
					recId: data[primaryKeyMap] ||data.recId || data.RecordID || data.id,
					refId
				});			
			},
			handleLayout: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.EDIT,
					moduleName: `${moduleName}Layout`,
					parentModule,
					recId: data[primaryKeyMap] ||data.recId || data.RecordID || data.id,
					refId,
					displaySize: "extralg"
				});	
			},
			handleImagePreview: ({ data }: BulkActionArgs) => {
				setModalState({
					children: <ImageCarouselModal data={data} setModalState={setModalState} />,
					title: "Image Carousel Preview",
					isOpen: true,
					maxWidth: "lg"
				});
			},
			handleViewOnly: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.EDIT,
					moduleName,
					parentModule,
					recId: data[primaryKeyMap] ||data.recId || data.RecordID,
					refId,
					viewOnly: true
				});
				if (hasSummary.includes(moduleName)) {
					setRecentlyViewed({ crmGraphServer, type: moduleName, record: data });
				}	
			},
			handleBulletinModal: async ({ data }: ActionArgs) => {
				const res = await crmGraphServer.general.get({
					type: "bulletin",
					options: {
						orderBy: "IsImportant",
						orderDir: "DESC",
						limit: 5
					},
					filter: {
						recId: data
					},
					fields: `
						Title
						Bulletin
						IsImportant
						Attachment {
							recs {
								name
								url
							}
						}
						recId
						BulletinID
					`
				});
				if (res.success) {
					setModalState({
						children: <BulletinModal data={{ content: res.recs[0].Bulletin, attachment: res.recs[0].Attachment.recs }} />,
						title: res.recs[0].Title,
						isOpen: true,
						maxWidth: "sm",
						useHandleBackdrop: false,
					});
				}
				// track its been read
				await crmGraphServer.general.set({
					type: "track_bulletin", 
					input: {BulletinID: res.recs[0].BulletinID, recId: res.recs[0].recId},
					fields: `
						success
					`
				});
			},
			handleEditSecurity: ({ data }: ActionArgs) => {
				openForm({
					action: FormActions.EDIT,
					moduleName: "contactextranetsecurity",
					parentModule: undefined,
					recId: data[primaryKeyMap] ||data.recId || data.RecordID,
					refId
				});
				if (hasSummary.includes(moduleName)) {
					setRecentlyViewed({ crmGraphServer, type: moduleName, record: data });
				}				
			},
			previewForm: ({ data }: ActionArgs) => {
				const zformType = "formBuilderPreview";
				const tabs = [{ name: "" }];
				const activeTab = tabs[0].name;

				const displayComponent = <FormFrame
					type={zformType}
					action={"add"}
					recId={data.id}
					refId={0}
					reMount={new Date()}
					defaultData={{ recId: data.id, formDisplay: "none" }}
				/>;

				setDrawerOpen("globalTabbedDrawer", {
					isOpen: true,
					type: zformType,
					id: 0,
					action: "add",
					tabs,
					activeTab,
					displayComponent
				});
			},
		};
		return result;
	}, [openForm, moduleName, parentModule, refId]);

	return callbackMap;
};

export default useCallbackMap;
