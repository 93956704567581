const styles = theme => ({
	card: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column"
	},
	cardContent: {
		paddingBottom: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"& h6": {
			textAlign: "center"
		}
	},
	cardSubActions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center"
	},
	cardIcon: {
		padding: 4,
		color: "black"
	},
	cardButton: {
		
	},
	cardAvatar: {
		width: theme.spacing(12),
		height: theme.spacing(12),
		fontSize: theme.spacing(6),
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.primary.main
	},
	tinyFontSize: {
		fontSize: "1rem",
		paddingTop: "0px",
	}
});

export default styles;
