import React, { useCallback } from "react";
import {
	Dialog,
	AppBar,
	Typography,
	DialogContent,
	IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import styles from "./styles";
import {
	GLOBAL_DIALOG
} from "../../common/consts";
import useTransientElements from "../../hooks/useTransientElements";

const useStyles = makeStyles(styles);

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	title?: string;
	maxWidth: any;
	useHandleBackdrop: boolean | null;
}

const Modal: React.FC<Props> = ({ isOpen, handleClose, title, children, maxWidth, useHandleBackdrop = true }) => {
	const classes = useStyles();
	const { setDrawerOpen } = useTransientElements();

	const handleBackdrop = useCallback(() => {
		if (useHandleBackdrop) {
			const actions = [
				{
					label: "Cancel"
				},
				{
					label: "OK",
					color: "primary",
					handler: (): void => {
						setDrawerOpen(GLOBAL_DIALOG, { isOpen: false });
						handleClose();
					}
				}
			];

			setDrawerOpen(GLOBAL_DIALOG, {
				isOpen: true,
				title: "Do you want to exit the form? Your information will not be saved.",
				content: "Click OK to confirm. Click Cancel to go back.",
				actions
			});
		}
	}, [handleClose, useHandleBackdrop]);

	return <Dialog
		open={isOpen}
		fullWidth
		className={classes.modal}
		onClose={handleClose}
		disableEnforceFocus={true}
		maxWidth={maxWidth || "sm"}
		disableBackdropClick={useHandleBackdrop}
		onBackdropClick={handleBackdrop}
	>
		<AppBar className={classes.appBar} position="static">
			<Typography variant="h6" className={classes.appBarTitle}>
				{title}
			</Typography>
			<IconButton onClick={handleClose}>
				<Close />
			</IconButton>
		</AppBar>
		<DialogContent className={classes.dialogContent}>
			{children}
		</DialogContent>
	</Dialog>;
};

export default Modal;
