const styles = theme => ({
	personAvatar: {
		backgroundColor: "#fff",
		color: theme.colors.gandolf,
		"& svg": {
			color: theme.colors.gandolf
		}
	}
});

export default styles;
