import Accounts from "./Accounts";
import Calendar from "./Calendar";
import PendingActivity from "./PendingActivity";
import MyActionItems from "./MyActionItems";
import Tasks from "./Tasks";
import Carousel from "./Carousel";
import Bulletin from "./Bulletin.tsx";

const dashlets = {
	Accounts,
	Calendar,
	PendingActivity,
	MyActionItems,
	Tasks,
	Carousel,
	Bulletin
};

export default { ...dashlets };
