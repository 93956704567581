import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-build-custom";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../Form/Components/styles";
import InputLabel from "@material-ui/core/InputLabel";
import "./style.css"; // because material ui (and other frameworks) use insanely high z-index values, this style sheet allows us to bring CKEditor pop ups to the top
import classNames from "classnames";
import ViewOnly from "../../Fields/ViewOnly";
import { Creators } from "../../../components/Form/actions";

const RichContentEditor = (props) => {
	const {
		CustUploadAdapter,
		enableSubmit,
		value,
		error,
		onChange,
		onBlur = () => {},
		errorMsg,
		field,
		classes,
		setError,
		clearError = () => {},
		viewOnly,
		itemType = "standard"
	} = props;
	const { label, readOnly, characterLimit } = field;
	const [charCount, setCharCount] = useState(0);

	const onChangeCallback = useCallback(
		(e, editor) => {
			onChange(editor.getData());
			if (characterLimit && charCount > characterLimit) {
				setError(
					"setCharacterCount",
					"Character count is over the allowed count for this Listing Type."
				);
			} else {
				clearError("setCharacterCount");
			}
		},
		[characterLimit, charCount, onChange]
	);

	useEffect(() => {
		if (characterLimit && charCount > characterLimit) {
			setError(
				"setCharacterCount",
				"Character count is over the allowed count for this Listing Type."
			);
		} else {
			clearError("setCharacterCount");
		}
	}, [characterLimit]);

	function customUploadAdapterPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return new CustUploadAdapter(loader, enableSubmit);
		};
	}

	if (viewOnly || readOnly) {
		const field = { defaultString: value };
		return <ViewOnly field={field} />;
	}

	const itemMap = {
		standard: [
			"heading",
			"|",
			"bold",
			"italic",
			"underline",
			"link",
			"bulletedList",
			"numberedList",
			"|",
			"outdent",
			"indent",
			"|",
			"fontColor",
			"fontSize",
			"fontFamily",
			"|",
			"imageUpload",
			"insertTable",
			"blockQuote",
			"undo",
			"redo",
			"|",
			"sourceEditing"
		],
		basic: [
			"heading",
			"|",
			"bold",
			"italic",
			"underline",
			"link",
			"bulletedList",
			"numberedList",
			"|",
			"outdent",
			"indent",
			"|",
			"blockQuote",
			"undo",
			"redo"
		]
	};

	const items = itemMap[itemType] || itemMap["standard"];

	return (
		<div>
			<InputLabel
				className={classNames(
					classes.outerLabel,
					error ? classes.errorLabel : ""
				)}
			>
				{label}
			</InputLabel>
			<div className={error ? classes.errorBorder : ""}>
				<CKEditor
					editor={Editor}
					data={value ? value : ""}
					error={error}
					onChange={onChangeCallback}
					onBlur={onBlur}
					config={{
						removePlugins: ["ImageCaption"],
						toolbar: {
							items
						},
						extraPlugins: [customUploadAdapterPlugin],
						image: {
							toolbar: [
								"imageTextAlternative",
								"|",
								"imageStyle:alignLeft",
								"imageStyle:alignCenter",
								"imageStyle:alignRight"
							],
							styles: ["alignCenter", "alignLeft", "alignRight"],
							upload: {
								types: ["png", "jpeg", "jpg"],
							},
						},
						wordCount: {
							onUpdate: (stats) => {
								setCharCount(stats.characters);
							},
						},
						htmlSupport: {
							allow: [
								{
									name: /.*/,
									attributes: false,
									classes: false,
									styles: true
								}
							]
						}
					}}
				/>
			</div>
			<FormHelperText error={error} className={classes.helperText}>
				Character Count: {charCount}{" "}
				{characterLimit ? `/ ${characterLimit}` : null}
			</FormHelperText>
			<FormHelperText error={error} className={classes.helperText}>
				{errorMsg}
			</FormHelperText>
		</div>
	);
};

const mapStateToProps = (
	{ CustUploadAdapter },
	{ CustUploadAdapter: ownCustUploadAdapter }
) => ({
	CustUploadAdapter: ownCustUploadAdapter || CustUploadAdapter,
});

const mapDispatchToProps = {
	enableSubmit: Creators.enableSubmit,
};

export default React.memo(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(RichContentEditor))
);
