import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";
import CollapsibleSectionCellDisplay from "./CollapsibleSectionCellDisplay";
import useGraphServer from "../../../hooks/useGraphServer";
import stripHtml from "../../../common/stripHtml";
import ViewOnly from "@react-ui/components/Fields/ViewOnly";

const ATTRIBUTE_TYPE = "listing_attribute";

const Attribute = ({ classes, recId, module }) => {
	const noDataMessage = "No attributes were found for this record";
	const tabs = [];

	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [formDef, setFormDef] = useState([]);

	const crm = useGraphServer();
	const fetchData = id => () => {
		if (isLoading === true) {
			return;
		}
		// TODO: update to only use RecordID
		// so we're not doing this funny business
		if (/[^\d]/.test(id) === true) {
			return;
		}
		setLoading(true);

		crm.listing.listings_v2(
			`
				success
				count
				formDef {
					recs {
					  	actions {
							callback
					  	}
					}
				}
				recs {
					RecordID
					Attributes {
						AttributeID
						Title
						Value
						ListItem {
							label
							value
						}
						Category {
							Title
							RecordID
							IsActive
						}
						SortOrder
						IsActive
					}
				}
			`,
			{
				ListingID: id
			},
			{
				limit: 1,
				page: 1
			}
		).then(res => {
			setFormDef(res.formDef);

			if (res.success && res.count) {
				const catIndex = {};

				// only valid amenities
				res.recs[0].Attributes = res.recs[0].Attributes.filter(rec => {
					return rec.Category.IsActive && rec.IsActive;
				});

				res.recs[0].Attributes.forEach(rec => {
					const value = rec.ListItem ? rec.ListItem.map(item => item.label).join(", ") : rec.Value;
					const isHtml = value && value.length && value.substring(0, 1) === "<";
					const field = { defaultString: value };
					if (!Object.keys(catIndex).includes(rec.Category.RecordID.toString())) {
						catIndex[rec.Category.RecordID] = {
							catTitle: rec.Category.Title,
							attributes: []
						}
					}

					catIndex[rec.Category.RecordID].attributes.push(
						{
							ID: rec.AttributeID,
							Title: rec.Title,
							Value: isHtml ? <ViewOnly label={""} field={field} /> : value,
							SortOrder: rec.SortOrder
						}
					)
				});

				const sectionData = [];
				Object.keys(catIndex).forEach(catId => {
					sectionData.push({
						CellData: catIndex[catId].attributes.sort((a, b) => {
							if (a.SortOrder < b.SortOrder) {
								return -1;
							} else {
								return 1;
							}
						}),
						RecordID: catId,
						Title: catIndex[catId].catTitle
					});
				})

				sectionData.sort((a, b) => {
					if (a.Title < b.Title) {
						return -1;
					} else {
						return 1;
					}
				});

				setData(sectionData);
			} else {
				setData([]);
			}
		}).finally(() => {
			setLoading(false);
		});
	};

	if (!Array.isArray(formDef) && formDef?.recs[0].actions.some(action => action.callback === "submit")) {
		tabs.push({ name: "Edit Attributes" });
	}

	return (
		<CollapsibleSectionCellDisplay
			recId={recId}
			type={ATTRIBUTE_TYPE}
			tabs={tabs}
			data={data}
			classes={classes}
			fetchData={fetchData(recId)}
			noDataMessage={noDataMessage}
			isLoading={isLoading}
			sortCellData={false}
		/>
	);

};

export default withStyles(styles)(Attribute);
