import React, { useState, useCallback, useMemo } from "react";
import { BulkActionData, GridModalState } from "./typeDefs";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { AsyncSelect, Option, LoadOptionsCallback } from "../Form/CustomFields/SelectField/AsyncSelect";
import FileField from "../../components/Form/CustomFields/FileField";
import TextField from "@react-ui/components/Fields/TextField";
import HTMLField from "@react-ui/components/Fields/HtmlField";
import validateEmail from "@react-ui/common/validateEmail";
import useGraphServer from "../../hooks/useGraphServer";
import { Button } from "@material-ui/core";
import { getDisplayName, getModuleName } from "../../common/moduleData";
import { CustUploadAdapter } from "../../components/Form/FormFrame";
import useTransientElements from "../../hooks/useTransientElements";
import "./style.css";
import { convertFileToFileObject } from "../../common/file";
import { useSelector } from "react-redux";
import Axios from "axios";
import store from "../../store";

const { getState } = store;

interface Props {
	data: BulkActionData;
	moduleName: string;
	setModalState: (state: GridModalState) => void;
}

const useStyles = makeStyles(styles);

const MAX_ASSET_SIZE = 8;
const MAX_ASSET_BULK_SIZE = 10;

const recIdMap: any = {
	contact: "ContactID"
};

const BulkCommForm: React.FC<Props> = ({ data, moduleName, setModalState, userSignature }: Props) => {
	const classes = useStyles();
	const { showSnackbarMessage } = useTransientElements();
	const [DisplayNm, setDisplayNm] = useState<string>("");
	const [FromAddress, setFromAddress] = useState<string>("");
	const [SubjectLine, setSubjectLine] = useState<string>("");
	const [BodyHTML, setBodyHTML] = useState<string>(userSignature);
	const [displayNmValidation, setDisplayNmValidation] = useState<boolean>(false);
	const [FromAddressValidation, setFromAddressValidation] = useState<boolean>(false);
	const [subjectLineValidation, setSubjectLineValidation] = useState<boolean>(false);
	const [bodyHTMLValidation, setBodyHTMLValidation] = useState<boolean>(false);
	const [currentlySending, setCurrentlySending] = useState<boolean>(false);
	const [templateID, setTemplateID] = useState<any>({});
	const [attachments, setAttachments] = useState<File[]>([]);
	const [AdditionalTo, setAdditionalTo] = useState<Option[]>([]);
	const crmGraphServer = useGraphServer();
	const [loading] = useState(false);
	const [templateOptions, setTemplateOptions] = useState<Option[]>([]);
	const [additionalToOptions, setAdditionalToOptions] = useState<Option[]>([]);
	const withoutEdit = useMemo(() => data.filter((row: any) => !row.rowButtons.includes("edit")), [data]);
	const canSubmit = useMemo(() => 
		DisplayNm?.length && SubjectLine?.length && FromAddress?.length && BodyHTML?.length && !currentlySending
	, [DisplayNm, SubjectLine, FromAddress, BodyHTML, currentlySending]);
	const { BASE_URL } = process.env;
	const { acctId } = getState().login;

	const { firstName, lastName, email } = useSelector((state: any): any => {
		return state.login;
	});

	useMemo(() => {
		setDisplayNm(`${firstName} ${lastName}`);
		setFromAddress(email);
	}, [data]);

	const loadTemplateOptions = useCallback<LoadOptionsCallback>(async (search, loadedOptions, { page }) => {
		const res = await crmGraphServer.general.get_old({
			type: "comm_options",
			queryString: `
			comm_options(
				options: {
					limit: 25,
					page: ${page}
				},
				column: "TemplateID",
				search: "${search}",
				isActiveOnly: true
			) {
				recs {
					label
					value
					valueString
					sortOrder
				}
				success
				message
				count
			}
			`
		});

		const hasMore = Math.ceil(res.count / 25) > page;
		const newOptions = res.recs.map((option: any) => ({
			label: option.label,
			value: option.valueString || option.value,
			sortOrder: option.sortOrder
		}));
		setTemplateOptions([...loadedOptions, ...newOptions]);
		return {
			options: newOptions,
			hasMore,
			additional: {
				page: page + 1
			}
		};
	}, [moduleName]);

	const loadAdditionalToOptions = useCallback<LoadOptionsCallback>(async (search, loadedOptions, { page }) => {
		return {
			options: [],
			hasMore: false,
			additional: {
				page: page + 1
			}
		};
	}, []);

	const getTemplateBody = useCallback(async (template) => {
		if (template?.value) {
			const res = await crmGraphServer.general.get({
				type: "template",
				filter: {
					recId: template?.value
				},
				fields: "TemplateBody"
			});

			if (res.success && res.count) {
				setBodyHTML(res.recs[0].TemplateBody + BodyHTML);
			}
		} else {
			setBodyHTML(BodyHTML);
		}
	}, []);

	const handleSendComms = useCallback(async () => {
		setCurrentlySending(true);
		const promises = attachments.map(async file => {
			const fileObject = await convertFileToFileObject(file);
			const { data: upsertResult } = await Axios.post(BASE_URL + "dam-graphql/", {
				fileObject,
				acct_id: acctId,
				private: true
			});
			if (upsertResult.success) {
				return {
					name: file.name,
					damId: upsertResult.doc.id,
					url: upsertResult.doc.asset_url,
					fileObject
				};
			} else {
				const filetype = file.name ? file.name.substring(file.name.indexOf(".") + 1) : undefined;
				const message = upsertResult.message === "Invalid asset type." && filetype
					? `${filetype} files are not able to be sent at this time.`
					: upsertResult.message;

				return {
					success: upsertResult.success,
					message
				}
			}
		});

		const uploadResult = await Promise.all(promises);
		let uploadSuccess = attachments.length && !uploadResult;
		let uploadErrorMessage = "The file upload failed. Please try again.";
		
		if (uploadResult) {
			uploadSuccess = attachments.length === uploadResult.length;

			uploadResult.map((res: any) => {
				uploadSuccess = uploadSuccess && res.damId;
				uploadErrorMessage = res.message || uploadErrorMessage;
			});
		}
		
		let res;
		if (uploadSuccess) {
			const dedupe = (arr: Option[]): (string | number)[] => arr.reduce((accumulator: (string | number)[], currentValue) => {
				if (!accumulator.find(elem => elem === currentValue.value)) {
					accumulator.push(currentValue.value);
				}
				return accumulator;
			}, []);

			res = await crmGraphServer.general.set_old({
				type: "batch_set_contact_comm",
				queryString: `batch_set_contact_comm (
					ids: [${ data.map(value => recIdMap[moduleName] ? value[recIdMap[moduleName]] : value.recId).join(", ")}],
					input: {
						Attachments: $Attachments,
						DisplayNm: "${DisplayNm}",
						SubjectLine: "${SubjectLine}",
						FromAddress: "${FromAddress}",
						BodyHTML: ${JSON.stringify(BodyHTML)},
						${templateID?.value ? `, TemplateID: ${templateID?.value}` : ""},
						AdditionalTo: $AdditionalTo
					}
				) {
					success
					message
					snackbarMessage
					snackbarDisplayType
				}`,
				variables: {
					Attachments: {
						type: "[crm_asset_input]",
						value: uploadResult.map(e => ({ name: e.name, damId: e.damId, url: e.url }))
					},
					AdditionalTo: {
						type: "[String]",
						value: dedupe(AdditionalTo)
					}
				}
			});
		}

		if (uploadSuccess && res?.batch_set_contact_comm?.success) {
			setModalState({
				isOpen: false
			});
		} else {
			const errorMessage = uploadSuccess 
				? (res?.batch_set_contact_comm?.message || "Email has failed to send.") 
				: uploadErrorMessage;
			showSnackbarMessage(`${errorMessage} If the Issue persists, please contact your administrator.`, "error");
		}
		setCurrentlySending(false);
	}, [data, DisplayNm, FromAddress, SubjectLine, templateID, BodyHTML, attachments]);

	const handleSendTestComm = useCallback(async () => {
		setCurrentlySending(true);
		const toBeUploaded = [];
		for (const i in attachments) {
			const object = await convertFileToFileObject(attachments[i]);
			toBeUploaded.push(object);
		}

		const testComm = await crmGraphServer.general.sendEmail({
			input: {
				subject: SubjectLine,
				from: {
					email: FromAddress
				},
				to: [{ email: FromAddress }],
				bodyHTML: BodyHTML,
				templateId: templateID?.value,
				attachments: toBeUploaded
			}
		});
		setCurrentlySending(false);
		if (testComm.success) {
			showSnackbarMessage(`Test Email has been sent to ${FromAddress}`, "success");
		} else {
			showSnackbarMessage(testComm.message || "Test Email has failed to send", "error");
		}
	}, [FromAddress, SubjectLine, templateID, BodyHTML, attachments]);

	const AdditionalToCreateOption = useCallback(async (value: string): Promise<void> => {
		if (validateEmail(value)) {
			setAdditionalToOptions([...additionalToOptions, { label: value, value }]);
			setAdditionalTo([...AdditionalTo, { label: value, value }]);
		}
	}, [validateEmail, setAdditionalTo, AdditionalTo]);

	const handleAttachments = useCallback(async (val) => {
		//Check if any file size is over 5
		let totalFileSize = 0;
		let displayTotalMaxSizeMsg = false;
		const validFiles = val.reduce((accumulator: [any], currentValue: File) => {
			if ("size" in currentValue && currentValue.size >= MAX_ASSET_SIZE * 1000 * 1000) {
				showSnackbarMessage(`File size must be less than ${MAX_ASSET_SIZE}MB.`, "error");
			} else {
				if (totalFileSize + currentValue.size >= MAX_ASSET_BULK_SIZE * 1000 * 1000) {
					displayTotalMaxSizeMsg = true;
				} else {
					accumulator.push(currentValue);
					totalFileSize += currentValue.size;
				}
			}
			return accumulator;
		}, []);

		if (displayTotalMaxSizeMsg) {
			showSnackbarMessage(`Total size of all files must be less than ${MAX_ASSET_BULK_SIZE}MB.`, "error");
		}
		setAttachments(validFiles);
	}, []);
	
	const canAdd = !withoutEdit.length;
	const plural = data.length + AdditionalTo.length > 1;
	const emailCount = data.length + AdditionalTo.length;

	return <div>
		<div className={classes.bulkTagBanner}>
			{canAdd ?
				`${emailCount} email${plural ? "s" : ""} will be sent` :
				`You don't have permission to send an email to the following ${getModuleName(moduleName, true, withoutEdit.length)}:`
			}
		</div>

		{canAdd ? <div className={classes.bulkFormWrapper}>
			<div style={{ margin: 14 }}>
				<TextField
					field={{
						label: "Display Name*",
						placeholder: "Display Name",
						readOnly: false
					}}
					multiline={false}
					value={DisplayNm}
					onChange={(val: string): void => {
						setDisplayNm(val);
						setDisplayNmValidation(true);
					}}
					error={displayNmValidation && !(DisplayNm && DisplayNm.length)}
					errorMsg={displayNmValidation && !(DisplayNm && DisplayNm.length) ? "Field is required" : ""}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<TextField
					field={{
						label: "From Email*",
						placeholder: "From Email",
						readOnly: false
					}}
					multiline={false}
					value={FromAddress}
					onChange={(val: string): void => {
						setFromAddress(val);
						setFromAddressValidation(true);
					}}
					error={FromAddressValidation && !(FromAddress && FromAddress.length && validateEmail(FromAddress))}
					errorMsg={
						FromAddressValidation && !(FromAddress && FromAddress.length) ? "Field is required" :
							FromAddressValidation && !validateEmail(FromAddress) ? "Email format is invalid" :
								""
					}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<TextField
					field={{
						label: "Subject*",
						placeholder: "Subject",
						readOnly: false
					}}
					multiline={false}
					value={SubjectLine}
					onChange={(val: string): void => {
						setSubjectLine(val);
						setSubjectLineValidation(true);
					}}
					error={subjectLineValidation && !(SubjectLine && SubjectLine.length)}
					errorMsg={subjectLineValidation && !(SubjectLine && SubjectLine.length) ? "Field is required" : ""}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<AsyncSelect
					options={templateOptions}
					disabled={loading}
					value={templateID}
					variant="outlined"
					label="Template"
					isMulti={false}
					loadOptions={loadTemplateOptions}
					onChange={(options: Option[]): void => {
						setTemplateID(options);
						getTemplateBody(options);
					}}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<AsyncSelect
					className={classes.additionalTo}
					options={additionalToOptions}
					disabled={loading}
					value={AdditionalTo}
					variant="outlined"
					label="Additional To"
					isMulti={true}
					loadOptions={loadAdditionalToOptions}
					onChange={(options: Option[]): void => {
						setAdditionalTo(options);
					}}
					onCreateOption={AdditionalToCreateOption}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<HTMLField
					value={BodyHTML}
					onChange={(val: string): void => {
						setBodyHTML(val);
						setBodyHTMLValidation(true);
					}}
					error={bodyHTMLValidation && !(BodyHTML && BodyHTML.length)}
					errorMsg={bodyHTMLValidation && !(BodyHTML && BodyHTML.length) ? "Field is required" : ""}
					field={{ readOnly: false }}
					classes={{}}
					CustUploadAdapter={CustUploadAdapter}
				/>
			</div>
			<div style={{ margin: 14 }}>
				<FileField
					value={attachments}
					onChange={(val: File[]): void => {
						handleAttachments(val);
					}}
					errorMsg={""}
					limit={0}
					label="Attachments"
				/>
			</div>
		</div> : <div>
			{
				withoutEdit.map((row: any, rowIndex: number) => <p key={rowIndex}>
					{getDisplayName(moduleName, row)}
				</p>
				)
			}
		</div>}
		<div style={{ float: "right" }}>
			<Button onClick={(): void => setModalState({ isOpen: false })} variant="contained" color="secondary" style={{ marginRight: "0.5rem" }}>
				Cancel
			</Button>
			<Button onClick={handleSendTestComm} disabled={!canSubmit} variant="contained" color="primary" style={{ marginRight: "0.5rem" }}>
				Send Test Email
			</Button>
			{
				canAdd &&
				<Button onClick={handleSendComms} disabled={!canSubmit} variant="contained" color="primary">
					Send Email{plural ? "s" : ""}
				</Button>
			}
		</div>
	</div>;
};

export default BulkCommForm;
