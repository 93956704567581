const styles = theme => ({
	recentlyViewedDrawer: {
		"& header": {
			boxShadow: "none"
		},
		"& > div": {
			marginTop: theme.constants.header.height,
			height: `calc(100% - ${theme.constants.header.height}px)`,
			width: "100%"
		},
		"& > div:nth-child(2)": {
			maxWidth: 530
		},
		"& ul": {
			padding: 0
		},
		"& .drawerData": {
			margin: 0
		}
	},
	tab: {
		color: "#757575"
	},
	hide: {
		display: "none"
	},
	title: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		fontSize: ".8rem",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(3),
		paddingLeft: theme.spacing(3)
	},
	rightToolbar: {
		marginLeft: "auto",
		marginRight: -12
	},
	noData: {
		margin: 10,
		textAlign: "center"
	},
	menuSection: {
		backgroundColor: "#0088c6",
		borderBottom: "solid 2px #fff",
		"&:hover": {
			backgroundColor: "#0088c6 !important",
			borderBottom: "solid 2px #fff"
		},
		"&:focus": {
			backgroundColor: "#0088c6 !important",
			borderBottom: "solid 2px #fff"
		},
		"& span, & svg": {
			color: "#fff"
		}
	},
	removeBookmark: {
		backgroundColor: theme.colors.gandolf,
		color: "#fff",
		borderRadius: "50%",
		fontSize: 15,
		padding: 4,
		marginRight: 10
	},
	recentlyViewedAvatar: {
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: theme.colors.blueHover
		}
	}
});

export default styles;
