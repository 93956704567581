const styles = theme => ({
	messageContainer: {
		marginTop: "5px"
	},
	messageContent: {
		"& p": {
			display: "inline-block",
			verticalAlign: "top",
			marginTop: "3px",
			maxWidth: "460px"
		},
		"& a": {
			color: "#fff",
			fontWeight: "bold"
		}
	},
	messageBody: {
		color: "#fff",
		marginLeft: "1em"
	},
	messageType: {
		borderRadius: "4px",
		margin: "1em"
	},
	info: {
		backgroundColor: "#1976d2"
	},
	error: {
		backgroundColor: "#d32f2f"
	},
	success: {
		backgroundColor: "#43a047"
	},
	warning: {
		backgroundColor: "#ffa000"
	}
});

export default styles;
