import React, { useEffect } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Creators } from "../../../actions/dashboardActions";
import styles from "./styles";
import ImageCarousel from "../../ImageCarousel";

const Carousel = ({ fetchCarousel, data = [] }) => {
	useEffect(() => {
		fetchCarousel();
	}, []);

	return (
		<React.Fragment>
			{data.length ? (
				<ImageCarousel items={data}/>
			) : null}			
		</React.Fragment>
	);
};

const mapStateToProps = state => ({
	data: state?.dashboard?.data?.carousel
});

const mapDispatchToProps = {
	fetchCarousel: Creators.fetchCarousel
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Carousel));
