import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { Creators } from "../../actions/summaryActions";

// TODO: change this to a more appropriate component
const NotFound = ({ message = "Requested Resource Not Found.", clearError = false }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			if (clearError) {
				dispatch(Creators.clearSummaryErr());
			}
		};
	}, [clearError]);
	return <React.Fragment>
		<Typography variant="h6" style={{
			textAlign: "left",
			padding: "1rem",
		}}>{ message }</Typography>
	</React.Fragment>;
};

export default NotFound;
