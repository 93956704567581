const styles = (): Record<string, any> => ({
	root: {
		position: "relative",
		overflow: "hidden",
		maxWidth: "1200px",
		margin: "auto"
	},
	indicators: {
		width: "100%",
		marginTop: "10px",
		textAlign: "center"
	},
	indicator: {
		fontSize: "15px",
		cursor: "pointer",
		transition: "200ms",
		color: "#afafaf",
		"&:hover": {
			color: "#1f1f1f"
		},
		"&:active": {
			color: "#1f1f1f"
		}
	},
	active: {
		color: "#494949"
	},
	buttonWrapper: {
		position: "absolute",
		height: "100px",
		backgroundColor: "transparent",
		top: "calc(50% - 70px)",
		"&:hover": {
			"& $button": {
				backgroundColor: "black",
				filter: "brightness(120%)",
				opacity: 0.4
			}
		}
	},
	fullHeightHoverWrapper: {
		height: "calc(100% - 20px - 10px) !important",
		top: "0 !important"
	},
	button: {
		margin: "0 10px",
		position: "relative",
		backgroundColor: "#494949",
		top: "30px",
		color: "white",
		fontSize: "30px",
		transition: "200ms",
		cursor: "pointer",
		"&:hover": {
			opacity: "0.6 !important"
		}
	},
	fullHeightHoverButton: {
		top: "calc(50% - 20px) !important"
	},
	buttonVisible: {
		opacity: "0.6"
	},
	buttonHidden: {
		opacity: "0",
	},
	next: {
		right: 0
	},
	prev: {
		left: 0
	},
	carouselContent: {
		position: "absolute",
		right: 0,
		top: 0,
		width: "30% !important",
		height: "100% !important",
		backgroundColor: "rgba(1,1,1,.3)",
		color: "#fff",
		border: "none",
		fontSize: "14px",
		lineHeight: "32px",
		overflow: "hidden",
		padding: "1rem",
		boxSizing: "border-box"
	},
	carouselTitle: {
		marginTop: "0.5rem",
		marginBottom: "0.5rem",
		fontSize: "1.8rem",
		fontWeight: 400,
		whiteSpace: "normal",
		color: "#fff",
		fontFamily: "Museo Slab W01",
		textShadow: "1px 1px #555",
		padding: "0px"
	},
	carouselCaption: {
		fontSize: "1.0rem",
		lineHeight: "1.4rem",
		whiteSpace: "normal",
		marginBottom: ".5rem",
		textShadow: "1px 1px #555",
		padding: "0px"
	},
	carouselLink: {
		color: "#fff",
		padding: ".5em",
		borderRadius: "5px",
		backgroundColor: "rgba(0, 0, 0, 0.6)"
	}
});

export default styles;
