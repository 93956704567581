import { AppBar, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Close } from "@material-ui/icons";
import CircularProgressCentered from "../CircularProgressCentered";
import { Creators } from "../../actions/mycalendarActions";
import { Creators as TransCreators } from "../../actions/transientElementActions";
import { connect } from "react-redux";
import styles from "./styles";
import { wrapFormatValue } from "../../common/format";
import { CALENDAR_MODAL_NAME, EDIT, GLOBAL_TRANSIENT_ELEMENTS } from "../../common/consts";
import FormFrame from "../Form/FormFrame";
const { DRAWER } = GLOBAL_TRANSIENT_ELEMENTS;

const FlexContainer = ({ direction = "row", count = 0, wrap = false, children }) => (
	<div style={{
		display: "flex",
		flexDirection: direction,
		flexWrap: wrap ? "wrap" : "nowrap",
		flexCount: count,
		width: "100%",
	}}>
		{children}
	</div>
);

const RenderRow = ({ classes, columns, fields, name, compField, compIndex, data, fieldObj }) => (
	<FlexContainer wrap>
		{fields && fields.map(field => {
			return <Typography key={field}> {field.name} </Typography>;
		})}
		{columns && columns.map((column, i) => {
			const columnName = name + "-column-" + i;
			return <RenderColumn
				key={columnName}
				name={columnName}
				classes={classes}
				compField={compField}
				compIndex={compIndex}
				{...column}
				data={data}
				fieldObj={fieldObj}
			/>;
		})}
	</FlexContainer>
);

const RenderColumn = ({ classes, fields, rows, width, name, compField, compIndex, data, fieldObj }) => (
	<div style={{
		width: `${width || 100}%`,
		flexDirection: "column",
		display: "flex",
		boxSizing: "border-box",
	}}>
		{fields && fields.map(field => {
			if (!field) {
				return undefined;
			} else if (fieldObj[field].type === "ChipLink" && !data[field].length) {
				return undefined;
			}

			return (
				<div style={{ margin: 14 }} data-cy={fieldObj[field].name} key={field}>
					<Typography variant="caption" className={classes.label}>
						{fieldObj[field].label ? fieldObj[field].label + ":" : ""}
					</Typography>
					<Typography variant="body1" className={classes.text}>
						{
							Array.isArray(data[field]) ? (
								data[field].map((val, i) => {
									if (fieldObj[field].type === "ChipLink") {
										val.link = `${fieldObj[field].fileAppendText.labelLink.href}${val.value}`;
									}
									return (
										<React.Fragment key={i}>
											{ wrapFormatValue(val, fieldObj[field].type, fieldObj[field].wrapper, { module: fieldObj[field].module, data: data[field] }) }
										</React.Fragment>
									);
								})
							) : wrapFormatValue(data[field], fieldObj[field].type, fieldObj[field].wrapper, { module: fieldObj[field].module, data: data[field] })
						}
					</Typography>
				</div>
			);
		})}
		{rows && rows.map((row, i) => {
			const rowName = `${name}-row-${i}`;
			return <RenderRow compField={compField} compIndex={compIndex} classes={classes} key={rowName} {...row} name={rowName} data={data} fieldObj={fieldObj} />;
		})}
	</div>
);

class MyCalendarModal extends Component {

	componentDidMount() {
		const { isOpen, calendarEvent } = this.props;
		if (isOpen) {
			const { type, recId, refType } = calendarEvent;
			this.props.modalFetch(type, recId, refType);
		}
	}

	componentDidUpdate(prevProps) {
		const { isOpen, calendarEvent } = this.props;
		if (calendarEvent && isOpen && isOpen !== prevProps.isOpen) {
			const { type, recId, refType } = calendarEvent;
			
			if (isOpen) {
				this.props.modalFetch(type, recId, refType);
			}
		}
	}

	handleClose = () => {
		this.props.handleClose();
	};

	renderContent = () => {
		const { classes, layout, data, fields } = this.props;
		if (this.props.modalFetching === undefined || !layout.sections) {
			return null;
		}
		return (
			<React.Fragment>
				{
					layout.sections.map((section => {
						const { name, label, rows, columns } = section;
						return (
							<ExpansionPanel key={`modal-${name}`} defaultExpanded className={classes.section}>
								<ExpansionPanelSummary className={classNames(classes.sectionHeader, "CyModalSectionHeader")} expandIcon={<ExpandMoreIcon />}>
									{label}
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classNames(classes.gridContainer)}>
									{
										columns && columns.map((column, i) => {
											const columnName = `${name}-column-${i}`;
											return <RenderColumn classes={classes} key={columnName} {...column} name={columnName} data={data} fieldObj={fields} />;
										})
									}
									{
										rows && rows.map((row, i) => {
											const rowName = `${name}-row-${i}`;
											return <RenderRow classes={classes} key={rowName} {...row} name={rowName} data={data} fieldObj={fields} />;
										})
									}
								</ExpansionPanelDetails>
							</ExpansionPanel>
						);
					}))
				}
			</React.Fragment>
		);
	};

	displayComponent = (action, type, recId) => {
		const { setDrawerOpen, eventsFetch } = this.props;
		return (
			<FormFrame
				type={type}
				action={action}
				recId={recId}
				refId={0}
				afterSubmit={() => {
					this.handleClose();
					setDrawerOpen(DRAWER, { isOpen: false });
					eventsFetch();
				}}
			/>
		);
	};

	getCallback = (callback, type, recId) => {
		const { setDrawerOpen } = this.props;
		if (callback === "edit") {
			return () => setDrawerOpen(DRAWER, {
				type: type,
				isOpen: true,
				id: recId,
				action: EDIT,
				displayComponent: this.displayComponent(EDIT, type, recId),
				tabs: [{ name: "task" }],
				activeTab: "task"
			});
		}
	};

	render() {
		const {
			renderContent,
			handleClose,
			getCallback,
			props: { classes, modalFetching, isOpen, calendarEvent, actions }
		} = this;

		if (calendarEvent) {
			const { type, recId } = calendarEvent;

			return (
				<Dialog open={isOpen} fullWidth className={classes.modal} onClose={handleClose}>
					<AppBar className={classes.appBar} position="static">
						<Typography variant="h6" className={classes.appBarTitle}>
							{`${type}`}
						</Typography>
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</AppBar>
					<DialogContent className={classes.dialogContent}>{modalFetching ? <CircularProgressCentered size={75} color="primary" /> : renderContent()}</DialogContent>
					<DialogActions>
						{
							(actions || []).map(action => (
								<Button
									className={classes.detailActionButton}
									variant={"contained"}
									size="large"
									type={"button"}
									key={`modal_button_${action.callback}`}
									onClick={getCallback(action.callback, type, recId)}>
									{action.label}
								</Button>
							))
						}
					</DialogActions>
				</Dialog>
			);
		}
		return null;
	}
}

const mapStateToProps = state => ({
	...state.transientElements.drawerOpen[CALENDAR_MODAL_NAME],
	data: state.mycalendar.data,
	layout: state.mycalendar.layout,
	fields: state.mycalendar.fields,
	actions: state.mycalendar.actions,
	modalFetching: state.mycalendar.modalFetching
});

const mapDispatchToProps = {
	modalFetch: Creators.modalFetch,
	eventsFetch: Creators.eventsFetch,
	setDrawerOpen: TransCreators.setDrawerOpen
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(MyCalendarModal));
