
import React from "react";
import { ExpansionPanelDetails, Typography } from "@material-ui/core";
import { getFieldValue } from "../../../common/getFieldValue";
import { wrapFormatValue } from "../../../common/format";
import { useParams } from "react-router-dom";
import useTransientElements from "../../../hooks/useTransientElements";

const Details = ({ section, classes, data }) => {
	const { recId } = useParams();
	const { setDrawerOpen } = useTransientElements();
	return <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
		{ section.fields.map((field, i) => {
			field.value = getFieldValue(field, data);
			field.id = data[field.wrapperId];
			return (
				<div key={i} className="detail-summary-fieldset">
					<Typography variant="caption" className={classes.summaryLabel}>
						{field.label}
					</Typography>
					<React.Fragment>
						{Array.isArray(field.value) ? (
							<Typography variant="body1" className={classes.summaryText}>
								{field.value.map((val, i) => (
									<React.Fragment key={i}>
										{wrapFormatValue(val, field.type, field.wrapper, {
											module: field.module,
											id: field.id })}
									</React.Fragment>
								))}
							</Typography>
						) : (
							<Typography variant="body1" className={classes.summaryText}>
								{wrapFormatValue(
									field.groupData ? data : field.value,
									field.type,
									field.wrapper,
									{
										module: field.module || module,
										data: field.id || data[field.name],
										id: field.type === "email" ? data.recId : recId,
										setDrawerOpen,
										classes,
										commFormEmail: field.type === "email" ? field.value : ""
									}
								)}
							</Typography>
						)}
					</React.Fragment>
				</div>
			);
		}) }
	</ExpansionPanelDetails>;
};

export default Details;
