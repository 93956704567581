import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Creators } from "../../actions/mycalendarActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./styles";
import FullCalendar from "../FullCalendar";
import CircularProgressCentered from "../CircularProgressCentered";
import MyCalendarModal from "../MyCalendarModal";
import { CALENDAR_MODAL_NAME } from "../../common/consts";
import { compareEvent } from "../../common/event";
import { useEffect } from "react";
import { useCallback } from "react";
import useTransientElements from "../../hooks/useTransientElements";
import useLocationSearch from "../../hooks/useLocationSearch";


const MyCalendar = (props) => {
	const { eventsFetch } = props;
	const { setDrawerOpen } = useTransientElements();
	const { classes, events, isFetching, locale, setDateInView, dateInView, defaultView, setView, title
	} = props;

	useEffect(() => {
		eventsFetch();
	}, []);
	const [taskModal, setTaskModal] = useState({
		isOpen: false
	});

	useEffect(() => {
		setDrawerOpen(CALENDAR_MODAL_NAME, taskModal);
	}, [taskModal]);

	useLocationSearch({
		onChange: (curValue) => {
			if (curValue) {
				if (curValue.view && ["month", "listWeek"].includes(curValue.view)) {
					setView(curValue.view);
				}
				if (curValue.date) {
					setDateInView(new Date(curValue.date));
				}
				if (curValue.task) {
					setTaskModal({
						isOpen: true,
						calendarEvent: {
							type: "task",
							recId: curValue.task
						}
					});
				}
			}
		},
		clearSearch: true
	});

	const eventClick = useCallback(event => {
		setTaskModal({
			isOpen: true,
			calendarEvent: event.event.extendedProps
		});
	});

	
	const defaultSettings = {
		defaultView,
		eventClick: eventClick,
		eventLimit: true,
		displayEventTime: false,
		defaultDate: dateInView,
		header: {
			left: "title",
			center: "month,listWeek",
			right: "today prev,next"
		},
		eventRender: info => {
			info.el.style.cursor = "pointer";
			// so that the list view highlights today's events
			if (moment(info.event.start).format("L") === moment().format("L")) {
				info.el.style.background = "#fcf8e3";
				info.el.style.color = "	#33a4b9";
			}
		},
		eventOrder: compareEvent,
		height: () => window.innerHeight - 96,
		locale: locale
	};

	const settings = { ...defaultSettings, ...props.settings };
	return (
		<React.Fragment>
			{isFetching ? (
				<CircularProgressCentered size={75} color="primary" />
			) : (
				<React.Fragment>
					{ title ?
						<Typography variant="h2">
							{title}
						</Typography>
						: null
					}
					<div className={classes.calendarWrapper}>
						<FullCalendar events={events} {...settings} setDateInView={setDateInView} setView={setView} />
					</div>
					<MyCalendarModal handleClose={() => {
						setTaskModal({
							isOpen: false
						});
					}} />
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

MyCalendar.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	events: state.mycalendar.events,
	isFetching: state.mycalendar.isFetching,
	dateInView: state.mycalendar.dateInView,
	defaultView: state.header.CalendarDefaultView || "month",
	locale: state.header.Locale || "en"
});

const mapDispatchToProps = {
	eventsFetch: Creators.eventsFetch,
	setView: Creators.setView,
	setDateInView: Creators.setDateInView
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(MyCalendar));
