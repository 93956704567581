import React, { useState, useCallback, useMemo } from "react";
import { BulkActionData, GridModalState } from "./typeDefs";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { AsyncSelect, Option, LoadOptionsCallback, CreateOptionCallback } from "../Form/CustomFields/SelectField/AsyncSelect";
import useGraphServer from "../../hooks/useGraphServer";
import { Button } from "@material-ui/core";
import useTransientElements from "../../hooks/useTransientElements";
import { getDisplayName, getModuleName } from "../../common/moduleData";
import { wrapValue } from "../../common/format";

interface Props {
	data: BulkActionData;
	moduleName: string;
	setModalState: (state: GridModalState) => void;
	asLink?: boolean;
}

const useStyles = makeStyles(styles);

const recIdMap: any = {
	offer: "SpecialOfferID",
	contact: "ContactID",
	eventCalendar: "EventCalendarID",
	inquiry: "InquiryID",
	account: "AccountID",
	listing: "ListingID"
};

const BulkTagForm: React.FC<Props> = ({ data, moduleName, setModalState, asLink = false }) => {
	const primaryKeyMap = recIdMap[moduleName];
	const classes = useStyles();
	const [tags, setTags] = useState<any[]>([]);
	const crmGraphServer = useGraphServer();
	const { showSnackbarMessage } = useTransientElements();
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<Option[]>([]);
	const [submitInProgress, setSubmitInProgress] = useState(false);
	const withoutEdit = useMemo(() => {
		return data.filter(row => {
			return !row.rowButtons.includes("edit");
		});
	}, [data]);

	const pending = useMemo(() => {
		return data.filter(row => {
			return row.PendingID;
		});
	}, [data]);

	const canAdd = withoutEdit.length === 0 && pending.length === 0;

	const loadOptions = useCallback<LoadOptionsCallback>(async (search, loadedOptions, { page }) => {
		const type = `${moduleName}_options`;
		const res = await crmGraphServer.general.get_old({
			type,
			queryString: `
			${type}(
				options: {
					limit: 25,
					page: ${page}
				},
				column: "Tag",
				search: "${search}",
				isActiveOnly: true
			) {
				recs {
					label
					value
					valueString
					sortOrder
				}
				success
				message
				count
			}
			`
		});

		const hasMore = Math.ceil(res.count / 25) > page;
		const newOptions = res.recs.map((option: any) => ({
			label: option.label,
			value: option.valueString || option.value,
			sortOrder: option.sortOrder
		}));
		setOptions([...loadedOptions, ...newOptions]);
		return {
			options: newOptions,
			hasMore,
			additional: {
				page: page + 1
			}
		};
	}, [moduleName]);

	const onCreateOption = useCallback<CreateOptionCallback>(async (label) => {
		setLoading(true);
		setSubmitInProgress(true);
		const resolver = `set_${moduleName}_tag`;
		const escapedValue = label.trim().replace(/["\\]/g, function (match) {
			return "\\".concat(match);
		});
		setTags([...tags, {
			label,
			value: Date.now()
		}]);
		const res = await crmGraphServer.general.set_old({
			type: resolver,
			queryString: `
				${resolver}(tag: "${escapedValue}") {
					success
					message
					snackbarMessage
					snackbarDisplayType
					recId
				}
			`
		});
		const data = res[resolver];
		if (data?.success) {
			const option = { label, value: data.recId, sortOrder: 0 };
			setTags([...tags, option]);
			setOptions([...options, option]);
		} else {
			showSnackbarMessage("Failed to create tag: " + label, "error");
		}
		setLoading(false);
		setSubmitInProgress(false);
	}, [tags]);

	const handleAddTags = useCallback(async () => {
		setSubmitInProgress(true);
		const res = await crmGraphServer.general.set_old({
			type: "batch_set_tag",
			queryString: `
				batch_set_tag (
					type: ${moduleName},
					ids: [${ data.map(value => primaryKeyMap ? value[primaryKeyMap] : value.recId).join(", ") }],
					tagIds: [${ tags.map(tag => tag.value ).join(", " )}]
				) {
					success
					message
					snackbarMessage
					snackbarDisplayType
				}
			`
		});
		setSubmitInProgress(false);
		if (res.batch_set_tag.success) {
			setModalState({
				isOpen: false
			});
		}
	}, [tags, data]);

	const plural = tags?.length > 1;

	return <div>
		<div className={classes.bulkTagBanner}>
			{canAdd ?
				`The tag${plural ? "s" : ""} you selected will be added to ${data.length} ${getModuleName(moduleName, true, data.length)}` :
				withoutEdit.length ?
					`You don't have permission to EDIT the following ${getModuleName(moduleName, true, withoutEdit.length)}:` :
					`The following ${getModuleName(moduleName, true, pending.length)} have pending changes, please resolve ${pending.length > 1 ? "them" : "it"} first:`}
		</div>
		
		{canAdd ? <div className={classes.bulkFormWrapper}>
			<AsyncSelect
				options={options}
				disabled={loading}
				value={tags}
				variant="outlined"
				label="Tag Name"
				isMulti={true}
				loadOptions={loadOptions}
				onCreateOption={onCreateOption}
				onChange={((options: Option[]): void => {
					setTags(options);
				})} />
		</div> : <div>
			{withoutEdit.length ?
				withoutEdit.map(row => <p key={primaryKeyMap ? row[primaryKeyMap] : row.recId}>
					{getDisplayName(moduleName, row)}
				</p>) : 
				pending.map(row => <p key={primaryKeyMap ? row[primaryKeyMap] : row.recId}>
					{asLink ? wrapValue(getDisplayName(moduleName, row), "moduleLink", { module: moduleName, data: row.RecordID }) : getDisplayName(moduleName, row)}
				</p>)
			}
		</div>}
		<div style={{
			float: "right"
		}}>
			<Button onClick={(): void => setModalState({ isOpen: false })} variant="contained" color="secondary" style={{
				marginRight: "0.5rem"
			}}>Cancel</Button>
			{ canAdd && <Button onClick={handleAddTags} disabled={tags?.length === 0 || submitInProgress} variant="contained" color="primary">Add Tag{plural ? "s" : ""}</Button>}
		</div>
	</div>;
};

export default BulkTagForm;
