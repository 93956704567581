import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem, Avatar } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Creators } from "../../../actions/loginActions";
import { Creators as HeaderCreators } from "../../../actions/headerActions";
import { Creators as TransientCreators } from "../../../actions/transientElementActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FormFrame from "../../Form/FormFrame";
import { GLOBAL_TABBED_DRAWER, EDIT } from "../../../common/consts";
import styles from "./styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const ANCHOR = "userSettingsAnchorElement";

const drawerTabsMap = [{ type: "preference", name: "Settings & Preferences" }];

class UserSettings extends Component {
	componentDidMount() {
		this.props.fetchProfileSetting();
		this.props.setAnchorElement(ANCHOR, null);
	}

	handleMenu = event => {
		const { setAnchorElement } = this.props;
		setAnchorElement(ANCHOR, event.currentTarget);
	};

	openDrawer = type => () => {
		const { setDrawerOpen, setAnchorElement, fetchGrid, currentGrid, fetchCalendar } = this.props;
		const viewType = currentGrid && currentGrid.indexOf(".") >= 0 ? currentGrid.split(".")[1] : currentGrid;
		const shouldFetchGrid = type === viewType;
		const displayComponent = action => (
			<FormFrame
				type={type}
				action={action}
				recId={0}
				afterSubmit={() => {
					setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
					if (shouldFetchGrid) {
						fetchGrid(false);
					} else if (type.indexOf("task") >= 0) {
						fetchCalendar();
					}
				}}
			/>
		);

		const drawerTab = drawerTabsMap.find(drawerTab => drawerTab.type === type);
		setDrawerOpen(GLOBAL_TABBED_DRAWER, {
			isOpen: true,
			type,
			id: 0,
			action: EDIT,
			tabs: new Array(drawerTab),
			activeTab: drawerTab.name,
			displayComponent: displayComponent(EDIT)
		});
		setAnchorElement(ANCHOR, null);
	};

	handleClose = item => {
		if (item.href) {
			window.open(item.href);
		}

		const { setAnchorElement } = this.props;
		setAnchorElement(ANCHOR, null);
	};

	renderAvatar = () => {
		const {
			classes,
			profileSettings: { FirstName }
		} = this.props;

		if (FirstName) {
			return <Avatar className={classes.personAvatar}>{FirstName.substring(0, 1)}</Avatar>;
		} else {
			return (
				<Avatar className={classes.personAvatar}>
					<AccountCircle />
				</Avatar>
			);
		}
	};

	render() {
		const {
			handleMenu,
			handleClose,
			renderAvatar,
			openDrawer,
			props: { classes, logout, anchorElement, loginErr, productName, isSVUser }
		} = this;
		const open = !!anchorElement;

		return (
			<React.Fragment>
				<Tooltip title={"User Options"}>
					<IconButton onClick={handleMenu} className={classes.appBarAvatars} color="inherit" aria-label="Open Avatar Menu">
						{renderAvatar()}
					</IconButton>
				</Tooltip>
				<Menu
					id="menu-appbar"
					getContentAnchorEl={null}
					anchorEl={anchorElement}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={open}
					onClose={handleClose}
					disableAutoFocusItem
				>
					{!loginErr && !isSVUser && productName === "dms" &&
						<MenuItem component={Link} to={"/myProfile"} onClick={handleClose}>
							My Profile
						</MenuItem>
					}
					{!loginErr && productName === "dms" &&
						<MenuItem component={Link} to={"/mycalendar"} onClick={handleClose}>
							My Calendar
						</MenuItem>
					}
					{!loginErr && productName === "dms" && drawerTabsMap.map(item => {
						return (
							<MenuItem key={`${item.type}AddNewMenuItem`} onClick={openDrawer(item.type)} data-cy={item.type}>
								{item.name}
							</MenuItem>
						);
					})}
					<MenuItem component={Link} to={"/Logout"} onClick={logout}>
						Log Out
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}

UserSettings.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	anchorElement: state.transientElements.anchorElement[ANCHOR],
	profileSettings: state.header.profileSettings || {},
	productName: state.login.productName,
	isSVUser: state.login.isSVUser
});

const mapDispatchToProps = {
	fetchProfileSetting: HeaderCreators.fetchProfileSetting,
	setDrawerOpen: TransientCreators.setDrawerOpen,
	setAnchorElement: TransientCreators.setAnchorElement,
	logout: Creators.logout
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(UserSettings)
);
