import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Grid, Card, CardContent, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography,
	List, ListItem, ListItemIcon, ListItemText, Divider, Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch, useParams } from "react-router-dom";
import CircularProgressCentered from "../CircularProgressCentered";
import { Creators } from "../../actions/summaryActions";
import SummaryTabs from "../SummaryTabs";
import styles from "./styles";
import NotFound from "../NotFound";
import Amenity from "./Display/Amenity";
import Attribute from "./Display/Attribute";
import BenefitSummary from "./Display/BenefitSummary";
import MeetingFacility from "./Display/MeetingFacility";
import {
	SUMMARY,
	SUMMARY_PARAM,
	AMENITY,
	BENEFITSUMMARY,
	MEETINGFACILITY,
	MYPROFILE,
	ATTRIBUTE
} from "../../common/consts";
import MosaicGrid from "../Grid";
import { useCallback } from "react";
import useShouldFetch from "../../hooks/useShouldFetch";
import {
	LocationOn,
	Phone,
	Email,
	ExpandMore,
	Edit,
	Delete,
	FileCopyOutlined,
	StarBorder,
	Add,
	AddCircleOutline
} from "@material-ui/icons";
import { formatPhone, formatAddress, formatDate } from "@react-ui/common/format";
import Preview from "../Icons/Preview";
import classNames from "classnames";

const SubGrid = () => {
	const { recId, module, tab } = useParams();
	const numRecId = useSelector(state => state.summary.data.recId);

	const displays = {
		[MEETINGFACILITY]: <MeetingFacility key={`${MEETINGFACILITY}`} strId={recId} />,
		[AMENITY]: <Amenity key={`${AMENITY}`} recId={numRecId || recId} module={module} />,
		[BENEFITSUMMARY]: <BenefitSummary key={`${BENEFITSUMMARY}`} recId={numRecId || recId} />,
		[ATTRIBUTE]: <Attribute key={`${ATTRIBUTE}`} recId={numRecId || recId} module={module} />,
	};

	if ([MEETINGFACILITY, AMENITY, BENEFITSUMMARY, ATTRIBUTE].includes(tab)) {
		return displays[tab];
	} else {
		return <MosaicGrid moduleName={tab} parentModule={module} parentId={recId} key={`grid-${module}-${tab}-${recId}`} />;
	}
};

const MyProfileGrid = () => {
	const { tab } = useParams();
	return <MosaicGrid moduleName={tab} parentModule={MYPROFILE} parentId={0} key={`grid-${MYPROFILE}-${tab}-${0}`} />;
};

const AlternateSummary = ({ classes, tabsFetching, shouldRedirect, summaryErr, adminModule, summaryTabSettings, summaryFetch, summaryFetching, data = {}, }) => {
	let { module, recId } = useParams();
	module = adminModule || module;

	shouldRedirect = !!(shouldRedirect || (module !== MYPROFILE && !module) || (module !== MYPROFILE && !recId));

	const notFoundProps = { clearError: true };
	if (summaryErr) {
		notFoundProps.message = summaryErr;
	}

	const fetchSummary = useCallback(() => {
		if (!summaryErr) {
			summaryFetch(module, recId);
		}
	}, [module, recId]);

	useShouldFetch(module, {
		handleFetch: fetchSummary
	});

	return shouldRedirect || !summaryTabSettings[module] ?

		<NotFound {...notFoundProps} /> :

		<React.Fragment>
			<Grid
				container
				spacing={2}
				alignItems="stretch"
				className={classes.summaryPageWrapper}
			>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							{summaryFetching ? (
								<CircularProgressCentered size={50} color="primary" />
							) : (
								<React.Fragment>
									<Typography className={classes.summaryModuleTitle} variant="h2">{data.CompanyName}</Typography>
									<List className={classNames(classes.summaryModuleDetails, classes.summaryModuleActions)} orientation="horizontal">
										<ListItem>
											<ListItemIcon>
												<StarBorder fontSize="small" color="primary" />
											</ListItemIcon>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<Edit fontSize="small" color="primary" />
											</ListItemIcon>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<FileCopyOutlined fontSize="small" color="primary" />
											</ListItemIcon>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<Delete fontSize="small" color="primary" />
											</ListItemIcon>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<Preview fontSize="small" color="primary" />
											</ListItemIcon>
										</ListItem>
									</List>
									<List className={classes.summaryModuleDetails} orientation="horizontal">
										<ListItem>
											<ListItemText primary={"Accommodation"} />
										</ListItem>
										<Divider orientation="vertical" variant="middle" flexItem />
										<ListItem>
											<ListItemIcon>
												<LocationOn fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={data.PhysicalCity} />
										</ListItem>
										<Divider orientation="vertical" variant="middle" flexItem />
										<ListItem>
											<ListItemIcon>
												<Phone fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={formatPhone(data.PrimaryPhoneNumber || "")} />
										</ListItem>
										<Divider orientation="vertical" variant="middle" flexItem />
										<ListItem>
											<ListItemIcon>
												<Email fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={data.PrimaryEmailAddress} />
										</ListItem>
									</List>
								</React.Fragment>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={2}>
					<Card>
						<CardContent style={{ padding: 0 }}>
							{tabsFetching ? (
								<CircularProgressCentered size={50} color="primary" />
							) : (
								<SummaryTabs moduleName={module} />
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={10}>

					<Switch>
						<Route path={`/${SUMMARY}/:module/${SUMMARY_PARAM}/summary`}>
							{summaryFetching ? (
								<CircularProgressCentered size={50} color="primary" />
							) : (
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">{data.CompanyName}</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classes.moduleDetails}>
														<Typography variant="body1">{`${data.Profile || ""}`}</Typography>
														<Grid container spacing={2}>
															<Grid item xs={6}>
																<List orientation="vertical">
																	<ListItem>
																		<Typography className={classes.label}>{`Email: `}</Typography>
																		<Typography>{`${data.PrimaryEmailAddress}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Phone: `}</Typography>
																		<Typography>{`${formatPhone(data.PrimaryPhoneNumber || "")}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Engagement Level: `}</Typography>
																		<Typography>{`${data.EngagementLevel || ""}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Business Type: `}</Typography>
																		<Typography>{`${data.NAICSCode || ""}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Groups: `}</Typography>
																		<Typography>{`${(data.Groups ? data.Groups.map((group, i) => {
																			return i ? ` ${group.label}` : group.label
																		}) : "") || ""}`}</Typography>
																	</ListItem>
																</List>
															</Grid>
															<Grid item xs={6}>
																<List orientation="vertical">
																	<ListItem>
																		<Typography className={classes.label}>{`Address: `}</Typography>
																		<Typography>{`${formatAddress(data.PhysicalAddressLine1, data.PhysicalAddressLine2, data.PhysicalAddressLine3, data.PhysicalCity, data.PhysicalStateCode, data.PhysicalPostalCode)}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Website: `}</Typography>
																		<Typography>{`${formatPhone(data.WebURL)}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Region: `}</Typography>
																		<Typography>{`${data.Region || ""}`}</Typography>
																	</ListItem>
																	<ListItem>
																		<Typography className={classes.label}>{`Tags: `}</Typography>
																		<Typography>{`${(data.Tag ? data.Tag.map((tag, i) => {
																			return i ? ` ${tag.label}` : tag.label
																		}) : "") || ""}`}</Typography>
																	</ListItem>
																</List>
															</Grid>
														</Grid>
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">Related Accounts</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classes.moduleDetails}>
														{
															data.Accounts && data.Accounts.recs ?
																<List orientation="vertical">
																	{
																		data.Accounts && data.Accounts.recs ? data.Accounts.recs.map(account => {
																			return <ListItem>
																				<Typography className={classes.label}>{`Name: `}</Typography>
																				<Typography>{`${account.label}`}</Typography>
																			</ListItem>
																		}) : undefined
																	}
																</List> : <React.Fragment>
																	<Typography className={classes.noRecords}>No Related Accounts</Typography>
																	<Button variant="contained" color="primary">
																		Add Account
																	</Button>
																</React.Fragment>
														}
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">Recent Activity</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classes.moduleDetails}>
														{
															data.Activity && data.Activity.recs ?
																<List orientation="vertical">
																	{
																		data.Activity && data.Activity.recs ? data.Activity.recs.map(activity => {
																			return <ListItem>
																				<Typography className={classes.label}>{`Name: `}</Typography>
																				<Typography>{`${activity.label}`}</Typography>
																			</ListItem>
																		}) : undefined
																	}
																</List> : <React.Fragment>
																	<Typography className={classes.noRecords}>There is no recent activity for this account.</Typography>
																</React.Fragment>
														}
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
										</Grid>

									</Grid>

									<Grid item xs={4}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">Assigned Users</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classes.moduleDetails}>
														<List orientation="vertical">
															{
																data.AssignedUsers ? data.AssignedUsers.map(user => {
																	return <ListItem>
																		<Typography className={classes.label}>{`Name: `}</Typography>
																		<Typography>{`${user.label}`}</Typography>
																	</ListItem>
																}) : ""
															}
														</List>
														<Button variant="contained" color="primary">
															Add User
														</Button>
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">Tasks</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classNames(classes.moduleDetails)}>
														<List orientation="vertical" className={classNames(classes.tasks)}>
															{
																data.Tasks ? data.Tasks.recs.map(task => {
																	const now = new Date();
																	const taskClass = task.CompleteDt ? classes.taskCompleted : (now > new Date(task.DueDate)) ? classes.taskOverdue : "";

																	return <React.Fragment><ListItem>
																		<Typography className={classes.label}>{`${task.Title}`}</Typography>
																		<Typography className={classNames(classes.alignRight, taskClass)}>{`${formatDate(task.DueDate)}`}</Typography>
																	</ListItem>
																		<ListItem className={classes.lastDetail}>
																			<Typography>{`${task.TaskType}`}</Typography>
																			<Typography className={classes.alignRight}>{`${task.Priority}`}</Typography>
																		</ListItem>
																	</React.Fragment>
																}) : <Typography>No Tasks</Typography>
															}
														</List>
														<Button variant="contained" color="primary">
															Add Task
														</Button>
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
											<Grid item xs={12}>
												<ExpansionPanel defaultExpanded>
													<ExpansionPanelSummary expandIcon={<ExpandMore />} >
														<Typography variant="h5">Latest Notes</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails className={classes.moduleDetails}>
														<List orientation="vertical">
															{
																data.Notes && data.Notes.recs ? data.Notes.recs.map(note => {
																	return <React.Fragment><ListItem>
																		<Typography className={classes.label}>{`${note.NoteTitle}`}</Typography>
																	</ListItem>
																		<ListItem className={classes.lastDetail}>
																			<Typography>{`${note.NoteText}`}</Typography>
																		</ListItem>
																	</React.Fragment>
																}) : <Typography>There are no notes for this account.</Typography>
															}
														</List>
														<Button variant="contained" color="primary">
															Add Note
														</Button>
													</ExpansionPanelDetails>
												</ExpansionPanel>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Route>
						<Route path={`/${SUMMARY}/:module/${SUMMARY_PARAM}/:tab`}>
							<SubGrid />
						</Route>
						<Route path={`/${MYPROFILE}/:tab`}>
							<MyProfileGrid />
						</Route>
					</Switch>
				</Grid>
			</Grid>
		</React.Fragment>;
};

const mapStateToProps = (state) => {
	return {
		data: state.summary.data,
		summaryFetching: state.summary.summaryFetching,
		tabsFetching: state.summary.tabsFetching,
		shouldRedirect: state.summary.shouldRedirect,
		summaryErr: state.summary.summaryErr,
		summaryTabSettings: state.summary.tabs
	};
};

const mapDispatchToProps = {
	summaryFetch: Creators.summaryFetch,
	summaryReset: Creators.summaryReset
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AlternateSummary));
