import React from "react";
import { Grid } from "@material-ui/core";

// TODO: use material flexbox instead once that becomes stable
const FlexContainer = ({ direction = "row", count = 0, wrap = false, children }) => {
	return <Grid
		container
		direction={direction}
		wrap={wrap ? "wrap" : "nowrap"}
		count={count}
		width={"100%"}
	>
		{children}
	</Grid>;
};

export const FlexColumn = (props) => <FlexContainer direction="column" {...props} />;

export default FlexContainer;
