import React, { Component } from "react";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Typography from "@material-ui/core/Typography/Typography";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error) {
		this.setState({
			error
		});
	}

	render() {
		const { classes } = this.props;

		if (this.state.error) {
			return (
				<div className={classes.wrapper}>
					<div><ErrorOutline classes={{ root: classes.icon }} /></div>
					<div><Typography classes={{ root: classes.text }}>{`Something went wrong. We've made a note of the error.`}</Typography></div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default withStyles(styles)(ErrorBoundary);
