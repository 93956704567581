import React, { useMemo, ChangeEvent } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop, DragObjectWithType, DropTargetMonitor } from "react-dnd";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(styles);

export interface FileInputAddon {
	label: string
	onClick: () => void
}

interface Props {
	onDrop: (item: DragObjectWithType, monitor: DropTargetMonitor) => void
	onSelect: (event: ChangeEvent<HTMLInputElement>) => void
	singular?: boolean
	disabled?: boolean
	classes?: Record<string, string>
	addon?: FileInputAddon
}

const TargetBox: React.FC<Props> = ({ onDrop, onSelect, singular, addon }) => {
	const classes = useStyles();
	const [{ canDrop, isOver }, drop] = useDrop({
		accept: [NativeTypes.FILE],
		drop: (item, monitor) => {
			onDrop && onDrop(item, monitor);
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const strFile = useMemo(() => {
		return "file" + (singular ? "" : "s");
	}, [singular]);

	const isActive = canDrop && isOver;

	return (
		<div ref={drop} className={classes && classes.targetBox}>
			{isActive ? `Release ${strFile} to drop` : <span>
			Drag {strFile} here or <input id="upload" type="file" multiple={!singular} onChange={onSelect} style={{
				opacity: 0,
				position: "absolute",
				pointerEvents: "none",
				// alternative to pointer-events, compatible with all browsers, just make it impossible to find
				width: 1,
				height: 1
			}}/>
			<label htmlFor="upload" className={classes.browse}>browse {strFile}{addon && <span className="addon"><span>or</span><a onClick={addon.onClick}>
					{addon.label}
				</a></span>}</label>
		</span>}
		</div>
	);
};

export default TargetBox;
