import React, { useEffect } from "react";
import { Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Creators } from "../../../actions/transientElementActions";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import FormFrame from "../../Form/FormFrame";
import { EDIT, GLOBAL_TABBED_DRAWER } from "../../../common/consts";
import CircularProgressCentered from "../../CircularProgressCentered";
import ExpandableText from "../../ExpandableText";

function testJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const RenderJson = ({ value }) => {
	if (value && value.name && (value.url || value.path)) {
		return <a href={value.url || value.path} target="_blank" rel="noopener noreferrer" >{value.name}</a>;
	} else {
		return <ExpandableText content={JSON.stringify(value)} />;
	}
};

const TableHead = ({ values, classes }) => {
	if (values && values.length) {
		return (
			<div className={classNames(classes.tableCell, classes.tableHeaderCell)}>
				{ values.map((value, i) => {
					return (
						<div key={`sectionalDisplay-header-${i}`} className={classNames(classes.tableTitleCell, classes.tableCellBorder)}>
							{value}
						</div>
					);
				})
				}
			</div>
		);
	}

	return "";
};

const TableCell = ({ title, titleLink, value, classes, hasHeaders }) => {
	title = hasHeaders ? title.trim() : (title.length ? `${title.trim()}:` : title);
	const isJson = testJson(value);
	const isObject = typeof value === "object";

	return (
		<div className={hasHeaders ? classNames(classes.tableCell, classes.tableHeaderCell) : classes.tableCell }>
			<div className={classNames(titleLink ? classes.tableValueCell : classes.tableTitleCell, title.length ? classes.tableCellBorder : classes.tableCellBlank)}>
				{ titleLink ? <Link to={titleLink}>{title}</Link> : title }
			</div>
			<div className={classNames(classes.tableValueCell, title.length ? classes.tableCellBorder : classes.tableCellBlank)}>
				{ isJson ? <RenderJson value={JSON.parse(value)} /> : isObject ? value : <ExpandableText content={value || ""} />}
			</div>
		</div>
	);
};

const CellSections = ({ isLoading, data, classes, titleLink, sortCellData, noDataMessage = "" }) => {
	return (
		<React.Fragment>
			{
				!isLoading && data.length ? data.map((section, i) => (
					<ExpansionPanel defaultExpanded className={classes.section} key={`sectionalDisplay-section-${i}`}>
						<ExpansionPanelSummary className={classNames(classes.sectionHeader, "CyFormSectionHeader")} expandIcon={<ExpandMoreIcon />}>
							{
								titleLink ?
									(<Link className={classes.titleLink} to={titleLink.replace("${RecordID}", section.RecordID)}>{section.Title}</Link>) :
									section.Title
							}
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className={classNames(classes.gridContainer)}>
							<TableHead 
								values={section.CellHeader} 
								classes={classes} 
								key={"sectionalDisplay-header"}
							/>
							{
								sortCellData ?
									section.CellData.sort((a, b) => (a.Title > b.Title) ? 1 : -1).map((cellData, k) =>
										<TableCell
											classes={classes}
											title={cellData.Title}
											titleLink={cellData.TitleLink}
											value={cellData.Value}
											hasHeaders={section.CellHeader ? true : false}
											key={`sectionalDisplay-cell-${section.RecordID}-${cellData.ID}-${i}-${k}`}
										/>) :
									section.CellData.map((cellData, k) =>
										<TableCell
											classes={classes}
											title={cellData.Title}
											titleLink={cellData.TitleLink}
											value={cellData.Value}
											key={`sectionalDisplay-cell-${section.RecordID}-${cellData.ID}-${i}-${k}`}
										/>
									)
							}
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)
				) : !isLoading && !data.length ?
					<div className={classes.noAmenities}>{noDataMessage}</div> : <CircularProgressCentered/> }
		</React.Fragment>
	);
};

const CollapsibleSectionCellDisplay = ({ recId, type, fetchData, isLoading, data, classes, titleLink, tabs = [], noDataMessage = "", sortCellData = true }) => {
	useEffect(() => {
		if (recId) {
			fetchData && fetchData();
		}
	}, [recId]);

	const dispatch = useDispatch();

	if (!recId) {
		return null;
	}

	let title = "Amenity Detail";
	if (type === "account_meetingFacility") {
		title = "Meeting Facilities Detail";
	} else if (type === "listing_attribute") {
		title = "Attribute Detail";
	}

	const displayComponent = action => (
		<FormFrame
			title={title}
			type={type}
			action={action}
			recId={recId}
			afterSubmit={() => {
				fetchData && fetchData(recId);
				dispatch(Creators.setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false }));
			}}
		/>
	);

	return (
		<React.Fragment>
			{ tabs.length ?
				<span
					className={classes.editSpan}
					onClick={() => dispatch(Creators.setDrawerOpen(GLOBAL_TABBED_DRAWER, {
						type,
						isOpen: true,
						id: recId,
						action: EDIT,
						displayComponent: displayComponent(EDIT),
						tabs: tabs,
						activeTab: tabs[0].name
					}))}
				>
					<Edit /> <span className={classes.editAmenities}>{tabs[0].name}</span>
				</span> :
				null
			}
			<CellSections isLoading={isLoading} data={data} classes={classes} noDataMessage={noDataMessage} titleLink={titleLink} sortCellData={sortCellData} />
		</React.Fragment>
	);
};

export default CollapsibleSectionCellDisplay;
