import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import classNames from "classnames";
import { DataView } from "@simpleview/sv-mosaic";
import useWindowSize from "@react-ui/hooks/useWindowSize";
import { makeStyles } from "@material-ui/styles";

import GridModal from "../Modal";
import { GridModalState } from "../Modal/typeDefs";
import useFetchGrid from "./useFetchGrid";
import useCallbackMap from "./useCallbackMap";
import { Creators as GridCreators } from "../../actions/gridActions";
import styles from "./styles";
import CircularProgressCentered from "../CircularProgressCentered";

const useStyles = makeStyles(styles);

type ModuleName = string;

interface Props {
	moduleName: ModuleName;
	parentModule?: ModuleName;
	withMargin?: boolean;
	fullWidth?: boolean;
	parentId?: string | number;
}

const MosaicGrid: React.FC<Props> = (props) => {
	const { moduleName, parentModule, withMargin, fullWidth, parentId } = props;
	const dispatch = useDispatch();
	const windowSize = useWindowSize();
	const classes = useStyles();
	const [modalState, setModalState] = useState<GridModalState>({
		title: "",
		isOpen: false
	});
	const parentRecId = useSelector((state: any) => {
		// make sure refId matches RecordID
		return state.summary?.data?.RecordID === parentId ? state.summary?.data?.recId : undefined;
	});
	
	// If recordID doesn't exist, use refId
	const refId = (parentId && parentRecId) ? parentRecId : parentId;
	const callbackMap = useCallbackMap({
		moduleName, parentModule, parentId: refId, setModalState
	});
	
	
	const closeModal = useCallback(() => setModalState({
		...modalState,
		isOpen: false
	}), [modalState]);
	
	
	useEffect(() => {
		const gridType = parentModule ? `${parentModule}.${moduleName}` : moduleName;
		dispatch(GridCreators.setGridViewInStore(gridType));
	}, [moduleName, parentModule]);

	const gridProps = useFetchGrid({ moduleName, parentModule, refId, callbackMap });

	return gridProps.title ? <Paper className={classNames(classes.gridComponent, { [classes.gridWithMargin]: withMargin, [classes.fullWidth]: fullWidth })} style={{
		// make room for margin/tab and navbar
		overflowX: "auto",
		height: (windowSize?.height || 100) - 180
	}}>
		<DataView
			sticky={true}
			savedViewAllowSharedViewSave={false}
			{...gridProps}
		/>
		<GridModal handleClose={closeModal} isOpen={modalState.isOpen} title={modalState.title} maxWidth={modalState.maxWidth || "sm"} useHandleBackdrop={modalState.useHandleBackdrop}>
			{ modalState.children}
		</GridModal>
	</Paper> : <CircularProgressCentered message="No results were found.  Try adjusting your filters or select a different view." messageType="warning" />;
};

export default MosaicGrid;
