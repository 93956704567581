import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Backspace from "@material-ui/icons/Backspace";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";

import styles from "./styles";
import useGraphServer from "../../../hooks/useGraphServer";
import { getModuleIcon } from "../../../common/moduleData";
import { Creators } from "../../../actions/recordActions";
import { useHistory } from "react-router";

const escapeRegExp = (text) => {
	return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const SearchBox = ({ classes }) => {
	const crm = useGraphServer();
	const history = useHistory();
	const dispatch = useDispatch();
	const [results, setCurrentResults] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null); //anchor for the popper menu
	const [inputValue, setInputValue] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const [tooltipTitle, setTooltipTitle] = useState("");
	const [opacity, setOpacity] = useState(1); //This is the opacity of the results display (opacity is dimmed to signal loading)
	const inputRef = useRef(null); //The ref for the text box. Used for anchoring the menu and setting focus programmatically
	const divRef = useRef(null); //The ref for the containing div of the entire component. When the menu is open, this is assigned to anchorEl

	const handleChange = debounce(async evt => {
		const value = evt ? evt.target.value.trim() : "";
		if (value.length < 3) {
			setResults([]);
			setTooltipTitle("");
			if(value.length > 0) {
				setTooltipTitle("Minimum of 3 characters required");
			}
			return;
		}
		setOpacity(0.4);
		/*
		* we fetch more than we display (100 records), so that if a user hits enter to go to the detail
		* we don't have to refetch
		*/
		const results = await crm.general.get_old({
			type: "searchResults",
			queryString: `
				searchResults(filter: $filter) {
					recs {
						type
						recId
						RecordID
						title
						score
						groups
					}
					message
					success
				}
			`,
			filter: { 
				term: evt.target.value,
				filterType: "crm_search_results_filter!" 
			}
		});
		if (results?.recs?.length) {
			setTooltipTitle("");
			setResults(results.recs);
			openMenu();
		} else if (results?.success) {
			setResults([]);
			setTooltipTitle("No results found");
		} else {
			setTooltipTitle("Could not fetch results");
		}
		setOpacity(1);
	}, 250); //Debounced for 250ms. We can play with this, but 250 seems about right.


	const setResults = results => {
		dispatch(Creators.setSearchResults(results));
		setCurrentResults(results);
	};
	const openMenu = () => {
		setAnchorEl(divRef.current);
		inputRef.current.focus();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleOpen = () => {
		if (results.length) {
			openMenu();
		} else {
			handleClose();
		}
	};

	const setFocus = () => {
		setIsFocused(true);
		if (results.length) {
			openMenu();
		}
	};

	const setBlur = () => {
		setIsFocused(false);
	};

	const handleEnter = evt => {
		if(evt.key === "Enter") {
			history.push("/searchresults");
			handleClose();
		}
	};
	/*
	*	this could be simplified to use material-ui's autocomplete,
	*	but this will include advanced search options
	* 	so this approach should make it easier to implement that
	*/
	return (
		<>
			<Tooltip title={tooltipTitle} open={!!tooltipTitle.length} id={"header-search-feedback-tooltip"}>
				<div className={classes.search} ref={divRef} style={isFocused ? {
					backgroundColor: "#fff",
					transition: "background-color 0.5s ease"
				} : { transition: "background-color 0.5s ease" }}>
					<Input
						startAdornment={<SearchIcon className={classes.searchIcon} />}
						ref={inputRef}
						placeholder="Search…"
						value={inputValue}
						onChange={async evt => {
							evt.persist();
							setInputValue(evt.target.value); //We don't want the value update to be debounced or we get delays in typing.
							await handleChange(evt);
						}}
						onKeyDown={handleEnter}
						onFocus={setFocus}
						onBlur={setBlur}
						className={"CyHeaderSearchInput"}
						classes={{
							input: classes.inputInput
						}}
						endAdornment={
							<Grid container direction="row" alignItems="center" justify={"flex-end"} className={classes.expandContainer}>
								{inputValue.length ?
									<Grid item id={"clear-search-button-container"} className={classes.clear}>
										<Tooltip title={"clear"}>
											<IconButton onClick={async () => {
												setInputValue("");
												await handleChange({ target: { value: "" } });
											}}>
												<Backspace className={classes.backspace} alt={"clear"}/>
											</IconButton>
										</Tooltip>
									</Grid>
									: null
								}
								<Grid item>
									<IconButton className={classes.expand} onClick={toggleOpen}>
										{
											anchorEl && results?.length ?
												<ExpandLess className={classes.expand} /> :
												<ExpandMore className={classes.expand} />
										}
									</IconButton>
								</Grid>
							</Grid>
						}
						disableUnderline={true}
					/>
					<Backdrop open={!!anchorEl} className={classes.searchBackdrop} onClick={handleClose} />
					<Popper
						placement={"bottom-start"}
						anchorEl={anchorEl}
						open={!!anchorEl}
						onClose={handleClose}
						className={classes.popper}
					>
						<Paper elevation={5} style={{ opacity, overflowY: "auto", maxHeight: 400 }}>
							<List className={classes.searchList}>
								{
									results.slice(0, 10).map((result, i) => {
										const Icon = getModuleIcon(result.type);
										return (
											<ListItem
												key={`search-quick-${i}`}
												onClick={handleClose}
												component={Link}
												to={`/summary/${result.type}/${result.RecordID}`}
												className={classes.menuItem}>
												<ListItemIcon>
													<Icon/>
												</ListItemIcon>
												<Highlighter
													searchWords={escapeRegExp(inputValue).split(" ")}
													textToHighlight={result.title}
													highlightClassName={classes.highlight}
													caseSensitive={false}
												/>
											</ListItem>
										);
									})
								}
							</List>
						</Paper>
					</Popper>
				</div>
			</Tooltip>
		</>
	);
};

export default withStyles(styles)(SearchBox);
