const styles = theme => ({
	addAvatar: {
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: theme.colors.lightBlue
		}
	}
});

export default styles;
