const styles = theme => ({
	container: {
		padding: 20
	},
	table: {
		"& button.withIcon": {
			marginLeft: 8
		}
	},
	brandYellow: {
		backgroundColor: theme.colors.brandYellow
	},
	brandGreen: {
		backgroundColor: theme.colors.brandGreen
	},
	brandBlue: {
		backgroundColor: theme.colors.brandBlue
	},
	brandTeal: {
		backgroundColor: theme.colors.brandTeal
	},
	brandOrange: {
		backgroundColor: theme.colors.brandOrange
	},
	brandGray: {
		backgroundColor: theme.colors.brandGray
	},
	exterminateRed: {
		backgroundColor: theme.colors.exterminateRed
	},
	filterGreen: {
		backgroundColor: theme.colors.filterGreen
	},
	secondaryTeal: {
		backgroundColor: theme.colors.secondaryTeal
	},
	primaryBlue: {
		backgroundColor: theme.colors.primaryBlue
	},
	almostBlack: {
		backgroundColor: theme.colors.almostBlack
	},
	darkGray: {
		backgroundColor: theme.colors.darkGray
	},
	darkerGray: {
		backgroundColor: theme.colors.darkerGray
	},
	black: {
		backgroundColor: theme.colors.black
	},
	shadow: {
		backgroundColor: theme.colors.shadow
	},
	gandolf: {
		backgroundColor: theme.colors.gandolf
	},
	astral: {
		backgroundColor: theme.colors.astral
	},
	system20: {
		backgroundColor: theme.colors.system20
	}
});

export default styles;
