import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import Details from "./Details";

const DetailPanel = ({ classes, data, definition }) => {
	return definition?.sections?.map((section, i) => {
		return <ExpansionPanel 
			key={i}
			defaultExpanded={section.defaultExpanded} 
			classes={{
				root: classes.expansionPanel,
				expanded: classes.expansionPanelExpanded
			}}>
			<ExpansionPanelSummary
				expandIcon={<ArrowDropDown />}
				classes={{
					root: classes.expansionPanelSummary,
					content: classes.expansionPanelSummaryContent,
					expanded: classes.expansionPanelSummaryExpanded,
					expandIcon: classes.expansionPanelIcon
				}}
			>
				<Typography variant="subtitle1">{section.subtitle}</Typography>
			</ExpansionPanelSummary>
			<Details data={data} section={section} classes={classes} />
		</ExpansionPanel>;
	});
};

export default DetailPanel;
