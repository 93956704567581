const styles = theme => ({
	container: {
		padding: theme.spacing(3),
		paddingLeft: theme.spacing(3.5),
		paddingRight: theme.spacing(2.5),
	},
	title: {
		marginBottom: theme.spacing(1.25)
	},
	dashboardCard: {
		minHeight: "100%",
		"& ul li": {
			paddingTop: 10,
			paddingBottom: 2
		}
	},
	dashletTitle: {
		fontStyle: "normal",
		fontWeight: "bold"
	},
	linkButton: {
		cursor: "pointer"
	},
	carouselDashlet: {
		padding: theme.spacing(2)
	},
	dashboardSubGrid: {
		paddingBottom: theme.spacing(3)
	}
});

export default styles;
