import React, { useState } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Edit, Delete, FileCopyOutlined, MoreVert, Assignment } from "@material-ui/icons";
import Preview from "../../Icons/Preview";
import classNames from "classnames";
import { connect } from "react-redux";
import { wrapFormatValue } from "../../../common/format";
import styles from "./styles";

// Registrate handler hear with display name for the action
// pass in the handler as a prop.
const moreActionHandlers = {
	email: {
		handler: "handleSendEmail",
		icon: "Email",
		label: "Send Email"
	},
	anonymize: {
		handler: "handleAnonymize",
		label: "Data Privacy Anonymize"
	},
	anonymizeReport: {
		handler: "handleAnonymizeReport",
		label: "Data Privacy Report"
	}
};

const TopCard = props => {
	const [anchor, setAncor] = useState(null);
	const { 
		classes,
		fullName = "",
		image,
		initial,
		status,
		handleEdit,
		handleClone,
		handleView, 
		handleDelete,
		handleReview,
		handleRead,
		rowActions = [],
		moduleName,
		PendingID,
		LastModifiedDt,
		kbLinks,
		productName
	} = props;
	const kbLink = kbLinks[moduleName] || "https://kb.simpleviewdms.com/hc/en-us";
	const avatar = image ? <Avatar src={image} className={classes.cardAvatar} /> : <Avatar className={classes.cardAvatar}>{initial || fullName.substring(0, 1) || "?"}</Avatar>;

	const SUMMARY_EDIT = (
		<IconButton key="Edit" className={classes.cardIcon} onClick={handleEdit}>
			<Edit />
		</IconButton>
	);

	const SUMMARY_CLONE = (
		<IconButton key="Clone" className={classes.cardIcon} onClick={handleClone}>
			<FileCopyOutlined />
		</IconButton>
	);	

	const SUMMARY_DELETE = (
		<IconButton key="Delete" className={classes.cardIcon} onClick={handleDelete}>
			<Delete />
		</IconButton>
	);	

	const SUMMARY_REVIEW = (
		<IconButton key="Review" className={classes.cardIcon} onClick={handleReview}>
			<Assignment />
		</IconButton>
	);

	const SUMMARY_READ = (
		<IconButton key="Read" className={classes.cardIcon} onClick={handleRead}>
			<Preview />
		</IconButton>
	);
	

	const SUMMARY_ACTIONS = {
		edit: SUMMARY_EDIT,
		clone: SUMMARY_CLONE,
		delete: SUMMARY_DELETE,
		review: SUMMARY_REVIEW,
		read: SUMMARY_READ
	};	

	// Map Summary Actions 
	const actions = [];

	if(!PendingID) {
		rowActions.map(action => {
			if (action in SUMMARY_ACTIONS && action !== "review") {
				actions.push(SUMMARY_ACTIONS[action]);
			}
		});	
	} else if (rowActions.includes("review")) {
		actions.push(SUMMARY_ACTIONS["review"]);
		actions.push(SUMMARY_ACTIONS["read"]);
	} else if (rowActions.includes("read")) {
		actions.push(SUMMARY_ACTIONS["read"]);
	}

	const renderActions = () => {
		return actions.length ? (
			<React.Fragment>
				{actions}
			</React.Fragment>
		) : null;		
	};

	const renderMoreActions = () => {
		const result = [];
		for (const action in moreActionHandlers) {
			const currAction = moreActionHandlers[action];
			if (props[currAction.handler] && rowActions.includes(action)) {
				result.push(<MenuItem key={`menu-item-for-${currAction.handler}`} onClick={() => {
					// call handler
					props[currAction.handler]();
					// close dropdown
					setAncor(null);
				}}>{currAction.label}</MenuItem>);
			}
		}
		return result.length ? (
			<React.Fragment>
				<IconButton className={classes.cardIcon} onClick={
					(e) => {
						setAncor(e.currentTarget);
					}
				}>
					<MoreVert />
				</IconButton>
				<Menu id="more-action-menu" anchorEl={anchor} open={!!anchor} onClose={() => {
					setAncor(null)
					;
				}}>
					{result}
				</Menu>
			</React.Fragment>
		) : null;		

	};

	return <Card className={classes.card} square elevation={0}>
		<CardContent className={classes.cardContent}>
			{avatar}
			<Typography variant="subtitle1">
				{productName === "dms" ? <IconButton key="Help" className={classNames(classes.cardIcon, classes.tinyFontSize)} onClick={() => window.open(kbLink, "_blank")}>
					<HelpIcon color="primary" className={classes.tinyFontSize} />
				</IconButton> : null}
				{fullName}
			</Typography>
			{status ? <Typography>Status: {status}</Typography> : null}
			{LastModifiedDt ? <Typography>Last Updated: {wrapFormatValue(LastModifiedDt, "date")}</Typography> : null}
			{moduleName === "myProfile" ? 
				<Button color="primary" disableRipple className={"deemphasized"} onClick={handleView}>
					Edit My Profile
				</Button>
				: null}
			<CardActions className={classes.cardSubActions}>
				{renderActions()}
				{renderMoreActions()}
			</CardActions>
		</CardContent>
	</Card>;
};

TopCard.props = {
	fullName: Proptypes.string.isRequired,
	phoneNumber: Proptypes.string,
	initial: Proptypes.string,
	status: Proptypes.string,
	LastModifiedDt: Proptypes.string,
	handleView: Proptypes.func.isRequired,
	handleEdit: Proptypes.func.isRequired,
	handleDelete: Proptypes.func.isRequired,
	handleClone: Proptypes.func.isRequired,
	handleReview: Proptypes.func.isRequired,
	handleRead: Proptypes.func.isRequired,
};

const mapStateToProps = state => ({
	kbLinks: state.header.kbLinks || {},
	productName: state.login.productName
});

const mapDispatchToProps = {};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(TopCard)
);
