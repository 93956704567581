import React, { useEffect, useState, useCallback } from "react";
import useGraphServer from "../../../hooks/useGraphServer";
import EmbeddedForm from "../../../components/EmbeddedForm";
import NotFound from "../../NotFound";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const EmbedFormViewer = () => {
	const crmGraphServer = useGraphServer();
	const acctId = useSelector(state => state.login.acctId);
	const isSVUser = useSelector((state) => state.login.isSVUser);
	const [forms, setForms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [curForm, setCurForm] = useState(null);
	const [primaryColor, setPrimaryColor] = useState("#0070F2");
	const [secondaryColor, setSecondaryColor] = useState("#F0F2F5");
	const [shouldLoad, setShouldLoad] = useState(true);
	const [pullFrom, setPullFrom] = useState({
		env: "dev",
		acct_id: acctId,
		key: ""
	});
	const env = window.location.href.split(".")[1];
	useEffect(() => {
		if (shouldLoad) {
			crmGraphServer.general.get_old({
				type: "webForm",
				queryString: `
				webForm {
					recs {
						id
						label
					}
					success
					message
					count
				}
			`
			}).then((res) => {
				setForms(res.recs);
				setLoading(false);
			});
			setShouldLoad(false);
		}
	}, [shouldLoad]);

	const debugClientForm = useCallback(() => {
		crmGraphServer.general.set_old({
			type: "syncClientForms",
			queryString: `
			syncClientForms(source: ${pullFrom.env}, destination:${env === "dms" ? "local" : env}, key:"${pullFrom.key}", debug:"${pullFrom.acct_id}") {
				success
				message
			}
			`
		}).then(res => {
			console.log(res);
			if (res.syncClientForms.success) {
				setShouldLoad(true);
			} else {
				alert(res.syncClientForms.message);
			}
		});
	}, [pullFrom]);
	
	return isSVUser ? (
		<div style={{
			paddingTop: "50px"
		}}>
			<h2>Web Form Preview</h2>
			{["dms", "dev"].includes(env) && <div>pull forms from <select onChange={(e) => {
				setPullFrom({
					...pullFrom,
					env: e.target.value
				});
			}}>
				<option value="dev">dev</option>
				<option value="qa">qa</option>
				<option value="staging">staging</option>
				<option value="live">live</option>
			</select> account <input defaultValue={acctId} onChange={(e) => {
				setPullFrom({
					...pullFrom,
					acct_id: e.target.value
				});
			}}></input><button onClick={debugClientForm}> pull</button> passcode: <input type="password" onChange={(e) => {
				setPullFrom({
					...pullFrom,
					key: e.target.value
				});
			}} />(It might not submit properly if you pull from diffierent account with UDFs)
			</div>}
			{loading && <p>loading...</p>}
			{(forms.length && !curForm) ? <select onChange={(e) => {
				setCurForm(e.target.value);
			}}>
				<option>Choose a form...</option>
				{forms.map(form => {
					return <option value={form.id} key={form.id}>{form.label}</option>;
				})}
			</select> : !curForm ? <p>No forms were found. <button onClick={() => setShouldLoad(true)}>reload</button></p> : ""}
			{ curForm && <button onClick={() => setCurForm(null)}>load another form</button>}
			<br />
			primary color:
			<input type="color" value={primaryColor} onChange={(e) => {
				setCurForm(null);
				setPrimaryColor(e.target.value);
			}} />
			<br />
			secondary color:
			<input type="color" value={secondaryColor} onChange={(e) => {
				setCurForm(null);
				setSecondaryColor(e.target.value);
			}} />
			<br />
			{ curForm && <EmbeddedForm 
				acct_id={crmGraphServer.general._graphServer.context.acct_id} 
				form_id={curForm} 
				primaryColor={primaryColor} 
				secondaryColor={secondaryColor}
			/>}
		</div>
	) : (
		<NotFound />
	);
};

export default EmbedFormViewer;
