import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import CircularProgressCentered from "../CircularProgressCentered";

import { Creators } from "../../actions/summaryActions";
import { Creators as TransientElementCreator } from "../../actions/transientElementActions";

import TopCard from "./TopCard";
import { connect } from "react-redux";
import styles from "./styles";
import { useParams } from "react-router-dom";
import DetailPanel from "./DetailPanel";
import useShouldFetch from "../../hooks/useShouldFetch";
import { useCallback } from "react";
import NotFound from "../NotFound";

const SummaryMiniPanel = ({ summaryFetch, moduleName, classes, summaryFetching, deleteRecord, data = {}, definition, summaryErr, shouldRedirect }) => {

	const { module: paramModule, recId } = useParams();

	const fetchSummary = useCallback(() => {
		if (!summaryErr) {
			summaryFetch(paramModule || moduleName, recId);
		}
	}, [paramModule, moduleName, recId, shouldRedirect]);

	useShouldFetch(paramModule || moduleName, {
		handleFetch: fetchSummary
	});

	return <Paper elevation={2} classes={{ root: classes.summaryPaper }}>
		{summaryFetching ? (
			<CircularProgressCentered size={75} color="primary" />
		) : shouldRedirect ? <NotFound message={summaryErr} /> : (
			<React.Fragment>
				<TopCard deleteRecord={deleteRecord} moduleName={moduleName} data={data} />
				<DetailPanel classes={classes} data={data} definition={definition} />
			</React.Fragment>
		)}
	</Paper>;
};

const mapStateToProps = state => ({
	data: state.summary.data,
	definition: state.summary.definition,
	summaryFetching: state.summary.summaryFetching,
	shouldRedirect: state.summary.shouldRedirect,
	summaryErr: state.summary.summaryErr
});

const mapDispatchToProps = {
	summaryFetch: Creators.summaryFetch,
	deleteRecord: Creators.deleteRecord,
	setDrawerOpen: TransientElementCreator.setDrawerOpen
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SummaryMiniPanel)
);
