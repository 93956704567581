const styles = theme => ({
	searchPaper: {
		margin: theme.spacing(5),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(5)
	},
	searchHeader: {
		backgroundColor: "#f0f2f5"
	},
	searchTable: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(5)
	},
	cell: {
		paddingLeft: theme.spacing(5),
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		}
	},
	recordsFoundText: {
		fontSize: "0.95em",
		color: "#c8c8c8",
		fontWeight: "normal"
	}
});

export default styles;
