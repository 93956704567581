import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Avatar, Table, TableBody, TableHead, TableRow, TableCell, Tabs, Tab, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import brandInfo from "./brandInfo.json";
import typographyImage from "../../assets/images/Typography.png";
import iconImage from "../../assets/images/Icons.png";
import EmbedFormViewer from "./EmbedFormViewer";
import NotFound from "../NotFound";

class Developers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: "colors"
		};
	}

	handleChange = (event, value) => {
		if (value) {
			this.setState({ tab: value });
		}
	};

	displayTabData = () => {
		const { classes } = this.props;
		const { tab } = this.state;

		if (tab === "colors") {
			return (
				<React.Fragment>
					<br />
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Color</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>CSS Variable</TableCell>
								<TableCell>Hex Code</TableCell>
								<TableCell>Uses</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{brandInfo.colors.map(color => {
								return (
									<TableRow key={color.id}>
										<TableCell>
											<Avatar className={classes[`${color.id}`]} />
										</TableCell>
										<TableCell>{color.name}</TableCell>
										<TableCell>{color.css}</TableCell>
										<TableCell>{color.hexCode}</TableCell>
										<TableCell>{color.uses}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</React.Fragment>
			);
		} else if (tab === "typography") {
			return (
				<React.Fragment>
					<br />
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Type</TableCell>
								<TableCell>Example</TableCell>
								<TableCell>Usage</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{brandInfo.typographys.map(typography => {
								return (
									<TableRow key={typography.id}>
										<TableCell>
											<Typography variant={typography.variant} className={typography.className}>
												{typography.name}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant={typography.variant} className={typography.className}>
												{typography.example}
											</Typography>
										</TableCell>
										<TableCell>{typography.usage}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<img src={typographyImage} />
				</React.Fragment>
			);
		} else if (tab === "buttons") {
			return (
				<React.Fragment>
					<br />
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Button</TableCell>
								<TableCell>Example</TableCell>
								<TableCell>Usage</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{brandInfo.buttons.map(button => {
								return (
									<TableRow key={button.id}>
										<TableCell>
											<Button variant={button.variant} color={button.color} className={button.className}>
												{button.name}
											</Button>
											<Button variant={button.variant} color={button.color} className={`${button.className} withIcon`}>
												<SaveIcon />
												{button.name}
											</Button>
										</TableCell>
										<TableCell>{button.example}</TableCell>
										<TableCell>
											{button.usage}
											<br />
											{button.usageWithIcon}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<br />
					<Typography variant="h5">General Usage</Typography>
					<ul>
						<li>
							<Typography variant="body2">Buttons should generally be placed in the top left of the Drawer/Page.</Typography>
						</li>
						<li>
							<Typography variant="body2">When multiple buttons are placed next to one another, there should be a 8px margin between them.</Typography>
						</li>
						<li>
							<Typography variant="body2">
								Most of the time it will be Primary, then Secondary (in that order) but there are exceptions like Back and Next buttons.
							</Typography>
						</li>
					</ul>
				</React.Fragment>
			);
		} else if (tab === "icons") {
			return (
				<React.Fragment>
					<br />
					<img src={iconImage} />
				</React.Fragment>
			);
		}
		return tab;
	};


	render() {
		const { classes } = this.props;
		const { tab } = this.state;

		if (tab) {
			return (
				<React.Fragment>
					<div className={classes.container}>
						{/*
						<Tabs value={tab} onChange={this.handleChange}>
							<Tab value="colors" label="Colors" />
							<Tab value="typography" label="Typography" />
							<Tab value="buttons" label="Buttons" />
							<Tab value="icons" label="Icons" />
						</Tabs>
						*/}
						{
						// this.displayTabData()
						}
						<EmbedFormViewer />
					</div>
				</React.Fragment>
			);
		}
		return <NotFound />;
	}
}

Developers.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Developers);
