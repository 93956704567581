import { AppBar, Button, Drawer, Grid, IconButton, Paper, Tab, Toolbar, Typography, Tabs as MaterialsTabs, withWidth } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import { Creators as SummaryCreators } from "../../actions/summaryActions";
import HTML5Backend from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import ReorderableNavTabs from "./ReorderableNavTabs";
import { Creators as TransCreators } from "../../actions/transientElementActions";
import cloneDeep from "lodash/cloneDeep";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { DndProvider } from "react-dnd";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import SummaryTab from "./Tab";

const Tabs = ({ tabs, setTabs, moduleName, classes }) => {
	const [state, setState] = useState({
		drawerOpen: false,
		anchorEl: null,
		reorderedTabs: []
	});

	if (tabs && moduleName) {
		if (tabs[moduleName] === undefined) {
			return null;
		}

		tabs[moduleName] = tabs[moduleName].sort((a, b) => b.sortOrder - a.sortOrder > 0 ? -1 : 1);
	}

	const { tab } = useParams();
	const [activeTab, setActiveTab] = useState(tab);

	useEffect(() => {
		if (tab && findTab(tab)) {
			setActiveTab(tab);
		}
	}, [tab, activeTab]);

	const toggleDrawer = () => {
		setState({
			...state,
			drawerOpen: !state.drawerOpen,
			anchorEl: null
		});
		setActiveTab(state.drawerOpen ? "reorder" : tab);
	};

	const findTab = () => {
		return tabs ? tabs[moduleName].find(_tab => _tab.gridType.split(".")[1] === tab) : null;
	};


	const renderTabs = () => {
		const tabsUI = tabs[moduleName].map(_tab => {
			const value = _tab.gridType.split(".")[1];
			return (
				<SummaryTab
					classes={classes}
					key={`${_tab.label}-summary-tab`}
					value={value}
					moduleName={value}
					tab={_tab}
					parentModule={moduleName} />
			);
		});

		tabsUI.push(
			<Tab
				key={"reorderTabs-summary-tab"}
				value={"reorder"}
				label={"Reorder Tabs"}
				onClick={() => toggleDrawer()}
				icon={null}
			/>
		);

		return tabsUI;
	};

	const saveReorderTabs = () => {
		const { reorderedTabs } = state;
		const tab = activeTab ? activeTab : tabs[0].key;
		setTabs(reorderedTabs);
		setState({ ...state, drawerOpen: false });
		setActiveTab(tab);
	};

	const moveTab = reorderedTabs => {
		setState({
			...state,
			reorderedTabs
		});
	};

	const { drawerOpen } = state;
	if (!tabs) {
		return null;
	}
	const selectedTab = findTab(activeTab) ? activeTab : (tabs[moduleName][0] ? tabs[moduleName][0].gridType.split(".")[1] : undefined);
	if (tabs[moduleName].length > 0 && selectedTab) {
		const devolopmentEnv = false; //["dms-development", "dms-dev"].includes(process.env.PRODUCT);

		return (
			<DndProvider backend={HTML5Backend}>
				<Paper square className={classes.container}>
					<Grid container>
						<Grid item className={devolopmentEnv ? classes.verticaluitabs : classes.uitabs}>
							<MaterialsTabs
								orientation={devolopmentEnv ? "vertical" : "horizontal"}
								indicatorColor="primary"
								textColor="primary"
								value={selectedTab}
								className={classes.uitabs}
								onChange={setActiveTab}
								variant="scrollable"
								scrollButtons="auto">
								{
									renderTabs()
								}
							</MaterialsTabs>
						</Grid>
					</Grid>


					<Drawer className={classes.tabDrawer} anchor="right" variant="temporary" open={drawerOpen} onClose={toggleDrawer}>
						<div tabIndex={0} role="button">
							<AppBar position="static">
								<Toolbar>
									<Typography variant="h6" color="inherit">
										Ordering
									</Typography>
									<div className={classes.rightToolbar}>
										<IconButton onClick={toggleDrawer} aria-haspopup="true" color="inherit">
											<Close />
										</IconButton>
									</div>
								</Toolbar>
							</AppBar>
							<div className={classes.reorderMainDrawer}>
								<Typography>Reorder Tabs</Typography>

								<Button onClick={() => saveReorderTabs()}>Apply</Button>
								<ReorderableNavTabs moduleName={moduleName} moveTab={moveTab} />
							</div>
						</div>
					</Drawer>
				</Paper>
			</DndProvider>
		);
	}

	return null;
};

Tabs.propTypes = {
	width: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired
};

Tabs.defaultProps = { tabs: [] };

const mapStateToProps = state => ({
	tabs: cloneDeep(state.summary.tabs),
	activeTab: state.summary.activeTab
});

const mapDispatchToProps = {
	setTabs: SummaryCreators.setTabs,
	setMessage: TransCreators.setMessage
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(
	compose(
		withStyles(styles),
		withWidth()
	)(Tabs)
));
