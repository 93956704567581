import React, { useState, ReactElement } from "react";
import { Tab, Badge } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { MYPROFILE } from "../../common/consts";
import useShouldFetch from "../../hooks/useShouldFetch";
import useGraphServer from "../../hooks/useGraphServer";


type ModuleBadgesFields = {[moduleName: string]: string};

const moduleBadgesFields: ModuleBadgesFields = {
	note: "Notes { count }",
	task: "Tasks { openTasks count recs { CompleteDt } }"
};

type GetCountRes = { recs: any[] };
const getCount = (res: GetCountRes, moduleName: string): number => {
	const rec = res.recs[0];
	const getters: {[moduleName: string]: (rec: any) => number } = {
		note: (rec) => rec ? rec.Notes.count : 0,
		task: (rec) => rec ? rec.Tasks.openTasks : 0
	};
	return getters[moduleName](rec);
};

interface TabData {
	label: string;
	sortOrder: number;
	gridType: string;
	permission?: string;
	showCount?: boolean;
}

interface SummaryTabProps {
	classes: Record<string, string>;
	parentModule: string;
	className?: string;
	moduleName: string;
	tab: TabData;
}

const SummaryTab: React.FC<SummaryTabProps> = (props: SummaryTabProps) => {
	const { classes, parentModule, className, moduleName, tab } = props;
	const { recId: parentID } : Record<string, any> = useParams();
	const [count, setCount] = useState<number | undefined>();
	const crmGraphServer = useGraphServer();
	useShouldFetch(moduleName, {
		handleFetch: (): void => {
			const fields = ["recId"];
			if (!moduleBadgesFields[moduleName]) {
				// don't do anything if badge field is not specified.
				return;
			}
			fields.push(moduleBadgesFields[moduleName]);
			crmGraphServer.general.get({
				type: `${parentModule}`,
				filter: {
					RecordID: parentID
				},
				fields
			}).then((res: any) => {
				setCount(getCount(res, moduleName));
			});
		},
	});

	const badge = (cnt: number, label?: string): ReactElement => {
		const badgeContent = cnt < 10 ? classes.badgeContentSingleDigit : classes.badgeContent;
		return (
			<Badge badgeContent={cnt} color="primary" classes={{ root: classes.badge, badge: badgeContent }}>
				{label || cnt}
			</Badge>
		);
	};

	return <Tab
		value={moduleName}
		className={className}
		label={count ? "" : tab.label}
		component={Link}
		classes={{
			root: classes.moduleTab 
		}}
		icon={count ? badge(count, tab.label!) : ""}
		to={`${parentModule === MYPROFILE ? "" : "/summary"}/${parentModule}/${parentModule === MYPROFILE ? "" : `${parentID}/`}${moduleName}`}
	/>;
};

export default SummaryTab;
