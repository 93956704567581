import React from "react";
import { DialogContentText, List, ListItem, ListItemText } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

interface Props {
	data: Record<string, any>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inline: {
			display: "inline",
		},
		dashletTitle: {
			fontStyle: "normal",
			fontWeight: "bold"
		},
		bulletinTitle: {
			"&:hover": {
				textDecoration: "none"
			},
			cursor: "pointer",
			color: "rgba(0, 0, 0, 0.87)"
		}
	})
);

const BulletinModal: React.FC<Props> = ({ data }: Props) => {
	const classes = useStyles();
	return <>
		<DialogContentText id="bulletin-dialog">
			<span dangerouslySetInnerHTML={{ __html: data.content }} />
		</DialogContentText>
		<List>
			{ data.attachment.map((attachment: any, index: number) => 
				<ListItem
					key={`list_item_${index}`}
					alignItems="flex-start"
				>
					<ListItemText primary={
						<a
							key={`list_item_link_${index}`}
							href={attachment.url}
							download
							target={"_blank"}
							rel={"noreferrer"}
							className={classNames(classes.inline, classes.dashletTitle, classes.bulletinTitle)}
						>
							{attachment.name.slice(-20)}
						</a>
					}
					/>
				</ListItem>
			)}
		</List>
	</>;
};

export default BulletinModal;
