import React, { useEffect, useState, useCallback, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, Menu, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import moment from "moment";
import { Creators } from "../../../actions/transientElementActions";
import { GLOBAL_SNACKBAR } from "../../../common/consts";
import { useDispatch } from "react-redux";
import styles from "../styles";
import CollapsibleSectionCellDisplay from "./CollapsibleSectionCellDisplay";
import useGraphServer from "../../../hooks/useGraphServer";

const ACCOUNTBENEFITSUMMARY_TYPE = "account_benefitSummary";

const HitType = ({ hitTypeTitle, setHitTypeModule, setHitTypeTitle }) => {
	const [anchorElement, setAnchorElement] = useState(null);

	const handleMenu = event => {
		setAnchorElement(event?.currentTarget || null);
	};

	const handleClose = useCallback((type, title) => {
		setAnchorElement(null);
		if (type) {
			setHitTypeModule(type);
			setHitTypeTitle(title);
		}
	});

	const switcherLabel = hitTypeTitle === "Referral" ? `${hitTypeTitle}s` : `${hitTypeTitle} Hits`;

	return (
		<React.Fragment>
			<Button onClick={handleMenu} className={"hitTypeSwitcher"} aria-owns="crmButton" aria-haspopup="true">
				<Typography variant="h6" className={"hitTypeSwitcherLabel"}>
					{switcherLabel}
				</Typography>
				<ExpandMoreIcon className="appBarProductSwitcherExpandMoreIcon" />
			</Button>
			<Menu
				id="menu-appbar"
				getContentAnchorEl={null}
				anchorEl={anchorElement}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				open={!!anchorElement}
				onClose={() => handleClose(null, null)}
				className="menu"
				disableAutoFocusItem
			>
				<MenuItem
					key={"HitMenuItemEventCalendarHits"}
					className={"hitTypeSwitcherMenuItem"}
					onClick={() => handleClose("eventCalendar", "Event Calendar")}>
					Event Calendar Hits
				</MenuItem>
				<MenuItem 
					key={"HitMenuItemListingHits"}
					className={"hitTypeSwitcherMenuItem"}
					onClick={() => handleClose("listing", "Listing")}>
					Listing Hits
				</MenuItem>
				<MenuItem
					key={"HitMenuItemOfferHits"}
					className={"hitTypeSwitcherMenuItem"}
					onClick={() => handleClose("offer", "Offer")}>
					Offer Hits
				</MenuItem>
				<MenuItem
					key={"HitMenuItemReferrals"}
					className={"hitTypeSwitcherMenuItem"}
					onClick={() => handleClose("referral", "Referral")}>
					Referrals
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
};

const BenefitSummaryDisplay = ({ classes, recId }) => {
	const dispatch = useDispatch();

	const [isLoading, setLoading] = useState(false);
	const [benefitSummary, setBenefitSummary] = useState({ Results: null });
	const [hitTypeModule, setHitTypeModule] = useState("eventCalendar");
	const [hitTypeTitle, setHitTypeTitle] = useState("Event Calendar");

	const [dateRange, setDateRange] = useState({
		StartMonth: new Date().getMonth() + 1,
		StartYear: new Date().getFullYear(),
		EndMonth: new Date().getMonth() + 1,
		EndYear: new Date().getFullYear()
	});

	const crm = useGraphServer();

	useEffect(() => {
		const pat = /[a-z]+/i;
		if(recId && !pat.test(recId)) {
			fetchData(recId)();
		}
	}, [recId, hitTypeModule]);

	const fetchData = id => () => {
		if (!id) {
			return;
		}
		setLoading(true);

		if(!dateRange) {
			setDateRange({
				StartMonth: new Date().getMonth() + 1,
				StartYear: new Date().getFullYear(),
				EndMonth: new Date().getMonth() + 1,
				EndYear: new Date().getFullYear()
			});
		}
		crm.general.get({
			type: "account_benefitSummary",
			filter: {
				recId: Number(id),
				module: hitTypeModule === "eventCalendar" ? "event" : hitTypeModule,
				...dateRange
			},
			fields: `
			RecordID
			Title
			HitType {
				HitTypeID
				HitType
				CounterValue
			}`
		}).then(res => {
			setBenefitSummary({ ...dateRange, Results: res.recs });
		}).finally(() => {
			setLoading(false);
		});

	};

	let data = [];

	let titleLink = `/summary/${hitTypeModule}/${"${RecordID}"}`;
	const noDataMessage = (hitTypeTitle === "Referral") 
		? "There are no referrals for this period."
		: `No Website ${hitTypeTitle}s have been set up for this Account`;
	const nowMonth = new Date().getMonth() + 1;
	const nowYear = new Date().getFullYear();
	const months = moment.months();
	const years = Array.from(new Array(Number(20)), (_, z) => nowYear - z);

	//Conform the results to the standard data set used by the shared ExpansionPanels.
	if (benefitSummary.Results && benefitSummary.Results.length) {
		if (hitTypeTitle === "Referral") {
			
			data = [{
				RecordID: "",
				Title: "Referrals",
				CellHeader: ["Listings", "Total number of Referrals"],
				CellData: benefitSummary.Results.map(result => {
					const value =
					result.HitType && result.HitType.filter((hitType) => hitType.HitType === "Referral").length
						? result.HitType.filter((hitType) => hitType.HitType === "Referral")[0].CounterValue
						: 0;

					return {
						ID: 1,
						Title: result.Title,
						TitleLink: titleLink.replace(`/${hitTypeModule}/`, "/listing/").replace("${RecordID}", result.RecordID),
						Value: value
					};
				})
			}];

			titleLink = "";
		} else {
			data = benefitSummary.Results.map(record => {
				return {
					RecordID: record.RecordID,
					Title: record.Title,
					CellData: record.HitType.map(hitType => {
						return {
							ID: hitType.HitTypeID,
							Title: hitType.HitType,
							Value: hitType.CounterValue
						};
					})
				};
			});
		}
	}
	return (
		<React.Fragment>
			<HitType hitTypeTitle={hitTypeTitle} setHitTypeTitle={setHitTypeTitle} setHitTypeModule={setHitTypeModule} />
			<div className={"hitTypeInputs"}>
				<Typography variant="h6">Start Date:</Typography>
				<Select className={classNames(classes.selectUnderline, "CyGridBulkActionsSelect")}
					classes={{ icon: classes.actionIcon }}
					onChange={e =>
						setDateRange({
							StartMonth: e.target.value,
							StartYear: dateRange.StartYear,
							EndMonth: dateRange.EndMonth,
							EndYear: dateRange.EndYear
						})
					}
					value={dateRange.StartMonth || nowMonth}
					displayEmpty>
					{months.map((month, index) => (
						<MenuItem key={`hitStartMonth${month}`} value={index + 1}>
							{month}
						</MenuItem>
					))}
				</Select>

				<Select className={classNames(classes.selectUnderline, "CyGridBulkActionsSelect")}
					classes={{ icon: classes.actionIcon }}
					onChange={e =>
						setDateRange({
							StartMonth: dateRange.StartMonth,
							StartYear: e.target.value,
							EndMonth: dateRange.EndMonth,
							EndYear: dateRange.EndYear
						})
					}
					value={dateRange.StartYear ? dateRange.StartYear : nowYear}
					displayEmpty>
					{years.map(year => (
						<MenuItem key={`hitStartYear${year}`} value={year}>
							{year}
						</MenuItem>
					))}
				</Select>

				<Typography variant="h6">End Date:</Typography>
				<Select className={classNames(classes.selectUnderline, "CyGridBulkActionsSelect")}
					classes={{ icon: classes.actionIcon }}
					onChange={e =>
						setDateRange({
							StartMonth: dateRange.StartMonth,
							StartYear: dateRange.StartYear,
							EndMonth: e.target.value,
							EndYear: dateRange.EndYear
						})
					}
					value={dateRange.EndMonth ? dateRange.EndMonth : nowMonth}
					displayEmpty>
					{months.map((month, index) => (
						<MenuItem key={`hitEndMonth${month}`} value={index + 1}>
							{month}
						</MenuItem>
					))}
				</Select>

				<Select className={classNames(classes.selectUnderline, "CyGridBulkActionsSelect")}
					classes={{ icon: classes.actionIcon }}
					onChange={e =>
						setDateRange({
							StartMonth: dateRange.StartMonth,
							StartYear: dateRange.StartYear,
							EndMonth: dateRange.EndMonth,
							EndYear: e.target.value
						})
					}
					value={dateRange.EndYear ? dateRange.EndYear : nowYear}
					displayEmpty>
					{years.map(year => (
						<MenuItem key={`hitEndYear${year}`} value={year}>
							{year}
						</MenuItem>
					))}
				</Select>

				<Button variant="contained"
					color="primary"
					onClick={() => {
						const startDate = new Date(dateRange.StartYear, dateRange.StartMonth-1, 1);
						const endDate = new Date(dateRange.EndYear, dateRange.EndMonth-1, 1);

						if ((endDate.getTime() - startDate.getTime()) < 0) {
							dispatch(Creators.setDrawerOpen(GLOBAL_SNACKBAR, {
								isOpen: true,
								message: "Start Date must precede End Date",
								messageType: "error"
							}));
						} else {
							fetchData(recId)(dateRange.StartMonth, dateRange.StartYear, dateRange.EndMonth, dateRange.EndYear);
						}
					}
					}>Refresh</Button>
			</div>
			<CollapsibleSectionCellDisplay
				recId={recId}
				type={ACCOUNTBENEFITSUMMARY_TYPE}
				isLoading={isLoading}
				data={data}
				classes={classes}
				noDataMessage={noDataMessage}
				titleLink={titleLink}
			/>
			
		</React.Fragment>
	);
};

export default withStyles(styles)(BenefitSummaryDisplay);
