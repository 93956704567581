const styles = theme => ({
	calendarWrapper: {
		padding: theme.spacing(2),
		"& .fc-event": {
			cursor: "default"
		}
	}
});

export default styles;
