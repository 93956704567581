import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Creators as HeaderCreators, LEFT_NAV, LEFT_NAV_TOGGLE } from "../../actions/headerActions";
import { Creators as TransientCreators } from "../../actions/transientElementActions";
import { Creators as recordCreators } from "../../actions/recordActions";
import { getModuleIcon } from "../../common/moduleData";
import { LeftNav as LeftNavMosaic } from "@simpleview/sv-mosaic";
import { debounce } from "lodash";
import FormFrame from "../Form/FormFrame";
import { GLOBAL_TABBED_DRAWER } from "../../common/consts";
import {
	Props,
	Variant,
	onNavArgs,
	LeftNavState
} from "./typeDefs";

const isMobile = (): boolean => {
	return window.innerWidth < 1218;
};

const LeftNav: React.FC<Props> = (props) => {
	const { 
		leftNavVariant, 
		leftNavToggle, 
		links, 
		leftNavFetch, 
		logUpdate,
		openNav, 
		setActiveNav, 
		updateGeneralSettings,
		setDrawerOpen 
	} = props;
	const history = useHistory();
	const location = useLocation();

	const [state, setState] = useState<LeftNavState>({
		open: false,
		variant: ["hidden", "icons_only"].includes(leftNavVariant) ? leftNavVariant : "full",
		label: "Home",
		name: "home",
		openNav: openNav
	});

	const variant = isMobile() ? "mobile" : state.variant;
	
	const onClose = (): void=> {
		setState({
			...state,
			open: false
		});
	};

	const onNav = ({ item }: onNavArgs): void => {
		if (item.link) {
			history.push(item.link);
		}

		setState({
			...state,
			open: false,
			label: item.label,
			name: item.name
		});
	};

	const onVariantChange = (variant: Variant): void => {
		setState({
			...state,
			variant,
			open: false
		});
	};

	const customOnNav = (type: string, name: string): void => {
		const formdef = [{ name, type }];
		const displayComponent = (
			<FormFrame
				type={formdef[0].type}
				action={"add"}
				recId={0}
				afterSubmit={(): void => {
					logUpdate("inquiry");
					setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
				}}
			/>
		);
		setDrawerOpen(GLOBAL_TABBED_DRAWER, {
			isOpen: true,
			type: formdef[0].type,
			id: 0,
			action: "add",
			tabs: formdef,
			activeTab: formdef[0].name,
			displayComponent
		});	
	};

	const findFormLink = (link: Record<string, any>): void => {
		if (link.formlink) {
			link.onNav = (): void => {
				customOnNav(link.formlink, link.label);
			};
		} else if (link.items) {
			link.items.forEach((linkItem: Record<string, any>) => {
				findFormLink(linkItem);
			});
		}
	};

	links.forEach(link => {
		link.mIcon = getModuleIcon(link.label, link.icon);
	});

	useEffect(() => {
		links.forEach(link => {
			findFormLink(link);
		});
	}, [links]);	

	useEffect(() => {
		updateGeneralSettings(LEFT_NAV, variant);
	}, [variant]);
	
	useEffect(() => {
		leftNavFetch();
	}, []);
	
	useEffect(() => {
		setState({
			...state,
			open: true
		});
	}, [leftNavToggle]);
	
	useEffect(() => {
		let navLocation = location.pathname.replace(/\//gi, ".").replace(".", "");
		//summary logic
		if (navLocation.indexOf("summary") > -1) {
			const regexMatch = navLocation.match(/[^\.]*\.[^\.]*/);
			navLocation = regexMatch ? regexMatch[0].replace("summary", "grid") : "";
		}
		setActiveNav(navLocation || "home");
	}, [location]);
	
	useEffect(() => {
		setState({
			...state,
			openNav
		});
	}, [openNav]);
	
	useEffect(() => {
		const resizeHandler = debounce(function() {
			const shouldBeMobile = isMobile();

			// if we are in mobile, ensure we are, if we aren't mobile, ensure we aren't
			// triggers a re-render just by calling setState()
			if ((shouldBeMobile && variant !== "mobile") || (!shouldBeMobile && variant === "mobile")) {
				setState({
					...state
				});
			}
		}, 100);

		window.addEventListener("resize", resizeHandler);

		return (): any => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, [state, variant]);
	return (
		<LeftNavMosaic
			variant={variant}
			open={state.open}
			items={links}
			active={state.openNav}
			onClose={onClose}
			onNav={onNav}
			onVariantChange={onVariantChange}
		/>
	);
};

const mapStateToProps = (state: { header: { [name: string]: any; leftNavLinks: any }; transientElements: { openNav: any } }): any => ({
	leftNavVariant: state.header[LEFT_NAV],
	leftNavToggle: state.header[LEFT_NAV_TOGGLE],
	links: state.header.leftNavLinks,
	openNav: state.transientElements.openNav
});

const mapDispatchToProps = {
	leftNavFetch: HeaderCreators.leftNavFetch,
	setActiveNav: TransientCreators.setActiveNav,
	updateGeneralSettings: HeaderCreators.updateGeneralSettings,
	setDrawerOpen: TransientCreators.setDrawerOpen,
	logUpdate: recordCreators.logUpdate,
};


export default 	connect(
	mapStateToProps,
	mapDispatchToProps
)(LeftNav);
