import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button, Typography, IconButton, Toolbar, AppBar, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ExpandMore, Menu as MenuIcon } from "@material-ui/icons";
import { Creators as HeaderCreators, LEFT_NAV, LEFT_NAV_TOGGLE } from "../../actions/headerActions";
import classNames from "classnames";
import UserSettings from "./UserSettings/index";
import AddNew from "./AddNew/index";
import RecentlyViewed from "./RecentlyViewed/index";
import styles from "./styles";
import logo from "../../assets/images/logo.png";
import SearchBox from "./SearchBox";

const Header = ({
	classes,
	leftNavVariant,
	loginErr,
	acctLabel,
	profileSettings,
	productName,
	updateGeneralSettingSuccess,
	leftNavToggle,
	subdomain,
	user,
	fetchZendeskWebToken
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenu = useCallback(event => {
		setAnchorEl(event.currentTarget);
	});

	const handleClose = useCallback(item => {
		if (item.href) {
			window.open(item.href.replace("$subdomain$", subdomain));
		}
		setAnchorEl(null);
	}, [subdomain]);

	const navToggle = useCallback(() => {
		updateGeneralSettingSuccess(LEFT_NAV_TOGGLE, !leftNavToggle);
	}, [updateGeneralSettingSuccess, leftNavToggle, LEFT_NAV_TOGGLE]);

	const products = profileSettings ? profileSettings.products.filter(product => product.name !== productName) : [];

	const getProductSwitcherMenuItems = useCallback(() => {
		if (products) {
			return products.map((item, idx) =>
				<MenuItem key={`ProductMenuItem${idx}`} className={classes.productSwitcherMenuItem} onClick={() => handleClose(item)}>
					{item.label === "Extranet" ? "Portal" : item.label}
				</MenuItem>
			);
		}
		return null;
	}, [products, handleClose]);

	useEffect(() => {
		if (productName === "dms" && !document.getElementById("ze-snippet")) {
			window.fetchZendeskWebToken = () => fetchZendeskWebToken(user);
			const script1 = document.createElement("script");
			script1.type = "text/javascript";
			script1.id = "ze-snippet-helper";
			script1.innerHTML = `
				window.zESettings = {
					webWidget: {
						authenticate: {
							jwtFn: function(callback) {
								window.fetchZendeskWebToken().then(res => {
									callback(res.success ? res.recs[0].token : "");
								});
							}
						}
					}
				}
			`;
			const appRoot = document.getElementById("app-root");
			appRoot.parentNode.appendChild(script1);

			const script2 = document.createElement("script");
			script2.type = "text/javascript";
			script2.id = "ze-snippet";
			script2.src = "https://static.zdassets.com/ekr/snippet.js?key=7c95bb92-7d3f-41b0-99db-c6d1e4790a03";
			appRoot.parentNode.appendChild(script2);
		}
	}, [productName]);

	const open = Boolean(anchorEl);
	const productSwitcher = (products.length) ? (
		<>
			<Button onClick={handleMenu} className={classes.productSwitcher} aria-owns="crmButton" aria-haspopup="true">
				<Typography variant="h6" className={classes.appBarProductSwitcherLabel}>
					{productName === "dms" ? "Simpleview CRM Apex" : "Portal"}
				</Typography>
				<ExpandMore className="appBarProductSwitcherExpandMoreIcon" />
			</Button>

			<Menu
				id="menu-appbar"
				getContentAnchorEl={null}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				open={open}
				onClose={handleClose}
				className="menu"
				disableAutoFocusItem
			>
				{getProductSwitcherMenuItems()}
			</Menu>
		</>
	) : (
		<Typography variant="h6" className={classes.appBarProductSwitcherLabelNoProducts}>
			{productName === "dms" ? "Simpleview CRM Apex" : "Portal"}
		</Typography>
	);

	return <AppBar className={classNames(classes.appBar)}>
		<Toolbar className="appBarToolbar" disableGutters>
			{
				["mobile", "hidden"].includes(leftNavVariant) &&
				<IconButton className={classes.appBarLeftNavToggler} color="inherit" aria-label="Open Avatar Options" onClick={() => !loginErr && navToggle(LEFT_NAV, leftNavVariant)}>
					<MenuIcon classes={{ root: classes.menuButtonIconOpen }} />
				</IconButton>
			}
			<img src={logo} alt="logo" className={classNames(classes.appLogo, "app-logo")} />

			{productSwitcher}

			<Typography variant="subtitle1" className={classes.appBarTitle}>
				{acctLabel}
			</Typography>

			<div className={classes.grow} />
			{!loginErr &&
				<>
					{productName === "dms" &&
						<SearchBox/>
					}
					<div className={classes.menuIcon}>
						<AddNew className={"CyHeaderAddNewIcon"}/>
					</div>
					<div className={classes.menuIcon}>
						<RecentlyViewed className={"CyHeaderRecentlyViewedIcon"}/>
					</div>
				</>
			}
			<div className={classes.menuIcon}>
				<UserSettings loginErr={loginErr} className={"CyHeaderUserSettingsIcon"} />
			</div>
		</Toolbar>
	</AppBar>;
};

const mapStateToProps = state => ({
	acctLabel: state.login.acctLabel,
	loginErr: state.login.loginErr,
	subdomain: state.login.subdomain,
	productName: state.login.productName,
	leftNavVariant: state.header[LEFT_NAV],
	leftNavToggle: state.header[LEFT_NAV_TOGGLE],
	profileSettings: state.header.profileSettings,
	user: {
		firstName: state.login.firstName,
		lastName: state.login.lastName,
		email: state.login.email,
		userId: state.login.userId,
	}
});

const mapDispatchToProps = {
	updateGeneralSettingSuccess: HeaderCreators.updateGeneralSettingSuccess,
	fetchZendeskWebToken: HeaderCreators.fetchZendeskWebToken
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Header));
