const styles = theme => ({
	summaryPageWrapper: {
		height: "100%",
		flex: 1,
		padding: theme.spacing(3)
	},
	summaryContentWrapper: {
		margin: theme.spacing(3),
		marginLeft: theme.spacing(3.5),
		marginRight: theme.spacing(2.5),
		flex: "1",
		width: 100,
		"& .MuiGrid-container": {
			flexWrap: "nowrap"
		},
		"& .hitTypeInputs": {
			display: "flex",
			flexWrap: "nowrap",
			margin: "5px 0",
			"& h6": {
				margin: "4px 6px"
			}
		},
		"& .hitTypeSwitcher": {
			color: theme.colors.shadow,
			margin: "0 6px",
			padding: 0,
			"&:hover": {
				color: theme.colors.shadow
			}
		},
		"& .hitTypeMenuItem": {
			backgroundColor: "#fff",
			"&:hover": {
				backgroundColor: theme.palette.secondary.main
			}
		},
	},
	summaryTabsPaper: {
		marginBottom: theme.spacing(1)
	},
	summaryTabs: {},
	miniPanelWrapper: {
		flex: "0"
	},
	editAmenities: {
		position: "relative",
		top: -5
	},
	section: {
		marginBottom: 28
	},
	sectionHeader: {
		backgroundColor: theme.colors.secondaryGray
	},
	gridContainer: {
		flex: 1,
		flexWrap: "wrap",
		margin: 0,
		padding: 0
	},
	tableCell: {
		flex: "1 1 50%",
		flexDirection: "row",
		display: "inline-flex",
		margin: 0,
		padding: 0
	},
	tableTitleCell: {
		flexDirection: "column",
		flex: "1 1 50%",
		fontWeight: "bold",
		paddingLeft: 15,
		paddingTop: 15,
		paddingBottom: 15
	},
	tableHeaderCell: {
		flex: "1 1 100%"
	},
	tableCellBorder: {
		border: "1px solid #CCC",
	},
	tableCellBlank: {
		backgroundColor: "#EEE"
	},
	tableValueCell: {
		flexDirection: "column",
		flex: "1 1 50%",
		alignContent: "flex-end",
		paddingLeft: 15,
		paddingTop: 15,
		paddingBottom: 15,
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			color: theme.colors.blueHover
		}
	},
	noAmenities: {
		width: "100%",
		textAlign: "center",
		marginTop: 25
	},
	editSpan: {
		"&:hover": {
			cursor: "pointer"
		}
	},
	roomGridDetail: {
		padding: "0 !important"

	},
	roomGrid: {
		width: "100%",
		margin: 0
	},
	selectUnderline: {
		minWidth: "105px",
		marginRight: "10px"
	},
	titleLink: {
		textDecoration: "none",
		color: "#0070F2"
	},
	summaryModuleDetails: {
		display: "flex",
    	flexDirection: "row",
		"& li": {
			width: "inherit",
			padding: 0
		},
		"& li div": {
			minWidth: "inherit",
			paddingRight: 10
		}
	},
	moduleDetails: {
		display: "inherit",
		paddingLeft: 24,
		"& li": {
			padding: 2,
			color: theme.colors.darkGray
		}
	},
	label: {
		fontWeight: "bold",
		marginRight: 5
	},
	summaryModuleTitle: {
		display: "inline",
		marginRight: 10
	},
	summaryModuleActions: {
		display: "inline-flex"
	},
	noRecords: {
		marginBottom: 10
	},
	expandableActionButton: {
		padding: 2,
		minWidth: 0
	},
	tasks: {
		display: "block",
		"& li": {
			display: "block",
			padding: 0
		},
		"& p": {
			display: "inline-block"
		}
	},
	alignRight: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		float: "right"
	},
	lastDetail: {
		marginBottom: 10
	},
	taskCompleted: {
		color: "green",
		fontWeight: "bold"
	},
	taskOverdue: {
		color: "red",
		fontWeight: "bold"
	}
});

export default styles;
