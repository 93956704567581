import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";
import CollapsibleSectionCellDisplay from "./CollapsibleSectionCellDisplay";
import useGraphServer from "../../../hooks/useGraphServer";
import { constant } from "lodash";

const AMENITY_TYPE = "listing_amenity";

const Amenity = ({ classes, recId, module }) => {
	const noDataMessage = "No amenities were found for this record";
	const tabs = [];

	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [formDef, setFormDef] = useState([]);

	const crm = useGraphServer();

	const fetchData = id => () => {
		if (isLoading === true) {
			return;
		}
		// TODO: update to only use RecordID
		// so we're not doing this funny business
		if (/[^\d]/.test(id) === true) {
			return;
		}
		setLoading(true);

		// TODO: the account portion will get removed
		if (module === "account") {
			crm.general.get({
				type: "account_amenity",
				filter: {
					recId: Number(id)
				},
				fields: "AmenityCategoryID TenantAmenityCategoryID Category AmenityValue AmenityTitle AmenityID",
				formDef: `
					actions {
						callback
					}`
			}).then(res => {
				setFormDef(res.formDef);
				
				if (res.recs.length) {
					const cats = res.recs.reduce((acc, curr) => {
						const CategoryID = curr.TenantAmenityCategoryID || curr.AmenityCategoryID;
						const index = acc.findIndex(rec => rec.RecordID === CategoryID);
						const cellData = {
							ID: curr.AmenityID,
							Title: curr.AmenityTitle,
							Value: curr.AmenityValue
						};
						if (index === -1) {
							acc.push({
								RecordID: CategoryID,
								Title: curr.Category,
								CellData: [cellData]
							});
						} else {
							const listIndex = acc[index].CellData.findIndex(rec => rec.Title === curr.AmenityTitle);
							if (listIndex === -1) {
								acc[index].CellData.push(cellData);
							} else {
								acc[index].CellData[listIndex].Value += `, ${curr.AmenityValue}`;
							}
						}
			
						return acc;
					}, []);
			
					setData(cats);
				}

			}).finally(() => {
				setLoading(false);
			});
		} else {
			crm.listing.listings_v2(
				`
					success
					count
					formDef {
						recs {
							actions {
								callback
							}
						}
					}
					recs {
						RecordID
						Amenities {
							RecordID
							Title
							Value
							Category {
								RecordID
								Title
								IsActive
							}
							IsActive
						}
					}
				`,
				{
					ListingID: id
				},
				{
					limit: 1,
					page: 1
				}
			).then(res => {
				setFormDef(res.formDef);

				if (res.success && res.count) {
					const catIndex = {};
					// only valid amenities
					res.recs[0].Amenities = res.recs[0].Amenities.filter(rec => {
						return rec.Category.IsActive && rec.IsActive;
					});

					res.recs[0].Amenities.forEach(rec => {
						if (catIndex[rec.Category.RecordID] === undefined) {
							catIndex[rec.Category.RecordID] = {
								RecordID: rec.Category.RecordID,
								Title: rec.Category.Title,
								CellData: []
							};
						}

						catIndex[rec.Category.RecordID].CellData.push({
							ID: rec.RecordID,
							Title: rec.Title,
							Value: rec.Value
						});
					});

					const cats = [];
					for (const cat in catIndex) {
						cats.push(catIndex[cat]);
					}

					setData(cats);
				} else {
					setData([]);
				}
			}).finally(() => {
				setLoading(false);
			});
		}
	};

	if (!Array.isArray(formDef) && formDef?.recs[0].actions.some(action => action.callback === "submit")) {
		tabs.push({ name: "Edit Amenities" });
	}

	return (
		<CollapsibleSectionCellDisplay
			recId={recId}
			type={AMENITY_TYPE}
			tabs={tabs}
			data={data}
			classes={classes}
			fetchData={fetchData(recId)}
			noDataMessage={noDataMessage}
			isLoading={isLoading}
		/>
	);

};

export default withStyles(styles)(Amenity);
