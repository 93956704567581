import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { 
	Button,
	Grid,
	Typography
} from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import FormFrame from "../../Form/FormFrame";
import { Creators } from "../../../actions/dashboardActions";
import { Creators as TransCreators } from "../../../actions/transientElementActions";
import { GLOBAL_TABBED_DRAWER } from "../../../common/consts";
import CircularProgressCentered from "../../CircularProgressCentered";
import { Link } from "react-router-dom";
import { formatDatetime } from "@react-ui/common/format";
import styles from "./styles";

const Tasks = ({ 
	fetchTasks,
	setDrawerOpen,
	boardClasses,
	classes,
	type = "task",
	action = "edit",
	tab = [{ name: "Edit Task" }]
}) => {
	const [data, setData] = useState([]);
	const [showCirlceSpinner, setShowCirlceSpinner] = useState(true);

	const fetchData = async () => {
		setShowCirlceSpinner(true);
		const { recs } = await fetchTasks();
		setData(recs);
		setShowCirlceSpinner(false);
	};

	const openTask = useCallback(
		taskId => () => {
			const displayComponent = (
				<FormFrame
					type={type}
					action={action}
					recId={taskId}
					afterSubmit={() => {
						setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
						fetchData();
					}}
				/>
			);
			setDrawerOpen(GLOBAL_TABBED_DRAWER, {
				isOpen: true,
				type,
				id: taskId,
				action,
				tabs: tab,
				activeTab: tab[0].name,
				displayComponent
			});
		},
		[setDrawerOpen, fetchTasks]
	);

	useEffect(() => {
		fetchData();
	}, [fetchTasks]);

	const tasks = data.slice(0, Math.min(data.length, 10));
	const unionedClasses = { ...boardClasses, ...classes };

	return <div>
		{ !showCirlceSpinner ?
			<React.Fragment>
				<span className={classes.accountTitleSpan}>
					<Typography variant="h2" className={classes.dashletTitle}>
						My Tasks
					</Typography>
				</span>
				<span className={classNames(classes.accountTitleSpan, classes.accountTitleSpanRight)}>
					<Link className={classes.seeAllLink} to={"/myProfile/task"}> See All </Link>
				</span>
			</React.Fragment>
			: null
		}
		{	showCirlceSpinner ?
			<CircularProgressCentered size={75} color="primary" className={unionedClasses.loader} />
			: null
		}
		{ !showCirlceSpinner && data.length ?
			<Grid container spacing={1} className={unionedClasses.unmarginedGrid}>
				<Grid container item xs={12} spacing={1} className={unionedClasses.headerRow}>
					<Grid item xs={3}>
						<Typography variant={"body2"}> Task Name </Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant={"body2"}> Task Type </Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant={"body2"}> Priority </Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant={"body2"}> Due Date </Typography>
					</Grid>
				</Grid>
			</Grid>
			: !showCirlceSpinner ?
				<Typography variant="body1" className={unionedClasses.marginedBody}>
					You currently have 0 tasks to complete.
				</Typography>
				: null
		}
		{tasks.map((task, index) => {
			return <Grid container spacing={1} key={`task_dashlet_${index}`} className={unionedClasses.unmarginedGrid}>
				<Grid container item xs={12} spacing={1}>
					<Grid item xs={3}>
						<Button disableRipple color="primary" size="small" onClick={openTask(task.TaskID)} className={classNames(unionedClasses.linkButton, unionedClasses.title)}>
							<Typography> {task.Title} </Typography>
						</Button>
					</Grid>
					<Grid item xs={3}>
						<Typography> {task.TaskType} </Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography> {task.Priority} </Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography className={new Date(task.DueDate) < new Date() ? unionedClasses.pastDue : ""}> 
							{formatDatetime(task.DueDate)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>;
		})}
	</div>;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
	fetchTasks: Creators.fetchTasks,
	setDrawerOpen: TransCreators.setDrawerOpen
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Tasks));
