
/**
 * This convert a file into sv-dam friendly
 * @param file 
 */
export const convertFileToFileObject = async (file: File): Promise<any> => {
	if ( typeof file !== "object" ) {
		return new Error("{File} is not an object.");
	}
	if ( file.size === undefined || file.name === undefined ) {
		return new Error("{File} is not a valid file.");
	}

	const reader = new FileReader();
	const returnObject: {
		name: string;
		length: string;
		dataFormat: string;
		data: any[];
	} = {
		name: file.name,
		length: file.size.toString(),
		dataFormat: "base64",
		data: []
	};

	return new Promise((resolve, reject) => {
		reader.onerror = (): void => {
			reader.abort();
			reject(new Error("Unable to parse file data."));
		};

		reader.onload = (): void => {
			returnObject.data[0] = reader.result;
			resolve(returnObject);
		};

		reader.readAsDataURL(file);
	});
};
