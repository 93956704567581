import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";
import { useState } from "react";

/**
 * return an object, where key and value are parsed from url query params.
 * @param {Object} input - kwargs
 * @param {Function} onChange - the function that will get called with (curValue, lastValue) when location.search changes.
 * @param {Boolean} [input.clearSearch=false] whether to clear the search after the initial call.
 * 
 * @return {Object[]} an array of two objects, the first element contains the current search, the second element contains the last search. If it's not yet set or the result is empty, the value will be set to NULL.
 */
const useLocationSearch = ({ clearSearch, onChange }) => {
	const [lastValue, setLastValue] = useState(null);
	const [curValue, setCurValue] = useState(null);
	const history = useHistory();
	const location = useLocation();
	useEffect(() => {
		let searchValues = queryString.parse(location.search);
		if (Object.keys(searchValues).length === 0) {
			searchValues = null;
		}
		setLastValue(curValue);
		setCurValue(searchValues);
		if (clearSearch) {
			history.replace({
				...location,
				search: ""
			});
		}
		if (onChange) {
			onChange(searchValues, curValue);
		}
	}, [location.search]);
	return [curValue, lastValue];
};

export default useLocationSearch;
