const styles = theme => ({
	accountTitleSpan: {
		display: "inline-flex",
		width: "50%",
		borderBottom: "1px #F0F2F5 solid",
		paddingBottom: "5px"
	},
	accountTitleSpanRight: {
		justifyContent: "flex-end"
	},
	seeAllLink: {
		marginRight: 30,
		color: theme.colors.blue,
		textDecoration: "none"
	},
	accountNoAccounts: {
		margin: "10px"
	},
	ListIcon: {
		padding: theme.spacing(1),
		color: theme.colors.blueHover,
		minWidth: 30
	},
	pastDue: {
		color: theme.colors.exterminateRed
	},
	headerRow: {
		backgroundColor: "#F0F2F5",
		margin: 0,
		borderBottom: "1px #F0F2F5 solid"
	},
	unmarginedGrid: {
		margin: 0,
		borderBottom: "1px #F0F2F5 solid"
	},
	topItems: {
		margin: 0
	},
	seeAllGridItem: {
		textAlign: "right",
		paddingTop: theme.spacing(1.5)
	},
	title: {
		justifyContent: "left",
		textAlign: "left"
	},
	loader: {
		margin: "0 auto"
	},
	marginedBody: {
		margin: 10
	}
});

export default styles;
