import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import AttachFile from "@material-ui/icons/AttachFile";
import DialogContentText from "@material-ui/core/DialogContentText";
import Link from "@material-ui/core/Link";
import { Creators } from "../../../actions/dashboardActions";
import GridModal from "../../Modal";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment';
import useGraphServer from "../../../hooks/useGraphServer";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			paddingBottom: "16px"
		},
		inline: {
			display: "inline",
		},
		item: {
			padding: "10px !important"
		},
		evenBg: {
			backgroundColor: "#EAF3FC"
		},
		divider: {
			padding: 1
		},
		dashletTitle: {
			fontStyle: "normal",
			fontWeight: "bold"
		},
		bulletinTitle: {
			"&:hover": {
				textDecoration: "none"
			},
			cursor: "pointer",
			color: "rgba(0, 0, 0, 0.87)"
		},
		body: {
			fontStyle: "normal",
			fontWeight: "normal"
		},
		important: {
			display: "inline",
			color: "#0070F2",
			fontSize: "1rem",
			verticalAlign: "-2px"
		},
		seeAllItem: {
			textAlign: "right",
			paddingTop: "15px"
		},
		seeAllLink: {
			textDecoration: "none",
			"&:hover": {
				textDecoration: "none"
			},
			cursor: "pointer",
		},
		extraSmallIcon: {
			fontSize: "1rem"
		}
	})
);

interface DialogState {
	title: string;
	content: string;
	attachment: any;
	BulletinID: number;
	recId: number;
}

interface Item {
	IsImportant: boolean;
	Title: string;
	Bulletin: string;
	PublishStartDt: string;
	LastModifiedDt: string;
	ShortBulletin: string;
	HasAttachment: boolean;
	Attachment: any;
	recId: number;
	BulletinID: number;
}

const Bulletin = ({ data = [], fetchBulletin }: { data: any; fetchBulletin: any }): any => {
	const crmGraphServer = useGraphServer();
	const [dialogInfo, setDialogInfo] = useState<DialogState>({
		title: "",
		content: "",
		attachment: [],
		BulletinID: 0,
		recId: 0
	});
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const classes = useStyles();

	const handleClickOpen = (title: string, content: string, attachment: any, BulletinID: number, recId: number) => (event: SyntheticEvent): void => {
		event.preventDefault();
		setDialogInfo({
			title,
			content,
			attachment,
			BulletinID,
			recId
		});
		setDialogOpen(true);
	};

	const handleClose = (): void => {
		setDialogOpen(false);
	};

	const dialogContentRef = useRef<HTMLElement>(null);
	useEffect(() => {
		if (dialogOpen) {
			const { current: dialogContent } = dialogContentRef;
			if (dialogContent !== null) {
				dialogContent.focus();
			}
			// track its been read
			const asyncFunc = async () => {
				await crmGraphServer.general.set({
					type: "track_bulletin", 
					input: {BulletinID: dialogInfo.BulletinID, recId: dialogInfo.recId},
					fields: `
						success
					`
				});
			};
			asyncFunc();
		}
	}, [dialogOpen]);
	
	useEffect(() => {
		fetchBulletin();
	}, []);

	return <React.Fragment>
		<Grid container>
			<Grid xs={3} item>
				<Typography variant="h2" className={classes.dashletTitle}>
					Bulletins
				</Typography>
			</Grid>
			<Grid xs={6} item></Grid>
			<Grid xs={3} item className={classes.seeAllItem}>
				<RouterLink to={"/grid/bulletin"} className={classNames(classes.inline, classes.seeAllLink)}>
					See All
				</RouterLink>
			</Grid>
		</Grid>
		<List className={classes.root}>
			{data.map((item: Item, index: number) => {
				return <React.Fragment key={`list_item_${index}`}>
					<ListItem
						key={`list_item_${index}`}
						className={classNames(classes.item, index % 2 === 0 ? classes.evenBg : undefined)}
						alignItems="flex-start"
					>
						<ListItemText
							key={`list_item_text_${index}`}
							primary={
								<>
									<Link
										key={`list_item_link_${index}`}
										component="span"
										variant="subtitle1"
										className={classNames(classes.inline, classes.dashletTitle, classes.bulletinTitle)}
										onClick={handleClickOpen(item.Title, item.Bulletin, item.Attachment.recs, item.BulletinID, item.recId)}
									>
										{`    ${item.Title}    `}
									</Link>
									<Typography
										key={`list_date_${index}`}
										component="span"
										variant="body2"
										className={classNames(classes.inline, classes.body)}
									>
										{item.PublishStartDt ? moment.utc(new Date(item.PublishStartDt)).format("MM/DD/YYYY") : new Date(item.LastModifiedDt).toLocaleDateString()}
										{
											item.IsImportant ? 
												<Tooltip title={"Important"}>
													<ErrorIcon key={`list_important_item_${index}`} className={classes.important} />
												</Tooltip> : null
										}
										{
											item.HasAttachment ?
												<Tooltip title={"There is an attachment in this bulletin"}>
													<AttachFile className={classes.extraSmallIcon} /> 
												</Tooltip> : null
										}
									</Typography>
								</>
							}
							secondary={<Typography
								key={`short_${index}`}
								variant="body2"
								className={classes.body}
							>
								{item.ShortBulletin}
							</Typography>}
						/>
					</ListItem>
					<Divider key={`divider_${index}`} />
				</React.Fragment>;
			})}
		</List>
		<GridModal handleClose={handleClose} isOpen={dialogOpen} title={dialogInfo.title} maxWidth={"sm"} useHandleBackdrop={false}>
			<DialogContentText
				id="bulletin-dialog"
				ref={dialogContentRef}
				tabIndex={-1}
			>
				<span dangerouslySetInnerHTML={{ __html: dialogInfo.content }} />
			</DialogContentText>
			<List>
				{ dialogInfo.attachment.map((attachment: any, index: number) => 
					<ListItem
						key={`list_item_${index}`}
						alignItems="flex-start"
					>
						<ListItemText primary={
							<a
								key={`list_item_link_${index}`}
								href={attachment.url}
								download
								target={"_blank"}
								rel={"noreferrer"}
								className={classNames(classes.inline, classes.dashletTitle, classes.bulletinTitle)}
							>
								{attachment.name.slice(-20)}
							</a>
						}
						/>
					</ListItem>
				)}
			</List>
		</GridModal>
	</React.Fragment>;
};

const mapStateToProps = (state: { dashboard: { data: any } }): Record<string, Item> => ({
	data: state?.dashboard?.data.bulletin
});

const mapDispatchToProps = {
	fetchBulletin: Creators.fetchBulletin
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Bulletin);
