import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { DragSource as dragSource, DropTarget as dropTarget } from "react-dnd";
import { Typography } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const Types = {
	ITEM: "reorderedTabs"
};

const itemSource = {
	beginDrag: props => ({
		text: props.text,
		id: props.id,
		index: props.findTab(props.id).index
	}),
	endDrag(props, monitor, component) {
		const { id, index } = monitor.getItem();
		const didDrop = monitor.didDrop();

		if (!didDrop) {
			return props.moveTab({ id, index });
		}
	}
};

const itemTarget = {
	canDrop() {
		return false;
	},

	hover(props, monitor) {
		const { id: draggedId } = monitor.getItem();
		const { id: overId } = props;

		if (draggedId !== overId) {
			const { index: overIndex } = props.findTab(overId);

			props.moveTab(draggedId, overIndex);
		}
	}
};

const ReorderableNavTabs = React.forwardRef((props, ref) => {
	const { isDragging, connectDragSource, id, text, connectDropTarget, classes } = props;
	const opacity = isDragging ? 0 : 1;

	return connectDragSource(
		connectDropTarget(
			<div key={id} className={classes.listItem} style={{ opacity }}>
				<DragIndicator className="DragIndicator" />
				<Typography>{text}</Typography>
			</div>
		)
	);
});

ReorderableNavTabs.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	dragSource(Types.ITEM, itemSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	})),
	dropTarget(Types.ITEM, itemTarget, connect => ({
		connectDropTarget: connect.dropTarget()
	})),
	withStyles(styles)
)(ReorderableNavTabs);
