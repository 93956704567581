import React from "react";
import MyCalendar from "../../MyCalendar";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
	calendar: {
		position: "relative",
		"& .fc-head": {
			background: theme.colors.darkerGray,
			color: "white",
			"& th": {
				verticalAlign: "middle",
				height: 30
			}
		}
	},
	title: {
		fontStyle: "normal",
		fontWeight: "bold"
	}
});

const Calendar = props => {
	const classes = { ...props.boardClasses, ...props.classes };
	return (
		<React.Fragment>
			<div className={classes.calendar}>
				<MyCalendar title="My Calendar"	/>
			</div>
		</React.Fragment>
	);
};

export default withStyles(styles)(Calendar);
