import React, { useCallback } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TargetBox, { FileInputAddon } from "./TargetBox";

interface Props {
	onFileAdded: (files: File[]) => void
	singular?: boolean
	disabled?: boolean
	addon?: FileInputAddon
}

const FileInput: React.FC<Props> = ({
	onFileAdded,
	singular,
	disabled,
	addon
}: Props) => {
	const handleFileDrop = useCallback((_, monitor) => {
		const files = monitor.getItem().files;
		onFileAdded(files);
	}, [onFileAdded]);

	const handleFileSelect = useCallback((e) => {
		onFileAdded && onFileAdded(e.target.files);
	}, [onFileAdded]);

	return <DndProvider backend={HTML5Backend}>
		<TargetBox onDrop={handleFileDrop} onSelect={handleFileSelect} singular={singular} disabled={disabled} addon={addon} />
	</DndProvider>;
};

export default FileInput;
