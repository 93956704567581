const styles = theme => ({
	summaryPaper: {
		flex: 1,
		minHeight: "100%", // use minHeight before we add scroll
		minWidth: 260,
		paddingBottom: "2rem"
	},
	expansionPanelSummary: {
		borderTopWidth: 1,
		borderTopStyle: "solid",
		borderTopColor: theme.palette.primary.light,
		borderBottomWidth: 1,
		borderBottomStyle: "solid",
		borderBottomColor: theme.palette.primary.light,
		flexDirection: "row"
	},
	expansionPanelSummaryContent: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		"& > :last-child": {
			paddingRight: 0,
			color: `${theme.colors.darkGray} !important`
		}
	},
	expansionPanelSummaryExpanded: {
		minHeight: "unset !important",
		height: 48,
		display: "flex",
		alignItems: "center"
	},
	expansionPanelDetails: {
		display: "block",
		"& .detail-summary-fieldset": {
			marginTop: theme.spacing(2),
			marginLeft: theme.spacing(1),
			"& .fieldset-span": {
				display: "block",
				marginTop: theme.spacing(2)
			}
		}
	},
	expansionPanel: {
		marginBottom: theme.spacing(2),
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		},
		borderRight: 0,
		boxShadow: "none"
	},
	expansionPanelExpanded: {
		marginBottom: 0,
		margin: 0,
		boxShadow: "none"
	},
	expansionPanelIcon: {
		color: theme.colors.darkGray,
		right: theme.spacing(1),
		left: "unset"
	},
	summaryLabel: {
		color: theme.colors.darkerGray,
		marginBottom: 1
	},
	summaryText: {
		wordBreak: "break-word",
		whiteSpace: "pre-line",
		margin: "2px 2px 2px 5px",
		"& .chip": {
			margin: "1px 2px",
			height: theme.spacing(3)
		},
		display: "block"
	}
});

export default styles;
