import React, { Component } from "react";
import { Calendar } from "fullcalendar";
import "fullcalendar/dist/fullcalendar.min.css";
import "fullcalendar/dist/locales-all";

class FullCalendar extends Component {
	constructor(props) {
		super(props);
		this.root = React.createRef();
		this.calendar = null;
	}

	componentDidMount() {
		this.calendar = new Calendar(this.root.current, { ...this.props,
			datesRender: info => {
				this.props.setView(info.view.type);
				this.props.setDateInView(this.calendar.getDate());
			}
		});
		this.calendar.render();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.events !== this.props.events || prevProps.defaultView !== this.props.defaultView) {
			this.calendar.destroy();
			this.calendar = new Calendar(this.root.current, this.props);
			this.calendar.render();
		}
	}

	render() {
		return <div ref={this.root} />;
	}
}

export default FullCalendar;
