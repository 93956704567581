import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon, Warning as WarningIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import { Creators } from "../../actions/transientElementActions";
import styles from "./styles";
import { connect } from "react-redux";
import classnames from "classnames";
import { DEFAULT_AUTO_HIDE_DURATION, GLOBAL_SNACKBAR } from "../../common/consts";

class Message extends Component {
	getIconType = () => {
		let icon = <InfoIcon />;
		const { classes, message, type } = this.props;

		if (type === "success") {
			icon = <CheckCircleIcon />;
		} else if (type === "warning") {
			icon = <WarningIcon />;
		} else if (type === "error") {
			icon = <ErrorIcon />;
		}

		return (
			<div className={classes.messageContainer}>
				{icon}
				<Typography className={classes.messageBody}>{message}</Typography>
			</div>
		);
	};

	render() {
		const {
			getIconType,
			props: { type, vertical, horizontal, autoHideDuration, classes, isOpen, setDrawerOpen, clearStore }
		} = this;
		return (
			<div className={classes.messageContent}>
				<Snackbar
					anchorOrigin={{
						vertical,
						horizontal
					}}
					open={isOpen}
					autoHideDuration={autoHideDuration}
					onClose={() => setDrawerOpen(GLOBAL_SNACKBAR, { isOpen: false,
					})}
					onExited={() => clearStore(GLOBAL_SNACKBAR)}
					disableWindowBlurListener={true}
				>
					<SnackbarContent
						className={classnames(classes.messageType, classes[type])}
						aria-describedby="client-snackbar"
						message={getIconType()}
						action={[
							<IconButton key="close" aria-label="Close" color="inherit" onClick={() => setDrawerOpen(GLOBAL_SNACKBAR, { isOpen: false })}>
								<CloseIcon />
							</IconButton>
						]}
					/>
				</Snackbar>
			</div>
		);
	}
}

Message.propTypes = {
	message: PropTypes.node.isRequired,
	type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
	autoHideDuration: PropTypes.number,
	vertical: PropTypes.oneOf(["top", "bottom"]),
	horizontal: PropTypes.oneOf(["left", "right", "center"]),
	classes: PropTypes.object.isRequired
};

Message.defaultProps = {
	autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
	vertical: "top",
	horizontal: "center"
};

const mapStateToProps = state => ({
	message: state.transientElements.drawerOpen[GLOBAL_SNACKBAR].message || "",
	type: state.transientElements.drawerOpen[GLOBAL_SNACKBAR].messageType || "info",
	isOpen: !!state.transientElements.drawerOpen[GLOBAL_SNACKBAR].isOpen || false,
	autoHideDuration: state.transientElements.drawerOpen[GLOBAL_SNACKBAR].autoHideDuration || DEFAULT_AUTO_HIDE_DURATION
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	clearStore: Creators.clearElement
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Message)
);
