/* eslint-disable no-extend-native */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import App from "./components/App";

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<BrowserRouter>
				<App style={{ width: "100%", height: "100%" }}/>
			</BrowserRouter>
		</Provider>
	</React.Fragment>,
	document.getElementById("app-root")
);

if (module.hot) {
	module.hot.accept();
}
