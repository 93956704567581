import React from "react";
import { BulkActionData, GridModalState } from "./typeDefs";
import ImageCarousel from "../ImageCarousel";

interface Props {
	data: BulkActionData;
	setModalState: (state: GridModalState) => void;
}

const ImageCarouselModal: React.FC<Props> = ({ data }) => {
	const items = data.sort((a, b) => a.SortOrder - b.SortOrder);
	return (
		<ImageCarousel items={items}/>
	);
};

export default ImageCarouselModal;
