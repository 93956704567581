const styles = theme => ({
	gridWithMargin: {
		margin: theme.spacing(3),
		marginLeft: theme.spacing(3.5),
		marginRight: theme.spacing(2.5),
	},
	gridComponent: {
		padding: theme.spacing(2),
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none",
			wordBreak: "break-word",
			minWidth: "150px",
			display: "block"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		}
	},
	fullWidth: {
		width: "100%"
	}
});

export default styles;
