import React from "react";
import { Card, CardHeader, CardActions, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { formatFileSize } from "@react-ui/common/format";

interface Props {
	file: File;
	handleRemoveFile: () => void;
}

const FilePreview: React.FC<Props> = ({ file, handleRemoveFile }) => {
	const { name } = file;
	return <Card style={{ width: 300, margin: 16 }}>
		<CardHeader title={name?.length > 20 ? `${name.slice(0, 17)}...` : name} subheader={formatFileSize(file.size)}>
		</CardHeader>
		<CardActions>
			<IconButton arial-label="remove" onClick={handleRemoveFile}>
				<Delete />
			</IconButton>
		</CardActions>
	</Card>;
};

export default FilePreview;
