import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, List, ListItem, ListItemText, ListItemIcon, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Creators } from "../../../../actions/transientElementActions";
import { Creators as HeaderCreators } from "../../../../actions/headerActions";
import styles from "../styles";
import { getModuleIcon, getModulePath } from "../../../../common/moduleData";
import { withRouter } from "react-router-dom";
import { GLOBAL_TABBED_DRAWER } from "../../../../common/consts";
import { formatTime, formatDate } from "@react-ui/common/format";

class RecentlyViewedTab extends Component {

	showHideTabDetails = type => () => {
		this.props.toggleSection(type);
	};

	componentDidMount = () => {
		this.props.recentlyViewedFetch();
	};

	displayTabData = () => {
		const {
			props: { classes, recentlyViewed, history, setDrawerOpen }
		} = this;

		if (recentlyViewed) {
			const menuUI = 
				<List component="div" disablePadding>
					{ recentlyViewed.map((record, index) => {
						const className = index % 2 ? "evenRow" : "oddRow";
						const Icon = getModuleIcon(record.Module);
						return (
							<Tooltip title={`${formatDate(record.ViewedDt)} ${formatTime(record.ViewedDt)}`} key={`${record.Module}_${record.RecordID}_tooltip`}>
								<ListItem className={className} button onClick={() => {
									history.push(getModulePath(record.Module, record.RecordID));
									setDrawerOpen(GLOBAL_TABBED_DRAWER, {
										isOpen: false
									});
								}} >
									<ListItemIcon><Icon /></ListItemIcon>
									<ListItemText primary={record.Title} secondary={`ID: ${record.RecordID}`} secondaryTypographyProps={{
										variant: "body1"
									}}/>
								</ListItem>
							</Tooltip>
						);
					})
					}
				</List>
			;

			return menuUI;
		}

		const menuUI =
			<Typography className={classes.noData}>You have no Recently Viewed items to display.</Typography>;

		return menuUI;
	};

	render() {
		const { displayTabData } = this;
		return <React.Fragment>{displayTabData()}</React.Fragment>;
	}
}

const mapStateToProps = (state) => ({
	recentlyViewed: state.header.recentlyViewed
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	recentlyViewedFetch: HeaderCreators.recentlyViewedFetch
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RecentlyViewedTab)));
