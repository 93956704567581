import React from "react";
import { Slide, Card, CardMedia, CardContent, Link } from "@material-ui/core";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { ImageCarouselItemProps } from "./typeDefs";

const useStyles = makeStyles(styles);

const ImageCarouselItem: React.FC<ImageCarouselItemProps> = (props) => {
	const classes = useStyles();
	const { active, data, direction } = props;

	const imagePath = data?.AssetPath?.props?.children?.props?.href ? data.AssetPath.props.children.props.href : data.AssetPath;
	
	return (
		active ? (
			<div className="CarouselItem" >
				<Slide direction={direction} in={active} timeout={200}>
					<div>
						<Card>
							<CardMedia style={{ width: 1200, height: 550 }} image={`${imagePath}`}>
								<CardContent className={classes.carouselContent}>
									<CardContent className={classes.carouselTitle}>
										{data.Title}
									</CardContent>
									<CardContent className={classes.carouselCaption}>
										{data.Caption}
									</CardContent>
									{data.Link ?
										<Link className={classes.carouselLink} underline="always" href={data.Link} target="_blank">
											{data.LinkText ? data.LinkText : "Learn More"}
										</Link>
										: null}
								</CardContent>
							</CardMedia>
						</Card>
					</div>
				</Slide>
			</div>
		) : null
	);
};

export default ImageCarouselItem;
