import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@react-ui/components/CircularProgressCentered";
import { GLOBAL_SPINNER } from "../../../common/consts";
import { Creators } from "../../../actions/transientElementActions";
import { connect } from "react-redux";

const GlobalSpinner = ({ onTimeout, setDrawerOpen, open, setMessage, ...props }) => {

	useEffect(() => {
		const timeoutRef = setTimeout(() => {
			open && setMessage("An action is taking longer than expected, please try again", "error");
			setDrawerOpen(GLOBAL_SPINNER, { isOpen: false });
		}, 15000);
		return function cleanup() {
			// clear timeout on destroy
			clearTimeout(timeoutRef);
		};
	}, []);

	const CircularProgressCentered = React.forwardRef((props, ref) => {
		return <CircularProgress forwardedRef={ref} {...props} useTimeout={false} spinning={open} />;
	});

	return <Modal open={open} disableAutoFocus tabIndex={-1} className="CyGlobalCircularProgressModal"><CircularProgressCentered {...props} /></Modal>;
};
const mapStateToProps = ({ transientElements: { drawerOpen: {
	[GLOBAL_SPINNER]: {
		isOpen
	}
} } }) => ({
	open: isOpen
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	setMessage: Creators.setMessage
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GlobalSpinner));
