import React, { useEffect } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Creators } from "../../../actions/dashboardActions";
import { getModuleIcon } from "../../../common/moduleData";
import CircularProgressCentered from "../../CircularProgressCentered";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const ActionListItem = ({ label, count, route, moduleNm, classes }) => {
	const Icon = getModuleIcon(moduleNm);

	return (
		<ListItem component={Link} to={`${route}`}>
			<ListItemIcon className={classes.ListIcon}><Icon /></ListItemIcon>
			<ListItemText 
				primaryTypographyProps={{ color: "primary" }}
				primary={`${label} (${count})`} />
		</ListItem>
	);
};

const actions = [
	{ label: "Accounts", key: "myAccounts", route: "/myProfile/account", moduleNm: "account" },
	{ label: "Tasks", key: "myTasks", route: "/myProfile/task", moduleNm: "task" }
];

const MyActionItems = ({ setDashboardData, fetchMine, classes, data = {}, setLastFilter }) => {
	useEffect(() => {
		fetchMine();
		return () => {
			setDashboardData({ myAccounts: undefined, myTasks: undefined, myListings: undefined, myOffers: undefined, myEventCalendars: undefined });
		};
	}, []);

	return (
		<React.Fragment>
			{data.myAccounts === undefined ? (
				<CircularProgressCentered size={75} color="secondary" />
			) : (
				<React.Fragment>
					<Typography variant="h2">
						My Items
					</Typography>
					<List>
						{actions.map(activity => {
							const { key, label, route, moduleNm } = activity;
							return <ActionListItem label={label} count={data[key] || 0} route={route} key={key} moduleNm={moduleNm} classes={classes}/>;
						})}
					</List>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

const mapStateToProps = state => ({
	data: state.dashboard.data
});

const mapDispatchToProps = {
	setDashboardData: Creators.setDashboardData,
	fetchMine: Creators.fetchMine,
	setLastFilter: Creators.setLastFilter,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(MyActionItems));
