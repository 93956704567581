const styles = theme => ({
	search: {
		display: "flex",
		position: "relative",
		backgroundColor: theme.colors.shadow,
		marginLeft: 0,
		marginRight: theme.spacing(3),
		width: "100%",
		transition: theme.transitions.create(["backgroundColor"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "16em"
		},
		alignItems: "center",
		justifyContent: "flex-start",
		maxHeight: 35
	},
	searchIcon: {
		width: theme.spacing(2),
		paddingLeft: theme.spacing(1),
		position: "relative",
		top: 2,
		pointerEvents: "none",
		color: theme.colors.gandolf
	},
	inputInput: {
		fontFamily: "Noto Sans",
		fontSize: 14,
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		color: theme.palette.common.white,
		transition: theme.transitions.create("color"),
		zIndex: 5001,
		width: "100%",
		"&:focus": {
			color: theme.palette.common.black
		}
	},
	searchBackdrop: {
		backgroundColor: "transparent",
		zIndex: 5000
	},
	searchList: {
		padding: 0
	},
	menuItem: {
		textDecoration: "none",
		color: theme.palette.primary.dark,
		"&:hover": {
			backgroundColor: theme.palette.primary.light
		}
	},
	highlight: {
		color: "rgba(0, 0, 0, 0.87)",
		fontWeight: "bold",
		backgroundColor: "transparent",
		textDecoration: "none"
	},
	popper: {
		zIndex: 10000,
		width: "16em",
		backgroundColor: theme.palette.common.white
	},
	expandContainer: {
		width: 40
	},
	expand: {
		color: theme.colors.gandolf,
		paddingLeft: 0
	},
	backspace: {
		color: theme.colors.gandolf,
		height: 10,
		width: 10,
		opacity: 0.8,
		zIndex: 5001
	},
	clear: {
		position: "absolute",
		right: "2em"
	}
});

export default styles;
